
















import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { ActivityModel } from '@/services/iNotificationService';

import { mixins } from "vue-class-component";
import SharedMixin from "@/mixins/SharedMixin";

import PgaActivitySingleItem from './PgaActivitySingleItem.vue';
import { Guid } from "guid-typescript";
import { ActivitiesStore } from "../store";

@Component({
    components: { PgaActivitySingleItem }
})
export default class PgaActivitiesListItems extends mixins(SharedMixin)
{
    @Prop() readonly activities!: Array<ActivityModel>;
    @Prop( { default: true }) readonly isLoading!: boolean;

    private activity!: ActivityModel;

    private showDetails(activity: ActivityModel) : void
    {
        this.$router.push({path: activity.UrlRelativePath});
    }

    private async dismissActivity(id: Guid) : Promise<void>
    {
        await ActivitiesStore.DismissActivity(id);
    }
}
