
















import { Component, Prop, Model } from "vue-property-decorator";

import ValidationMixin from "@/mixins/ValidationMixin";
import { mixins } from "vue-class-component";

@Component({})
export default class PgaTextArea extends mixins(ValidationMixin)
{
	@Prop({ type: String, default: "" }) readonly label!: string;
	@Prop({ type: Boolean, default: false }) readonly readonly!: boolean;
	@Prop({ type: String, default: "" }) readonly prependIcon!: string;
	@Prop({ type: Boolean, default: false }) readonly dense!: boolean;

	@Prop({ type: String, default: "" }) readonly keyError!: string;
	@Prop({ default: function() { return new Map<string, Array<string>>() } }) readonly Errors!: Map<string, Array<string>>;

	@Model("input", { type: String }) Model!: string;
	updateValue(value: string) : void
	{
		value = value ?? "";
		this.$emit('input', value);
	}
}
