













import Vue from "vue";
import { Component, Prop, Watch} from "vue-property-decorator";

@Component({})
export default class PgaLoadingSpinner extends Vue
{
	@Prop({ default: false }) isLoading! : boolean;
	@Prop({ default: true }) absolute! : boolean;
	//@PropSync("isLoading",{ default: false }) isLoadingSynced! : boolean;

	private myIsLoading= false;
	
	@Watch('isLoading',{ immediate: true, deep: true })
	onChildChanged(val: boolean, oldVal: boolean) : void
	{
		console.log('Prop changed: ', val, ' | was: ', oldVal)
		this.myIsLoading= val;
	} 
}
