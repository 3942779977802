







import Vue from 'vue'
import { Emit, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class PgaRequestChangesButton extends Vue
{
	@Prop({ default: "Request Changes" }) readonly text! : string;
    @Prop({ default: "mdi-progress-question" }) readonly icon! : string;

    @Emit("click")
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    clicked() : void { }
}
