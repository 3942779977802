import Vue from 'vue'
import VueRouter, { NavigationGuardNext, Route, RouteConfig } from 'vue-router'
import HomeIndex from '../feature/Home/HomeIndex.vue'
import DashboardIndex from '../feature/Home/DashboardIndex.vue'
import PgaPage from '../feature/Shared/Layout/PgaPage.vue'
import { SharedModule } from "@/feature/Shared/store";
import { ChildrenMenu } from '../services/iIdentityService';

//My Area
const MyActivitiesIndex = () => import(/* webpackChunkName: "MyActivities" */  '../feature/MyArea/MyActivities/MyActivitiesIndex.vue')
const MyCertificationsIndex = () => import(/* webpackChunkName: "MyCertifications" */  '../feature/MyArea/MyCertifications/MyCertificationsIndex.vue')
const MyCertificationsPageSelect = () => import(/* webpackChunkName: "MyCertificationsSelect" */  '../feature/MyArea/MyCertifications/MyCertificationsPageSelect.vue')
const MyExpertisesIndex = () => import(/* webpackChunkName: "MyExpertises" */  '../feature/MyArea/MyExpertises/MyExpertisesIndex.vue')
const MyExpertisesPageSelect = () => import(/* webpackChunkName: "MyExpertises" */  '../feature/MyArea/MyExpertises/MyExpertisesPageSelect.vue')
const ExpertisesProposedPageList = () => import(/* webpackChunkName: "MyExpertises" */  '../feature/MyArea/MyExpertises/ExpertisesProposedPageList.vue')

//Provisioning
const UsersPageIndex = () => import(/* webpackChunkName: "Users" */  '../feature/Provisioning/Users/UsersPageIndex.vue')
const UsersPageCreate = () => import(/* webpackChunkName: "Users" */  '../feature/Provisioning/Users/UsersPageCreate.vue')
const UsersPageDetails = () => import(/* webpackChunkName: "Users" */  '../feature/Provisioning/Users/UsersPageDetails.vue')
const UsersPageEdit = () => import(/* webpackChunkName: "Users" */  '../feature/Provisioning/Users/UsersPageEdit.vue')

const PgaRolesPageIndex = () => import(/* webpackChunkName: "Roles" */  '../feature/Provisioning/Roles/PgaRolesPageIndex.vue')
const PgaRolesPageCreate = () => import(/* webpackChunkName: "Roles" */  '../feature/Provisioning/Roles/PgaRolesPageCreate.vue')
const PgaRolesPageDetails = () => import(/* webpackChunkName: "Roles" */  '../feature/Provisioning/Roles/PgaRolesPageDetails.vue')
const PgaRolesPageEdit = () => import(/* webpackChunkName: "Roles" */  '../feature/Provisioning/Roles/PgaRolesPageEdit.vue')
const PgaRolesPageSelectUsers = () => import(/* webpackChunkName: "Roles" */  '../feature/Provisioning/Roles/PgaRolesPageSelectUsers.vue')

const TenantsIndex = () => import(/* webpackChunkName: "Tenants" */  '../feature/Provisioning/Tenants/TenantsPageIndex.vue')
const TenantCreate = () => import(/* webpackChunkName: "Tenants" */  '../feature/Provisioning/Tenants/TenantPageCreate.vue')
const TenantEdit = () => import(/* webpackChunkName: "Tenants" */  '../feature/Provisioning/Tenants/TenantPageEdit.vue')
//const OfferDisplayPage = () => import(/* webpackChunkName: "Offer" */  '../feature/Offerte/ValutazioneOfferta/ValutazioneOffertaPageDetails.vue')

//Order
// const OfferIndex = () => import(/* webpackChunkName: "Offer" */  '../feature/Offerte/ValutazioneOfferta/ValutazioneOffertaIndex.vue')
const OfferCreate = () => import(/* webpackChunkName: "Offer" */  '../feature/Offerte/ValutazioneOfferta/ValutazioneOffertaPageCreate.vue')
const OfferDisplayPage = () => import(/* webpackChunkName: "Offer" */  '../feature/Offerte/ValutazioneOfferta/ValutazioneOffertaPageDetails.vue')
//Refactoring above...
const OfferEvaluationPageIndex = () => import(/* webpackChunkName: "Offer" */  '../feature/Offerte/OfferEvaluation/OfferEvaluationPageIndex.vue')

const OfferEvaluationPageCustomerOrderCreate = () => import(/* webpackChunkName: "Offer" */  '../feature/Offerte/OfferEvaluation/OfferEvaluationPageCustomerOrderCreate.vue')
const OfferEvaluationPageCustomerOrderSelectDeal = () => import(/* webpackChunkName: "Offer" */  '../feature/Offerte/OfferEvaluation/OfferEvaluationPageCustomerOrderSelectDeal.vue')
const OfferEvaluationPageCustomerOrderDetails = () => import(/* webpackChunkName: "Offer" */  '../feature/Offerte/OfferEvaluation/OfferEvaluationPageCustomerOrderDetails.vue')
const OfferEvaluationPageCustomerOrderEdit = () => import(/* webpackChunkName: "Offer" */  '../feature/Offerte/OfferEvaluation/OfferEvaluationPageCustomerOrderEdit.vue')
const OfferEvaluationPageCustomerOrderReassignACOM = () => import(/* webpackChunkName: "Offer" */  '../feature/Offerte/OfferEvaluation/OfferEvaluationPageCustomerOrderReassignACOM.vue')

const OfferEvaluationPageInternalProjectCreate = () => import(/* webpackChunkName: "Offer" */  '../feature/Offerte/OfferEvaluation/OfferEvaluationPageInternalProjectCreate.vue')
const OfferEvaluationPageInternalProjectDetails = () => import(/* webpackChunkName: "Offer" */  '../feature/Offerte/OfferEvaluation/OfferEvaluationPageInternalProjectDetails.vue')
const OfferEvaluationPageInternalProjectEdit = () => import(/* webpackChunkName: "Offer" */  '../feature/Offerte/OfferEvaluation/OfferEvaluationPageInternalProjectEdit.vue')

const BusinessStartupPageIndex = () => import(/* webpackChunkName: "Offer" */  '../feature/Offerte/business-startups/BusinessStartupPageIndex.vue')

const BusinessStartupPageCustomerOrderCreate = () => import(/* webpackChunkName: "Offer" */  '../feature/Offerte/business-startups/customer-orders/BusinessStartupPageCustomerOrderCreate.vue')
const BusinessStartupPageCustomerOrderDetails = () => import(/* webpackChunkName: "Offer" */  '../feature/Offerte/business-startups/customer-orders/BusinessStartupPageCustomerOrderDetails.vue')
const BusinessStartupPageCustomerOrderEdit = () => import(/* webpackChunkName: "Offer" */  '../feature/Offerte/business-startups/customer-orders/BusinessStartupPageCustomerOrderEdit.vue')

const BusinessStartupPageInternalProjectDetails = () => import(/* webpackChunkName: "Offer" */  '../feature/Offerte/business-startups/internal-projects/BusinessStartupPageInternalProjectDetails.vue')

const AvvioAttivitaPageIndex = () => import(/* webpackChunkName: "AvvioAttivita" */  '../feature/Offerte/AvvioAttivita/AvvioAttivitaPageIndex.vue')
const AvvioAttivitaPageCreate = () => import(/* webpackChunkName: "AvvioAttivita" */  '../feature/Offerte/AvvioAttivita/AvvioAttivitaPageCreate.vue')
const AvvioAttivitaPageDetails = () => import(/* webpackChunkName: "AvvioAttivita" */  '../feature/Offerte/AvvioAttivita/AvvioAttivitaPageDetails.vue')

const AreaManagersPageIndex = () => import(/* webpackChunkName: "AreaManagers" */  '../feature/Offerte/AreaManagers/AreaManagersPageIndex.vue')
const AreaManagersPageCreate = () => import(/* webpackChunkName: "AreaManagers" */  '../feature/Offerte/AreaManagers/AreaManagersPageCreate.vue')
const AreaManagersPageDetails = () => import(/* webpackChunkName: "AreaManagers" */  '../feature/Offerte/AreaManagers/AreaManagersPageDetails.vue')
const AreaManagersPageEdit = () => import(/* webpackChunkName: "AreaManagers" */  '../feature/Offerte/AreaManagers/AreaManagersPageEdit.vue')

const BussinessLinesPageIndex = () => import(/* webpackChunkName: "BussinessLines" */  '../feature/Offerte/BussinessLines/BussinessLinesPageIndex.vue')
const BussinessLinesPageCreate = () => import(/* webpackChunkName: "BussinessLines" */  '../feature/Offerte/BussinessLines/BussinessLinesPageCreate.vue')
const BussinessLinesPageDetails = () => import(/* webpackChunkName: "BussinessLines" */  '../feature/Offerte/BussinessLines/BussinessLinesPageDetails.vue')
const BusinessLinesACOMPageEdit = () => import(/* webpackChunkName: "BussinessLines" */  '../feature/Offerte/BussinessLines/BusinessLinesACOMPageEdit.vue')
const BusinessLinesPageEdit = () => import(/* webpackChunkName: "BussinessLines" */  '../feature/Offerte/BussinessLines/BusinessLinesPageEdit.vue')

const EconomicLimitPageIndex = () => import(/* webpackChunkName: "LimitiEconomici" */  '../feature/Offerte/LimitiEconomici/EconomicLimitPageIndex.vue')
const EconomicLimitPageCreate = () => import(/* webpackChunkName: "LimitiEconomici" */  '../feature/Offerte/LimitiEconomici/EconomicLimitPageCreate.vue')
const EconomicLimitPageDetails = () => import(/* webpackChunkName: "LimitiEconomici" */  '../feature/Offerte/LimitiEconomici/EconomicLimitPageDetails.vue')
const EconomicLimitPageEdit = () => import(/* webpackChunkName: "LimitiEconomici" */  '../feature/Offerte/LimitiEconomici/EconomicLimitPageEdit.vue')

const TechAreasPageIndex = () => import(/* webpackChunkName: "TechAreas" */  '../feature/Offerte/TechAreas/TechAreasPageIndex.vue')
const TechAreasPageCreate = () => import(/* webpackChunkName: "TechAreas" */  '../feature/Offerte/TechAreas/TechAreasPageCreate.vue')
const TechAreasPageDetails = () => import(/* webpackChunkName: "TechAreas" */  '../feature/Offerte/TechAreas/TechAreasPageDetails.vue')
const TechAreasPageEdit = () => import(/* webpackChunkName: "TechAreas" */  '../feature/Offerte/TechAreas/TechAreasPageEdit.vue')

const RisorsePageIndex = () => import(/* webpackChunkName: "Risorse" */  '../feature/Resource/Risorse/RisorsePageIndex.vue')
const RisorsePageCreate = () => import(/* webpackChunkName: "Risorse" */  '../feature/Resource/Risorse/RisorsePageCreate.vue')
const RisorsePageDetails = () => import(/* webpackChunkName: "Risorse" */  '../feature/Resource/Risorse/RisorsePageDetails.vue')

const TipologiaFornituraPageIndex = () => import(/* webpackChunkName: "TipologiaFornitura" */  '../feature/Offerte/TipologiaFornitura/TipologiaFornituraPageIndex.vue')
const TipologiaFornituraPageCreate = () => import(/* webpackChunkName: "TipologiaFornitura" */  '../feature/Offerte/TipologiaFornitura/TipologiaFornituraPageCreate.vue')
const TipologiaFornituraPageDetails = () => import(/* webpackChunkName: "TipologiaFornitura" */  '../feature/Offerte/TipologiaFornitura/TipologiaFornituraPageDetails.vue')
const SupplyTipologyPageEdit = () => import(/* webpackChunkName: "SupplyTipology" */  '../feature/Offerte/TipologiaFornitura/SupplyTipologyPageEdit.vue')

const VerbaleStartupPageIndex = () => import(/* webpackChunkName: "VerbaleStartup" */  '../feature/Offerte/VerbaleStartup/VerbaleStartupPageIndex.vue')
const VerbaleStartupPageCreate = () => import(/* webpackChunkName: "VerbaleStartup" */  '../feature/Offerte/VerbaleStartup/VerbaleStartupPageCreate.vue')
const VerbaleStartupPageDetails = () => import(/* webpackChunkName: "VerbaleStartup" */  '../feature/Offerte/VerbaleStartup/VerbaleStartupPageDetails.vue')

const SolutionProductsPageIndex = () => import(/* webpackChunkName: "SolutionProducts" */  '../feature/Offerte/SolutionProducts/SolutionProductsPageIndex.vue')
const SolutionProductsPageCreate = () => import(/* webpackChunkName: "SolutionProducts" */  '../feature/Offerte/SolutionProducts/SolutionProductsPageCreate.vue')
const SolutionProductsPageDetails = () => import(/* webpackChunkName: "SolutionProducts" */  '../feature/Offerte/SolutionProducts/SolutionProductsPageDetails.vue')
const SolutionProductsPageEdit = () => import(/* webpackChunkName: "SolutionProducts" */  '../feature/Offerte/SolutionProducts/SolutionProductsPageEdit.vue')

//Resource
const ResourceDashboardPageIndex = () => import(/* webpackChunkName: "Certifications" */  '../feature/Resource/ResourceDashboard/ResourceDashboardPageIndex.vue')

const CertificationsPageIndex = () => import(/* webpackChunkName: "Certifications" */  '../feature/Resource/Certifications/CertificationsPageIndex.vue')
const CertificationPageCreate = () => import(/* webpackChunkName: "Certification" */  '../feature/Resource/Certifications/CertificationPageCreate.vue')
const CertificationPageDetails = () => import(/* webpackChunkName: "Certification" */  '../feature/Resource/Certifications/CertificationPageDetails.vue')
const CertificationPageEdit = () => import(/* webpackChunkName: "Certification" */  '../feature/Resource/Certifications/CertificationPageEdit.vue')
const CertificationPageSelectUsers = () => import(/* webpackChunkName: "Certification" */  '../feature/Resource/Certifications/CertificationsPageSelectUsers.vue')

const ExpertisesPageIndex = () => import(/* webpackChunkName: "Expertises" */  '../feature/Resource/Expertises/ExpertisePageIndex.vue')
const ExpertisePageCreate = () => import(/* webpackChunkName: "Expertises" */  '../feature/Resource/Expertises/ExpertisePageCreate.vue')
const ExpertisePageDetails = () => import(/* webpackChunkName: "Expertises" */  '../feature/Resource/Expertises/ExpertisePageDetails.vue')
const ExpertisePageEdit = () => import(/* webpackChunkName: "Expertises" */  '../feature/Resource/Expertises/ExpertisePageEdit.vue')
const ExpertisePageSelectUsers = () => import(/* webpackChunkName: "Expertise" */  '../feature/Resource/Expertises/ExpertisesPageSelectUsers.vue')

const TechnologyPageIndex = () => import(/* webpackChunkName: "Technologies" */  '../feature/Resource/Technologies/PgaTechnologyPageIndex.vue')
const TechnologyPageCreate = () => import(/* webpackChunkName: "Technologies" */  '../feature/Resource/Technologies/PgaTechnologyPageCreate.vue')
const TechnologyPageDetails = () => import(/* webpackChunkName: "Technologies" */  '../feature/Resource/Technologies/PgaTechnologyPageDetails.vue')
const TechnologyPageEdit = () => import(/* webpackChunkName: "Technologies" */  '../feature/Resource/Technologies/PgaTechnologyPageEdit.vue')

const VendorsPageIndex = () => import(/* webpackChunkName: "Vendors" */  '../feature/Resource/Vendors/PgaVendorsPageIndex.vue')
const VendorsPageCreate = () => import(/* webpackChunkName: "Vendors" */  '../feature/Resource/Vendors/PgaVendorPageCreate.vue')
const VendorsPageDetails = () => import(/* webpackChunkName: "Vendors" */  '../feature/Resource/Vendors/PgaVendorPageDetails.vue')

const PgaAccessDenied = () => import(/* webpackChunkName: "Shared" */  '../feature/Shared/Layout/PgaAccessDenied.vue')

Vue.use(VueRouter)

const navRoutes = [
    {
        path: '/',
        name: 'Home',
        component: HomeIndex
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        meta: { needGuard: true },
        component: DashboardIndex
    },
    {
        path: '/about',
        name: 'About',
        component: () => import(/* webpackChunkName: "about" */ '../feature/Shared/About.vue')
    },
    {
        path: '/myarea', meta: { needGuard: true }, component: PgaPage, children: [
            { name: 'myactivitiesindex', path: 'myactivities', component: MyActivitiesIndex, },
            {
                path: 'myexpertises', component: PgaPage, children: [
                    { name: 'myexpertisesindex', path: '', component: MyExpertisesIndex },
                    { name: 'myexpertisesselect', path: 'select', component: MyExpertisesPageSelect },
                ]
            },
            { name: 'expertiseproposedbymanager', path: 'expertisesproposed', component: ExpertisesProposedPageList },
            {
                path: 'mycertifications', component: PgaPage, children: [
                    { name: 'mycertificationsindex', path: '', component: MyCertificationsIndex },
                    { name: 'mycertificationsselect', path: 'select', component: MyCertificationsPageSelect },
                ]
            },
        ]
    },
    {
        path: '/users', meta: { needGuard: true }, component: PgaPage, children: [
            { name: 'userindex', path: '', component: UsersPageIndex },
            { name: 'usercreate', path: 'create', component: UsersPageCreate },
            { name: 'userdetails', path: ':id', component: UsersPageDetails },
            { name: 'useredit', path: ':id/edit', component: UsersPageEdit },
        ]
    },
    {
        path: '/roles', meta: { needGuard: true }, component: PgaPage, children: [
            { name: 'roleindex', path: '', component: PgaRolesPageIndex },
            { name: 'rolecreate', path: 'create', component: PgaRolesPageCreate },
            { name: 'roledetails', path: ':id', component: PgaRolesPageDetails },
            { name: 'roleedit', path: ':id/edit', component: PgaRolesPageEdit },
            { name: 'roleselectusers', path: ':id/selectusers', component: PgaRolesPageSelectUsers },
        ]
    },
    {
        path: '/tenants', meta: { needGuard: true }, component: PgaPage, children: [
            { path: '', component: TenantsIndex },
            { name: 'tenantcreate', path: 'create', component: TenantCreate },
            { name: 'tenantedit', path: ':id/edit', component: TenantEdit, params: true }
            // { path: 'details', component: OfferDisplayPage},
        ]
    },
    {
        path: '/offervalutation', meta: { needGuard: false }, component: PgaPage, children: [
            { path: 'create', component: OfferCreate },
            { path: 'details', component: OfferDisplayPage },
        ]
    },
    {
        path: '/offerevaluation', meta: { needGuard: true }, component: PgaPage, children: [
            { name: 'offerevaluationindex', path: '', component: OfferEvaluationPageIndex },
            { name: 'offerevaluationcustomerselectdeal', path: 'customerorder/selectdeal', component: OfferEvaluationPageCustomerOrderSelectDeal },
            { name: 'offerevaluationcustomercreate', path: 'customerorder/create', component: OfferEvaluationPageCustomerOrderCreate },
            { name: 'offerevaluationcustomerdetails', path: 'customerorder/:id', component: OfferEvaluationPageCustomerOrderDetails, params: true },
            { name: 'offerevaluationcustomeredit', path: 'customerorder/:id/edit', component: OfferEvaluationPageCustomerOrderEdit, params: true },
            { name: 'offerevaluationcustomerreassignacom', path: 'customerorder/reassignment', component: OfferEvaluationPageCustomerOrderReassignACOM },
            { name: 'offerevaluationprojectcreate', path: 'internalproject/create', component: OfferEvaluationPageInternalProjectCreate },
            { name: 'offerevaluationprojectdetails', path: 'internalproject/:id', component: OfferEvaluationPageInternalProjectDetails, params: true },
            { name: 'offerevaluationprojectedit', path: 'internalproject/:id/edit', component: OfferEvaluationPageInternalProjectEdit, params: true },
        ]
    },
    {
        path: '/businessstartup', meta: { needGuard: true }, component: PgaPage, children: [
            { name: 'businessstartupindex', path: '', component: BusinessStartupPageIndex },
            { name: 'businessstartupcreate', path: 'customerorder/create', component: BusinessStartupPageCustomerOrderCreate },
            { name: 'businessstartupcustomerdetails', path: 'customerorder/:id', component: BusinessStartupPageCustomerOrderDetails, params: true },
            { name: 'businessstartupcustomeredit', path: 'customerorder/:id/edit', component: BusinessStartupPageCustomerOrderEdit, params: true },

            { name: 'businessstartupprojectdetails', path: 'internalproject/:id', component: BusinessStartupPageInternalProjectDetails, params: true },
        ]
    },
    {
        path: '/startbusiness', meta: { needGuard: true }, component: PgaPage, children: [
            { path: '', component: AvvioAttivitaPageIndex },
            { path: 'create', component: AvvioAttivitaPageCreate },
            { path: 'details', component: AvvioAttivitaPageDetails },
        ]
    },
    {
        path: '/businesslines', meta: { needGuard: true }, component: PgaPage, children: [
            { path: '', component: BussinessLinesPageIndex },
            { path: 'create', component: BussinessLinesPageCreate },
            { name: 'businesslinedetails', path: ':id', component: BussinessLinesPageDetails },
            { name: 'businesslineedit', path: ':id/edit', component: BusinessLinesPageEdit },
            { name: 'businesslineacomedit', path: ':id/association', component: BusinessLinesACOMPageEdit },
        ]
    },
    {
        path: '/techareas', meta: { needGuard: true }, component: PgaPage, children: [
            { path: '', component: TechAreasPageIndex },
            { path: 'create', component: TechAreasPageCreate },
            { name: 'techareasdetails', path: ':id', component: TechAreasPageDetails },
            { name: 'techareasedit', path: ':id/edit', component: TechAreasPageEdit },
        ]
    },
    {
        path: '/supplytipologies', meta: { needGuard: true }, component: PgaPage, children: [
            { path: '', component: TipologiaFornituraPageIndex },
            { path: 'create', component: TipologiaFornituraPageCreate },
            { name: 'tipologiafornituradetails', path: ':id', component: TipologiaFornituraPageDetails },
            { name: 'supplytipologyedit', path: ':id/edit', component: SupplyTipologyPageEdit, params: true },
        ]
    },
    {
        path: '/certifications', meta: { needGuard: true }, component: PgaPage, children: [
            { path: '', component: CertificationsPageIndex },
            { path: 'create', component: CertificationPageCreate },
            { name: 'certificationdetails', path: ':id', component: CertificationPageDetails },
            { name: 'certificationedit', path: ':id/edit', component: CertificationPageEdit, params: true },
            { name: 'certificationsselectusers', path: ':id/selectusers', component: CertificationPageSelectUsers, params: true },
        ]
    },
    {
        path: '/expertises', meta: { needGuard: true }, component: PgaPage, children: [
            { path: '', component: ExpertisesPageIndex },
            { name: 'expertisecreate', path: 'create', component: ExpertisePageCreate },
            { name: 'expertisedetails', path: ':id', component: ExpertisePageDetails, params: true },
            { name: 'expertiseedit', path: ':id/edit', component: ExpertisePageEdit, params: true },
            { name: 'expertisesselectusers', path: ':id/selectusers', component: ExpertisePageSelectUsers, params: true },
        ]
    },
    {
        path: '/technologies', meta: { needGuard: true }, component: PgaPage, children: [
            { path: '', component: TechnologyPageIndex },
            { name: 'technologycreate', path: 'create', component: TechnologyPageCreate },
            { name: 'technologydetails', path: ':id', component: TechnologyPageDetails, params: true },
            { name: 'technologyedit', path: ':id/edit', component: TechnologyPageEdit, params: true }
        ]
    },
    {
        path: '/economiclimits', meta: { needGuard: true }, component: PgaPage, children: [
            { path: '', component: EconomicLimitPageIndex },
            { path: 'create', component: EconomicLimitPageCreate },
            { name: 'economiclimitdetails', path: ':id', component: EconomicLimitPageDetails, params: true },
            { name: 'economiclimitedit', path: ':id/edit', component: EconomicLimitPageEdit, params: true }
        ]
    },
    {
        meta: { needGuard: true },
        path: '/resourcedashboard',
        name: 'Dashboard',
        component: ResourceDashboardPageIndex
    },
    {
        path: '/resources', meta: { needGuard: true }, component: PgaPage, children: [
            { path: '', component: RisorsePageIndex },
            { path: 'create', component: RisorsePageCreate },
            { path: 'details', component: RisorsePageDetails },
            { path: 'dashboard', component: ResourceDashboardPageIndex },
        ]
    },
    {
        path: '/startupreport', meta: { needGuard: true }, component: PgaPage, children: [
            { path: '', component: VerbaleStartupPageIndex },
            { path: 'create', component: VerbaleStartupPageCreate },
            { path: 'details', component: VerbaleStartupPageDetails },
        ]
    },
    {
        path: '/solutionproducts', meta: { needGuard: true }, component: PgaPage, children: [
            { path: '', component: SolutionProductsPageIndex },
            { path: 'create', component: SolutionProductsPageCreate },
            { name: 'solutionproductsdetails', path: ':id', component: SolutionProductsPageDetails, params: true },
            { name: 'solutionproductsedit', path: ':id/edit', component: SolutionProductsPageEdit, params: true },
        ]
    },
    {
        path: '/areamanagers', meta: { needGuard: true }, component: PgaPage, children: [
            { path: '', component: AreaManagersPageIndex },
            { path: 'create', component: AreaManagersPageCreate },
            { name: 'areamanagerdetails', path: ':id', component: AreaManagersPageDetails, params: true },
            { name: 'areamanageredit', path: ':id/edit', component: AreaManagersPageEdit, params: true },
        ]
    },
    {
        path: '/vendors', meta: { needGuard: true }, component: PgaPage, children: [
            { path: '', component: VendorsPageIndex },
            { path: 'create', component: VendorsPageCreate },
            { name: 'vendordetails', path: ':id', component: VendorsPageDetails },
        ]
    },
    {
        path: '/AccountAccessDenied', component: PgaAccessDenied, name: "AccountAccessDenied"
    },
    {
        path: '*', component: HomeIndex
    },    
] as Array<RouteConfig>;

const router = new VueRouter({
    routes: navRoutes

});

router.beforeEach(async (to: Route, from: Route, next: NavigationGuardNext) => {
    const guardNeeded = to.matched.some(m => m.meta.needGuard);
    const isLogged = SharedModule.IsLogged;
    if (!isLogged && guardNeeded) {
        await SharedModule.silentCheckToken();
    }

    if (to.fullPath.indexOf("/code=") > -1) {
        await SharedModule.silentCheckToken();
        if (localStorage.CurrentUrl && localStorage.CurrentUrl !== '/') {
            next(localStorage.CurrentUrl);
        }
        else
            next('/dashboard');
    }
    localStorage.CurrentUrl = to.fullPath;
    //If a guard is needed
    if (guardNeeded && to.path !== '/dashboard') {        
        const menu = SharedModule.FlattenMenu;        
        const permissions = SharedModule.Permissions;
        const splitPath = to.fullPath.split('/').filter(sp => sp !== "").map(sp => sp.toLowerCase());
        const section = getSection(menu, splitPath);

        //Is a permission required?
        const noPermissionRequired = section &&
            section.PermissionCode === '';
        //Does the user have the required permission (if any)?
        const userHasRequiredPermission = section &&
            section.PermissionCode &&
            permissions &&
            permissions.some(element => element === section!.PermissionCode);
        //If a permission IS required AND the user does NOT have it
        if (!noPermissionRequired && !userHasRequiredPermission) {
            //We redirect to the access denied page
            next('/AccountAccessDenied');
        }
        else {
            next();
        }       
    }

    next();
});

function getSection(menu: Array<ChildrenMenu>, splitPath: Array<string>): ChildrenMenu | undefined {
    if (splitPath.length === 1) {
        return menu.find(element => splitPath.includes(element.Url.toLowerCase()));
    }

    const section = menu.find(element => splitPath.includes(element.Url.toLowerCase()));
    if (section !== undefined) {
        return section;
    }
    else {
        splitPath[1] = splitPath[0] + "/" + splitPath[1];
        return getSection(menu, splitPath.slice(1));
    }
}

export default router;
