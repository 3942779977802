import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './infrastructure/router'
import store from './infrastructure/store'
import "reflect-metadata";
import './App.container'
import vuetify from './plugins/vuetify';
import AxiosService from './services/AxiosService';
import NotificationHub from "@/feature/Shared/Notification/notification-hub";

import VueCurrencyInput from 'vue-currency-input'
import Component from 'vue-class-component';

const pluginOptions =
{
    /* see config reference */
    globalOptions: 
    {
        locale: 'de',
        currency: 'EUR',
        valueAsInteger: false,
        distractionFree: 
        {
            hideNegligibleDecimalDigits: false,
            hideCurrencySymbol: false,
            hideGroupingSymbol: false,
        },
        precision: 2,
        autoDecimalMode: true,
        allowNegative: true,
    }
};
Vue.use(VueCurrencyInput, pluginOptions)

Vue.prototype.$eventHub = new Vue(); // Global event bus
Vue.config.productionTip = false

//TODO: decomment line below when we would resolved issues with SignalR
Vue.use(NotificationHub);

AxiosService.Init();

Component.registerHooks([
    'beforeRouteEnter',
    'beforeRouteLeave',
    'beforeRouteUpdate',
]);

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')
