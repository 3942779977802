import { UrlString } from '@azure/msal-common';
import { Guid } from 'guid-typescript';

export default interface OrderService
{
    // BusinessLine API
    GetBusinessLines(): Promise<Array<BusinessLineTableView>>;
    GetBusinessLine(id: string): Promise<BusinessLineModel>;
    CreateBusinessLine(cmd: CreateBusinessLineCommand): Promise<CreateBusinessLineResponse>;
    EditBusinessLine(cmd: EditBusinessLineCommand): Promise<EditBusinessLineResponse>;
    DeleteBusinessLine(cmd: DeleteBusinessLineCommand): Promise<DeleteBusinessLineResponse>;
    ApproveBusinessLine(cmd: ApproveBusinessLineCommand): Promise<ApproveBusinessLineResponse>;
    EditAssociationBusinessLineACOM(cmd: EditAssociationBusinessLineACOMCommand): Promise<EditAssociationBusinessLineACOMResponse>
    ApproveDeletionBusinessLine(cmd: ApproveDeletionBusinessLineCommand): Promise<ApproveDeletionBusinessLineResponse>;
    GetBusinessLinesDropdown(): Promise<Array<BusinessLineTableView>>;

    // Tech Area API
    CreateTechArea(techArea: CreateTechAreaCommand): Promise<CreateTechAreaResponse>;
    EditTechArea(id: Guid, cmd: EditTechAreaCommand) : Promise<EditTechAreaResponse>;
    GetAllTechAreas(): Promise<ListTechAreas>;
    GetTechAreaById(id: Guid): Promise<TechAreaModel>;
    DeleteTechArea(id: Guid): Promise<DeleteTechAreaResponse>;
    ApproveTechArea(cmd: ApproveTechAreaCommand): Promise<ApproveTechAreaResponse>;
    ApproveDeletionTechArea(cmd: ApproveDeletionTechAreaCommand): Promise<ApproveDeletionTechAreaResponse>;
    GetAllTechAreasDropdown(): Promise<ListTechAreas>;
    GetAllTechAreasDropdownRestricted(): Promise<ListTechAreas>;

    // Supply Tipology API
    GetAllTipologiaFornitura(): Promise<ListTipologiaFornitura>;
    GetTipologiaFornituraById(id: string): Promise<TipologiaFornituraModel>;
    CreateTipologiaFornitura(tipologiaFornitura: TipologiaFornituraCommand): Promise<TipologiaFornituraResponse>;
    EditSupplyTipology(id: Guid, command: EditSupplyTipologyCommand): Promise<EditSupplyTipologyResponse>;
    DeleteTipologiaFornitura(id: string): Promise<TipologiaFornituraResponse>;
    ApproveSupplyTipology(cmd: ApproveSupplyTipologyCommand): Promise<ApproveSupplyTipologyResponse>;
    ApproveDeletionSupplyTipology(cmd: ApproveDeletionSupplyTipologyCommand) : Promise<ApproveDeletionSupplyTipologyResponse>;
    GetAllSupplyTipologiesDropdown(): Promise<ListTipologiaFornitura>;

    // Economic Limit API
    GetEconomicLimits(): Promise<Array<EconomicLimitModel>>;
    GetEconomicLimit(id: string): Promise<EconomicLimitModel>;
    CreateEconomicLimit(CreateEconomicLimitCommand: CreateEconomicLimitCommand): Promise<CreateEconomicLimitResponse>;
    EditEconomicLimit(id: string, EditEconomicLimitCommand: EditEconomicLimitCommand): Promise<CreateEconomicLimitResponse>;
    GetLastEconomicLimit(): Promise<LastEconomicLimit>;
    CheckCreateEconomicLimit() : Promise<boolean>;
    CheckEditEconomicLimit(id: Guid) : Promise<boolean>;
    ApproveEconomicLimit(id: Guid) : Promise<Guid>;

    // Solution Product API
    GetAllSolutionProducts(): Promise<ListSolutionProduct>;
    GetSolutionProductById(id: Guid): Promise<SolutionProductModel>;
    CreateSolutionProduct(cmd: CreateSolutionProductCommand): Promise<CreateSolutionProductResponse>;
    EditSolutionProduct(id: Guid, cmd: EditSolutionProductCommand): Promise<EditSolutionProductResponse>;
    DeleteSolutionProduct(id: Guid): Promise<DeleteSolutionProductResponse>;
    ApproveSolutionProduct(cmd: ApproveSolutionProductCommand): Promise<ApproveSolutionProductResponse>;
    ApproveDeletionSolutionProduct(cmd: ApproveDeletionSolutionProductCommand): Promise<ApproveDeletionSolutionProductResponse>;
    GetAllSolutionProductsDropdown(): Promise<ListSolutionProduct>;

    // Area Manager API
    GetAllAreaManagers(): Promise<ListAreaManagers>;
    GetAreaManagerById(id: Guid): Promise<AreaManagerModel>;
    CreateAreaManager(cmd: CreateAreaManagerCommand): Promise<CreateAreaManagerResponse>;
    EditAreaManager(id: Guid, cmd: EditAreaManagerCommand): Promise<EditAreaManagerResponse>;
    DeleteAreaManager(id: Guid): Promise<DeleteAreaManagerResponse>;
    ApproveAreaManager(cmd: ApproveAreaManagerCommand): Promise<ApproveAreaManagerResponse>;
    ApproveDeletionAreaManager(cmd: ApproveDeletionAreaManagerCommand): Promise<ApproveDeletionAreaManagerResponse>;
    GetAllAreaManagersDropdown(): Promise<ListAreaManagers>;

    // OfferEvaluation CustomerOrder API
    GetAllOfferEvaluationCustomerOrders() : Promise<ListOfferEvaluationCustomerOrder>;
    GetAllOfferEvaluationCustomerOrdersByACOMId(acomId: Guid) : Promise<ListOfferEvaluationCustomerOrder>;
    GetAllOfferEvaluationCustomerOrdersClosed() : Promise<ListOfferEvaluationCustomerOrder>;
    GetOfferEvaluationCustomerOrderById(id: Guid) : Promise<OfferEvaluationCustomerOrderDetailsView>;
    GetOfferEvaluationCustomerOrderByIdToEdit(id: Guid) : Promise<OfferEvaluationCustomerOrderDetailsToEdit>;
    CreateOfferEvaluationCustomerOrderHeader(cmd: CreateOfferEvaluationCustomerOrderHeaderCommand) : Promise<CreateOfferEvaluationCustomerOrderHeaderResponse>;
    EditOfferEvaluationCustomerOrderHeader(cmd: EditOfferEvaluationCustomerOrderHeaderCommand) : Promise<EditOfferEvaluationCustomerOrderHeaderResponse>;
    SaveOfferEvaluationCustomerOrder(cmd: SaveOfferEvaluationCustomerOrderCommand) : Promise<SaveOfferEvaluationCustomerOrderResponse>;
    DeleteOfferEvaluationCustomerOrder(id: Guid) : Promise<DeleteOfferEvaluationCustomerOrderResponse>;
    ActiveOfferEvaluationCustomerOrder(cmd: ActiveOfferEvaluationCustomerOrderCommand) : Promise<ActiveOfferEvaluationCustomerOrderResponse>;
    RequestApprovalOfferEvaluationCustomerOrder(cmd: RequestApprovalOfferEvaluationCustomerOrderCommand) : Promise<RequestApprovalOfferEvaluationCustomerOrderResponse>;
    ApproveOfferEvaluationCustomerOrder(id: Guid) : Promise<ApproveOfferEvaluationCustomerOrderResponse>;
    CreateAssessmentOfferEvaluationCustomerOrder(id: Guid) : Promise<CreateAssessmentOfferEvaluationCustomerOrderResponse>;
    NotifyAssessmentOfferEvaluationCustomerOrder(id: Guid) : Promise<NotifyAssessmentOfferEvaluationCustomerOrderResponse>;
    RequestApprovalAssessmentOfferEvaluationCustomerOrder(id: Guid) : Promise<RequestApprovalAssessmentOfferEvaluationCustomerOrderResponse>;
    ApproveAssessmentOfferEvaluationCustomerOrder(id: Guid) : Promise<ApproveAssessmentOfferEvaluationCustomerOrderResponse>;
    PreCheckReassignmentACOMCustomerOrder(cmd: PreCheckReassignmentACOMCustomerOrderCommand) : Promise<PreCheckReassignmentACOMCustomerOrderResponse>;
    ReassignmentACOMCustomerOrder(cmd: ReassignmentACOMCustomerOrderCommand) : Promise<ReassignmentACOMCustomerOrderResponse>;
    CheckIfExistsCustomerOrderByCRMDealId(crmDealId: string) : Promise<CheckIfExistsCustomerOrderByCRMDealIdResponse>;    

    // OfferEvaluation InternalProject API
    GetAllOfferEvaluationInternalProjects() : Promise<ListOfferEvaluationInternalProject>;
    GetAllOfferEvaluationInternalProjectsClosed() : Promise<ListOfferEvaluationInternalProject>;
    GetOfferEvaluationInternalProjectById(id: Guid) : Promise<OfferEvaluationInternalProjectDetailsView>;
    GetOfferEvaluationInternalProjectByIdToEdit(id: Guid) : Promise<OfferEvaluationInternalProjectDetailsToEdit>;
    CreateOfferEvaluationInternalProjectHeader(cmd: CreateOfferEvaluationInternalProjectHeaderCommand) : Promise<CreateOfferEvaluationInternalProjectHeaderResponse>;
    EditOfferEvaluationInternalProjectHeader(cmd: EditOfferEvaluationInternalProjectHeaderCommand) : Promise<EditOfferEvaluationInternalProjectHeaderResponse>;
    SaveOfferEvaluationInternalProject(cmd: SaveOfferEvaluationInternalProjectCommand) : Promise<SaveOfferEvaluationInternalProjectResponse>;
    DeleteOfferEvaluationInternalProject(id: Guid) : Promise<DeleteOfferEvaluationInternalProjectResponse>;
    ActiveOfferEvaluationInternalProject(cmd: ActiveOfferEvaluationInternalProjectCommand) : Promise<ActiveOfferEvaluationInternalProjectResponse>;
    RequestApprovalOfferEvaluationInternalProject(cmd: RequestApprovalOfferEvaluationInternalProjectCommand) : Promise<RequestApprovalOfferEvaluationInternalProjectResponse>;
    ApproveOfferEvaluationInternalProject(id: Guid) : Promise<ApproveOfferEvaluationInternalProjectResponse>;
    CreateAssessmentOfferEvaluationInternalProject(id: Guid) : Promise<CreateAssessmentOfferEvaluationInternalProjectResponse>;
    NotifyAssessmentOfferEvaluationInternalProject(id: Guid) : Promise<NotifyAssessmentOfferEvaluationInternalProjectResponse>;
    RequestApprovalAssessmentOfferEvaluationInternalProject(id: Guid) : Promise<RequestApprovalAssessmentOfferEvaluationInternalProjectResponse>;
    ApproveAssessmentOfferEvaluationInternalProject(id: Guid) : Promise<ApproveAssessmentOfferEvaluationInternalProjectResponse>;

    // OfferEvaluation Section API
    EditFixedPriceProject(resource: string, cmd: EditFixedPriceProjectCommand) : Promise<OfferEvaluationSectionResponse>;
    EditHumanResources(resource: string, cmd: EditHumanResourcesCommand) : Promise<OfferEvaluationSectionResponse>;
    EditOtherComponents(resource: string, cmd: EditOtherComponentsCommand) : Promise<OfferEvaluationSectionResponse>;
    EditTotalAmounts(resource: string, cmd: EditTotalAmountsCommand) : Promise<OfferEvaluationSectionResponse>;
    EditAdditionalCostsPresale(resource: string, cmd: EditAdditionalCostsPresaleCommand) : Promise<OfferEvaluationSectionResponse>;
    EditOtherCostsPresale(resource: string, cmd: EditOtherCostsPresaleCommand) : Promise<OfferEvaluationSectionResponse>;
    EditAdditionalCostsPostsale(resource: string, cmd: EditAdditionalCostsPostsaleCommand) : Promise<OfferEvaluationSectionResponse>;
    EditTotalExpectedRevenueByFee(resource: string, cmd: EditTotalExpectedRevenueByFeeCommand) : Promise<OfferEvaluationSectionResponse>;
    EditAttachments(resource: string, cmd: EditAttachmentsCommand) : Promise<OfferEvaluationSectionResponse>;
    UploadFilesOfferEvaluation(resource: string, cmd: UploadFilesOfferEvaluationCommand) : Promise<OfferEvaluationSectionResponse>;
    EditDetailsInternalProject(cmd: EditDetailsInternalProjectCommand) : Promise<OfferEvaluationSectionResponse>;

    // OfferEvaluation Section API Draft
    EditFixedPriceProjectDraft(resource: string, cmd: EditFixedPriceProjectCommand) : Promise<OfferEvaluationSectionResponse>;
    EditHumanResourcesDraft(resource: string, cmd: EditHumanResourcesCommand) : Promise<OfferEvaluationSectionResponse>;
    EditOtherComponentsDraft(resource: string, cmd: EditOtherComponentsCommand) : Promise<OfferEvaluationSectionResponse>;
    EditTotalAmountsDraft(resource: string, cmd: EditTotalAmountsCommand) : Promise<OfferEvaluationSectionResponse>;
    EditAdditionalCostsPresaleDraft(resource: string, cmd: EditAdditionalCostsPresaleCommand) : Promise<OfferEvaluationSectionResponse>;
    EditOtherCostsPresaleDraft(resource: string, cmd: EditOtherCostsPresaleCommand) : Promise<OfferEvaluationSectionResponse>;
    EditAdditionalCostsPostsaleDraft(resource: string, cmd: EditAdditionalCostsPostsaleCommand) : Promise<OfferEvaluationSectionResponse>;
    EditTotalExpectedRevenueByFeeDraft(resource: string, cmd: EditTotalExpectedRevenueByFeeCommand) : Promise<OfferEvaluationSectionResponse>;
    EditDetailsInternalProjectDraft(cmd: EditDetailsInternalProjectCommand) : Promise<OfferEvaluationSectionResponse>;

    // HubSpot
    GetAllHubSpotDealsPage(after?: string) : Promise<ListHubSpotDealsResponse>;

    // Professionals API
    GetAllProfessionals(): Promise<ListProfessionals>;

    // Customer API
    GetCustomersByBusinessName(businessName: string) : Promise<ListCustomersView>;

    // Supplier API
    GetSuppliersByBusinessName(businessName: string) : Promise<ListSuppliersView>;

    // BusinessStartup CustomerOrder API
    GetAllBusinessStartupCustomerOrders() : Promise<ListBusinessStartupCustomerOrderTable>;
    GetAllBusinessStartupCustomerOrdersClosed() : Promise<ListBusinessStartupCustomerOrderTable>;
    GetBusinessStartupCustomerOrderById(id: Guid) : Promise<BusinessStartupCustomerOrderDetailsView>;
    CreateBusinessStartupCustomerOrder(cmd: CreateBusinessStartupCustomerOrderCommand) : Promise<CreateBusinessStartupCustomerOrderResponse>;
    EditOrderRequirementsReview(cmd: EditOrderRequirementsReviewCommand) : Promise<EditBusinessStartupCustomerOrderResponse>;

    // BusinessStartup InternalProject API
    GetAllBusinessStartupInternalProjects() : Promise<ListBusinessStartupInternalProjectTable>;
    GetAllBusinessStartupInternalProjectsClosed() : Promise<ListBusinessStartupInternalProjectTable>;
    GetBusinessStartupInternalProjectById(id: Guid) : Promise<BusinessStartupInternalProjectDetailsView>;
}

// GLOBAL ORDER MODELS
export enum EntityOrderStatus
{
    Created = 1 << 0,
    Approved = 1 << 1,
    DeletionRequest = 1 << 2,
    Deleted = 1 << 3,
}
export enum OrderTipology
{
    New = 1 << 0,
    Renewal = 1 << 1,
}

// BUSINESS LINE
export interface BusinessLineModel {
    Id: Guid;
    Code: string;
    Title: string;
    Status: BusinessLineStatus;
    ACOM: Array<User>;
}

export enum BusinessLineStatus {
    Created = 1 << 0,
    Approved = 1 << 1,
    DeletionRequest = 1 << 2,
    Deleted = 1 << 3,
}

export interface BusinessLineTableView {
    Id: Guid;
    Code: string;
    Title: string;
    ACOM: Array<User>;
    Status: BusinessLineStatus;
}

export interface CreateBusinessLineCommand {
    Code: string;
    Title: string;
    ACOM: Array<string>;
}

export interface CreateBusinessLineResponse {
    Id: Guid;
}

export interface DeleteBusinessLineCommand {
    Id: Guid;
}

export interface DeleteBusinessLineResponse {
    Id: Guid;
}

export interface User {
    Id: Guid;
    FullName: string;
}

export interface ApproveBusinessLineCommand {
    Id: Guid;
}

export interface ApproveBusinessLineResponse {
    Id: Guid;
}

export interface EditBusinessLineCommand
{
    Id: Guid;
    Code: string;
    Title: string;
    ACOM: Array<Guid>;
}

export interface EditBusinessLineResponse
{
    Id: Guid;
}

export interface EditAssociationBusinessLineACOMCommand {
    Id: Guid;
    ACOM: Array<Guid>;
}

export interface EditAssociationBusinessLineACOMResponse {
    Id: Guid;
}

export interface ApproveDeletionBusinessLineCommand {
    Id: Guid;
}

export interface ApproveDeletionBusinessLineResponse {
    Id: Guid;
}

// TECH AREA
export interface TechAreaModel {
    Id: Guid;
    Code: string;
    Name: string;
    Status: EntityOrderStatus;
    BusinessLine: BusinessLineModel;
    AreaManager: AreaManagerModel;
    TenantId: Guid;
}

export interface ListTechAreas {
    TechAreas: Array<TechAreaModel>;
}

export interface CreateTechAreaCommand {
    Code: string;
    Name: string;
    BusinessLineId: Guid | null;
    AreaManagerId: Guid | null;
}

export interface CreateTechAreaResponse {
    Id: Guid;
}

export interface EditTechAreaCommand
{
    Id: Guid;
    Code: string;
    Name: string;
    BusinessLineId: Guid | null;
    AreaManagerId: Guid | null;
}

export interface EditTechAreaResponse
{
    Id: Guid;
}

export interface DeleteTechAreaCommand {
    Id: Guid;
}

export interface DeleteTechAreaResponse {
    Id: Guid;
}

export interface ApproveTechAreaCommand {
    Id: Guid;
}

export interface ApproveTechAreaResponse {
    Id: Guid;
}
export interface ApproveDeletionTechAreaCommand {
    Id: Guid;
}

export interface ApproveDeletionTechAreaResponse {
    Id: Guid;
}

// SUPPLY TIPOLOGY
export interface TipologiaFornituraModel {
    Id: string;
    Name: string;
    Code: string;
    Status: EntityOrderStatus;
    Users: Array<any>;
    TenantId: string;
}

export interface TipologiaFornituraCommand {
    Name: string;
    Code: string;
    Users: Array<any>;
    Errors: Map<string, Array<string>>;
}

export interface EditSupplyTipologyCommand {
    Id: Guid;
    Code: string;
    Name: string;
}

export interface EditSupplyTipologyResponse {
    Id: Guid;
}

export interface TipologiaFornituraResponse {
    Id: Guid;
    isValid: boolean;
}
export interface ListTipologiaFornitura {
    TipologiaFornitura: Array<TipologiaFornituraModel>;
}

export interface ApproveSupplyTipologyCommand {
    Id: Guid;
}

export interface ApproveSupplyTipologyResponse {
    Id: Guid;
}

export interface ApproveDeletionSupplyTipologyCommand {
    Id: Guid;
}

export interface ApproveDeletionSupplyTipologyResponse {
    Id: Guid;
}

// ECONOMIC LIMIT
export interface EconomicLimitModel {
    Id: string,
    Year?: Date,
    OfferLimit: number,
    PurchaseInnerLimit: number,
    PurchaseAnnualLimit: number,
    PurchaseOuterLimit: number,
    MOLMin: number,
    MaxBudgetInnerProjects: number,
    ApproveDHRG: boolean,
    ApproveDG: boolean
}

export interface CreateEconomicLimitCommand {
    OfferLimit: number,
    PurchaseInnerLimit: number,
    PurchaseAnnualLimit: number,
    PurchaseOuterLimit: number,
    MOLMin: number,
    MaxBudgetInnerProjects: number
}
export interface CreateEconomicLimitResponse {
    Id: string
}

export interface EditEconomicLimitCommand {
    Id: string,
    Year?: Date,
    OfferLimit: number,
    PurchaseInnerLimit: number,
    PurchaseAnnualLimit: number,
    PurchaseOuterLimit: number,
    MOLMin: number,
    MaxBudgetInnerProjects: number,
}
export interface EditEconomicLimitResponse {
    Id: string
}

export interface LastEconomicLimit {
    Id: string,
    Year: Date
}
export interface CheckCreateEconomicLimitResponse
{
    CanCreate: boolean;
}
export interface CheckEditEconomicLimitResponse
{
    CanEdit: boolean;
}
export interface ApproveEconomicLimitResponse
{
    Id: Guid;
}

// SOLUTION PRODUCT
export interface SolutionProductModel {
    Id: Guid;
    Code: string;
    Description: string;
    Typology: string;
    TenantId: string;
    Status: EntityOrderStatus;
}

export interface SolutionProductTableView {
    Id: Guid;
    Code: string;
    Description: string;
    Typology: string;
}

export interface CreateSolutionProductCommand {
    Code: string;
    Description: string;
    Typology: string;
    Errors: Map<string, Array<string>>;
}

export interface CreateSolutionProductResponse {
    Id: Guid;
}

export interface EditSolutionProductCommand
{
    Id: Guid;
    Code: string;
    Description: string;
    Typology: string;
}

export interface EditSolutionProductResponse
{
    Id: Guid;
}

export interface DeleteSolutionProductCommand {
    Id: Guid;
}

export interface DeleteSolutionProductResponse {
    Id: Guid;
}

export interface ListSolutionProduct {
    SolutionProducts: Array<SolutionProductModel>;
}

export interface ApproveSolutionProductCommand {
    Id: Guid;
}

export interface ApproveSolutionProductResponse {
    Id: Guid;
}

export interface ApproveDeletionSolutionProductCommand {
    Id: Guid;
}

export interface ApproveDeletionSolutionProductResponse {
    Id: Guid;
}

// AREA MANAGER
export interface AreaManagerModel {
    Id: Guid;
    CostCenter: string;
    User: User;
    Status: EntityOrderStatus;
    TenantId: Guid;
}

export interface ListAreaManagers {
    AreaManagers: Array<AreaManagerModel>;
}

export interface CreateAreaManagerCommand {
    CostCenter: string;
    UserId: Guid | null;
}

export interface CreateAreaManagerResponse {
    Id: Guid;
}

export interface EditAreaManagerCommand {
    Id: Guid;
    CostCenter: string;
    UserId: Guid | null;
}

export interface EditAreaManagerResponse {
    Id: Guid;
}

export interface DeleteAreaManagerResponse {
    Id: Guid;
}

export interface ApproveAreaManagerCommand {
    Id: Guid;
}

export interface ApproveAreaManagerResponse {
    Id: Guid;
}

export interface ApproveDeletionAreaManagerCommand {
    Id: Guid;
}

export interface ApproveDeletionAreaManagerResponse {
    Id: Guid;
}

// OfferEvaluation CustomerOrder
export interface ListOfferEvaluationCustomerOrder
{
    OfferEvaluationCustomerOrders: Array<OfferEvaluationCustomerOrderTableView>;
}
export interface OfferEvaluationCustomerOrderTableView
{
    Id: Guid;
    ACOM: string;
    CustomerName: string;
    Description: string;
    OrderProtocol: string;
    Blobs: Array<BlobMetaDataView>;
}
export interface OfferEvaluationCustomerOrderDetailsView extends BaseOfferEvaluationView
{
    Id: Guid;
    ACOM: User;
    CustomerName: string;
    Description: string;
    OrderProtocol: string;
    Type: string;
    MasterAgreement: boolean;
    // CRM
    CRMDealId: string;
    CRMAmount: number;
    CRMLastUpdate: Date;
    // Approval
    ApprovalOfferDC: boolean;
    ApprovalOfferLimitDC: boolean;
    ApprovalOfferLimitDG: boolean;
    ApprovalMOLDC: boolean;
    ApprovalMOLDG: boolean;
    ApprovalMinimumMOLDC: boolean;
    ApprovalMinimumMOLDG: boolean;
    ReviewSG: boolean;
    ApprovalDHRG: boolean;
    // Exceeded Limits
    ExceedOfferLimit: boolean;
    NotExceedMOL: boolean;
    FixedPriceProject: FixedPriceProjectSectionView;
    HumanResources: HumanResourcesSectionView;
    OtherComponents: OtherComponentsView;
    TotalAmounts: TotalAmountsSectionView;
    TotalAmountsDiscounted: TotalAmountsDiscountedSectionView;
    AdditionalCostsPresale: AdditionalCostsPresaleSectionView;
    OtherCostsPresale: OtherCostsPresaleSectionView;
    TotalCostsPresale: TotalCostsPresaleSectionView;
    AdditionalCostsPostsale: AdditionalCostsPostsaleSectionView;
    TotalCostsPostsale: TotalCostsPostsaleSectionView;
    TotalsOfferEvaluation: TotalsOfferEvaluationSectionView;
    FeeTotalExpectedRevenue: FeeTotalExpectedRevenueSectionView;
    Versions: Array<OfferEvaluationCustomerOrderVersionView>;
}
export interface OfferEvaluationCustomerOrderVersionView
{
    Version: number;
    LastModified: Date;
    InternalProject: OfferEvaluationCustomerOrderDetailsView;
}
export interface OfferEvaluationCustomerOrderDetailsToEdit extends BaseOfferEvaluationViewToEdit
{
    Id: Guid;
    CustomerName: string;
    Description: string;
    OrderProtocol: string;
    Type: OrderTipology;
    MasterAgreement: boolean;
    // CRM
    CRMDealId: string;
    CRMAmount: number;
    CRMLastUpdate: Date;

    FixedPriceProject: EditFixedPriceProjectCommand;
    HumanResources: EditHumanResourcesCommand;
    OtherComponents: EditOtherComponentsCommand;
    TotalAmounts: EditTotalAmountsCommand;
    AdditionalCostsPresale: EditAdditionalCostsPresaleCommand;
    OtherCostsPresale: EditOtherCostsPresaleCommand;
    AdditionalCostsPostsale: EditAdditionalCostsPostsaleCommand;
    FeeTotalExpectedRevenue: EditTotalExpectedRevenueByFeeCommand;
}
export interface CreateOfferEvaluationCustomerOrderHeaderCommand
{
    ACOMId: Guid | null;
    CustomerName: string;
    Description: string;
    Type: OrderTipology;
    MasterAgreement: boolean;
    // CRM
    CRMDealId: string;
    CRMAmount: number;
    CRMLastUpdate: Date;
}
export interface CreateOfferEvaluationCustomerOrderHeaderResponse
{
    Id: Guid;
}
export interface EditOfferEvaluationCustomerOrderHeaderCommand
{
    Id: Guid;
    ACOMId: Guid | null;
    CustomerName: string;
    Description: string;
    OrderProtocol: string;
    Type: OrderTipology;
    MasterAgreement: boolean;
    // CRM
    CRMDealId: string;
    CRMAmount: number;
    CRMLastUpdate: Date;
}
export interface EditOfferEvaluationCustomerOrderHeaderResponse
{
    Id: Guid;
}
export interface SaveOfferEvaluationCustomerOrderCommand
{
    Id: Guid;
}
export interface SaveOfferEvaluationCustomerOrderResponse
{
    Id: Guid;
    WarningMessages: Array<string>;
}
export interface DeleteOfferEvaluationCustomerOrderResponse
{
    Id: Guid;
}
export interface ActiveOfferEvaluationCustomerOrderCommand
{
    Id: Guid;
}
export interface ActiveOfferEvaluationCustomerOrderResponse
{
    Id: Guid;
    WarningMessages: Array<string>;
}
export interface RequestApprovalOfferEvaluationCustomerOrderCommand
{
    Id: Guid;
}
export interface RequestApprovalOfferEvaluationCustomerOrderResponse
{
    Id: Guid;
}
export interface ApproveOfferEvaluationCustomerOrderResponse
{
    Id: Guid;
}
export interface CreateAssessmentOfferEvaluationCustomerOrderResponse
{
    Id: Guid;
}
export interface NotifyAssessmentOfferEvaluationCustomerOrderResponse
{
    Id: Guid;
    WarningMessages: Array<string>;
}
export interface RequestApprovalAssessmentOfferEvaluationCustomerOrderResponse
{
    Id: Guid;
}
export interface ApproveAssessmentOfferEvaluationCustomerOrderResponse
{
    Id: Guid;
}
export interface ReassignmentACOMCustomerOrderCommand
{
    SendMailACOMOutcoming: boolean;
    ACOMOutcomingId: Guid;
    Reassignments: Array<ReassignmentSingleACOMCommand>;
}
export interface ReassignmentACOMCustomerOrderResponse
{
    Id: Guid;
}
export interface ReassignmentSingleACOMCommand
{
    CustomerOrderId: Guid;
    ACOMIncomingId: Guid;
}
export interface PreCheckReassignmentACOMCustomerOrderCommand
{
    Reassingments: Array<ReassignmentSingleACOMCommand>;
}
export interface PreCheckReassignmentACOMCustomerOrderResponse
{
    Checked: boolean;
}
export interface CheckIfExistsCustomerOrderByCRMDealIdResponse
{
    Checked: boolean;
}


// OfferEvaluation InternalProjects
export interface ListOfferEvaluationInternalProject
{
    OfferEvaluationInternalProjects: Array<OfferEvaluationInternalProjectTableView>;
}
export interface OfferEvaluationInternalProjectTableView
{
    Id: Guid;
    Title: string;
    ExpectedCost: number;
    Date: Date;
    Blobs: Array<BlobMetaDataView>;
}
export interface OfferEvaluationInternalProjectDetailsView extends BaseOfferEvaluationView
{
    Id: Guid;
    Title: string;
    Date: Date;
    BusinessLine: string;
    TechArea: string;
    SupplyTipology: string;
    // Approval
    ApprovalProjectDG: boolean;
    ApprovalSpendingBudgetDG: boolean;
    ReviewSG: boolean;
    // Exceeded Limits
    ExceedBudget: boolean;
    Details: DetailsInternalProjectView;
    Versions: Array<OfferEvaluationInternalProjectVersionView>;
}
export interface OfferEvaluationInternalProjectVersionView
{
    Version: number;
    LastModified: Date;
    InternalProject: OfferEvaluationInternalProjectDetailsView;
}
export interface OfferEvaluationInternalProjectDetailsToEdit extends BaseOfferEvaluationViewToEdit
{
    Id: Guid;
    Title: string;
    Date: Date;
    BusinessLineId: Guid | null;
    TechAreaId: Guid | null;
    SupplyTipologyId: Guid | null;
    Details: EditDetailsInternalProjectCommand;
}
export interface CreateOfferEvaluationInternalProjectHeaderCommand
{
    Title: string;
    Date: Date;
    BusinessLineId: Guid | null;
    TechAreaId: Guid | null;
    SupplyTipologyId: Guid | null;
}
export interface CreateOfferEvaluationInternalProjectHeaderResponse
{
    Id: Guid;
}
export interface EditOfferEvaluationInternalProjectHeaderCommand
{
    Id: Guid;
    Title: string;
    Date: Date;
    BusinessLineId: Guid | null;
    TechAreaId: Guid | null;
    SupplyTipologyId: Guid | null;
}
export interface EditOfferEvaluationInternalProjectHeaderResponse
{
    Id: Guid;
}
export interface SaveOfferEvaluationInternalProjectCommand
{
    Id: Guid;
}
export interface SaveOfferEvaluationInternalProjectResponse
{
    Id: Guid;
}
export interface DeleteOfferEvaluationInternalProjectResponse
{
    Id: Guid;
}
export interface ActiveOfferEvaluationInternalProjectCommand
{
    Id: Guid;
}
export interface ActiveOfferEvaluationInternalProjectResponse
{
    Id: Guid;
    WarningMessages: Array<string>;
}
export interface RequestApprovalOfferEvaluationInternalProjectCommand
{
    Id: Guid;
}
export interface RequestApprovalOfferEvaluationInternalProjectResponse
{
    Id: Guid;
}
export interface ApproveOfferEvaluationInternalProjectResponse
{
    Id: Guid;
}
export interface CreateAssessmentOfferEvaluationInternalProjectResponse
{
    Id: Guid;
}
export interface NotifyAssessmentOfferEvaluationInternalProjectResponse
{
    Id: Guid;
    WarningMessages: Array<string>;
}
export interface RequestApprovalAssessmentOfferEvaluationInternalProjectResponse
{
    Id: Guid;
}
export interface ApproveAssessmentOfferEvaluationInternalProjectResponse
{
    Id: Guid;
}

// OfferEvaluation Sections
// Views
export interface BaseOfferEvaluationView
{
    Attachments: AttachmentsSectionView;
    Status: StatusOfferEvaluation;
}
export enum StatusOfferEvaluation
{
    Draft = 1 << 0,
    Saved = 1 << 1,
    ApprovalRequestable = 1 << 2,
    Activating = 1 << 3,
    RequestApprovalDHRG = 1 << 4,
    Approved = 1 << 5,
    Closed = 1 << 6,
}
export interface FixedPriceProjectSectionView
{
    TotalTaxableAmount: number;
}
export interface HumanResourcesSectionView
{
    ExpectedMen: number;
    OfferedMen: number;
    MarginRisk: number;
    OfferAmount: number;
    ExpectedCost: number;
    ExpectedMargin: number;
    ExpectedMarginPercentage: number;
    Resources: Array<ResourceDetailsView>;
}
export interface ResourceDetailsView
{
    BusinessLine: string;
    TechArea: string;
    SupplyTipology: string;
    Professional: string;
    Company: string;
    ResourceName: string;
    ExpectedMen: number;
    OfferedMen: number;
    MarginRisk: number;
    DailyPriceOffer: number;
    OfferAmount: number;
    MiniumDailyPrice: number;
    ExpectedCost: number;
    ExpectedMargin: number;
    ExpectedMarginPercentage: number;
}
export interface OtherComponentsView
{
    ProductsPurchase: ProductsPurchaseComponentDetailsView;
    SolutionsPurchase: SolutionsPurchaseComponentDetailsView;
    ServicesPurchase: ServicesPurchaseComponentDetailsView;
    TransferCosts: ComponentDetailsView;
    SecurityCosts: ComponentDetailsView;
    Others: Array<ComponentDetailsView>;

    //Subtotals
    OfferAmount: number;
    ExpectedCost: number;
    ExpectedMargin: number;
    ExpectedMarginPercentage: number;
}
export interface ComponentDetailsView
{
    BusinessLine: string;
    TechArea: string;
    SupplyTipology: string;

    Description: string;
    OfferAmount: number;
    ExpectedCost: number;
    ExpectedMargin: number;
    ExpectedMarginPercentage: number;
}
export interface ServicesPurchaseComponentDetailsView extends ComponentDetailsView
{
    SupplierCode: string;
    SupplierBusinessName: string;
}
export interface ProductsPurchaseComponentDetailsView extends ServicesPurchaseComponentDetailsView
{
    ProductCatalog: string;
}
export interface SolutionsPurchaseComponentDetailsView extends ServicesPurchaseComponentDetailsView
{
    SolutionCatalog: string;
}
export interface TotalAmountsSectionView
{
    OfferAmounts: number;
    ExpectedCosts: number;
    ExpectedMargins: number;
    ExpectedMarginsPercentage: number;
    Discount: number;
}
export interface TotalAmountsDiscountedSectionView
{
    OfferAmounts: number;
    ExpectedCosts: number;
    ExpectedMargins: number;
    ExpectedMarginsPercentage: number;
}
export interface AdditionalCostsPresaleSectionView
{
    AdditionalCosts: Array<AdditionalCostPresaleDetailsView>;
    // Subtotals
    DaysMen: number;
    ExpectedCost: number;
}
export interface AdditionalCostPresaleDetailsView
{
    BusinessLine: string;
    TechArea: string;
    SupplyTipology: string;

    Professional: string;
    Company: string;
    ResourceName: string;

    DaysMen: number;
    DailyCost: number;
    ExpectedCost: number;
}
export interface OtherCostsPresaleSectionView
{
    ExpectedCost: number;
    OtherCosts: Array<OtherCostPresaleDetailsView>;
}
export interface OtherCostPresaleDetailsView
{
    Description: string;
    SupplierCode: string;
    SupplierBusinessName: string;
    ExpectedCost: number;
}
export interface TotalCostsPresaleSectionView
{
    TotalCosts: number;
}
export interface AdditionalCostsPostsaleSectionView
{
    Maintenance: AdditionalCostPostsaleDetailsView;
    Warranty: AdditionalCostPostsaleDetailsView;
    Support: AdditionalCostPostsaleDetailsView;
    Others: Array<AdditionalCostPostsaleDetailsView>;
}
export interface AdditionalCostPostsaleDetailsView
{
    BusinessLine: string;
    TechArea: string;
    SupplyTipology: string;
    
    Description: string;
    ExpectedCost: number;
}
export interface TotalCostsPostsaleSectionView
{
    TotalCosts: number;
}
export interface TotalsOfferEvaluationSectionView
{
    OfferAmounts: number;
    ExpectedCosts: number;
    ExpectedMargins: number;
    ExpectedMarginsPercentage: number;
}
export interface FeeTotalExpectedRevenueSectionView
{
    ExpectedFee: number;
    TotalExpectedRevenue: number;
    ExpectedCosts: number;
    ExpectedMargins: number;
    ExpectedMarginsPercentage: number;
}
export interface AttachmentsSectionView
{
    Notes: string;
    Blobs: Array<BlobMetaDataView>;
}
export interface BlobMetaDataView
{
    Name: string;
    UriSASToken: UrlString;
}
export interface DetailsInternalProjectView
{
    Description: string;
    ACOM: string;
    DaysMenExpected: number;
    ExpectedCost: number;
    Market: boolean;
    InternalUse: boolean;
}

// Commands and Response
export interface BaseOfferEvaluationViewToEdit
{
    Attachments: EditAttachmentsCommand;
}
export interface OfferEvaluationSectionResponse
{
    Id: Guid;
}
export interface EditFixedPriceProjectCommand
{
    Id: Guid;
    TotalTaxableAmount: number;
}
export interface EditHumanResourcesCommand
{
    Id: Guid;
    Resources: Array<ResourceDetailsCommand>;
}
export interface ResourceDetailsCommand
{
    BusinessLineId: Guid | null;
    TechAreaId: Guid | null;
    SupplyTipologyId: Guid | null;
    Professional: string;
    Company: string;
    ResourceNameId: Guid | null;
    ResourceName: string;

    ExpectedMen: number;
    OfferedMen: number;

    DailyPriceOffer: number;
    MiniumDailyPrice: number;
}
export interface EditOtherComponentsCommand
{
    Id: Guid;
    ProductsPurchase: ProductsPurchaseComponentDetailsCommand;
    SolutionsPurchase: SolutionsPurchaseComponentDetailsCommand;
    ServicesPurchase: ServicesPurchaseComponentDetailsCommand;
    TransferCosts: ComponentDetailsCommand; 
    SecurityCosts: ComponentDetailsCommand;
    Others: Array<ComponentDetailsCommand>;
}
export interface ComponentDetailsCommand
{
    BusinessLineId: Guid | null;
    TechAreaId: Guid | null;
    SupplyTipologyId: Guid | null;
    Description: string;

    OfferAmount: number;
    ExpectedCost: number;
}
export interface ServicesPurchaseComponentDetailsCommand extends ComponentDetailsCommand
{
    SupplierCode: string;
    SupplierBusinessName: string;
}

export interface ProductsPurchaseComponentDetailsCommand extends ServicesPurchaseComponentDetailsCommand
{
    ProductCatalogId: Guid | null;
}
export interface SolutionsPurchaseComponentDetailsCommand extends ServicesPurchaseComponentDetailsCommand
{
    SolutionCatalogId: Guid | null;
}
export interface EditTotalAmountsCommand
{
    Id: Guid;
    Discount: number;
}
export interface EditAdditionalCostsPresaleCommand
{
    Id: Guid;
    AdditionalCosts: Array<AdditionalCostPresaleDetailsCommand>;
}
export interface AdditionalCostPresaleDetailsCommand
{
    BusinessLineId: Guid | null;
    TechAreaId: Guid | null;
    SupplyTipologyId: Guid | null;

    Professional: string;
    Company: string;
    ResourceNameId: Guid | null;
    ResourceName: string;

    DaysMen: number;
    DailyCost: number;
}
export interface EditOtherCostsPresaleCommand
{
    Id: Guid;
    OtherCosts: Array<OtherCostPresaleDetailsCommand>;
}
export interface OtherCostPresaleDetailsCommand
{
    Description: string;
    SupplierCode: string;
    SupplierBusinessName: string;

    ExpectedCost: number;
}
export interface EditAdditionalCostsPostsaleCommand
{
    Id: Guid;
    Maintenance: AdditionalCostPostsaleDetailsCommand;
    Warranty: AdditionalCostPostsaleDetailsCommand;
    Support: AdditionalCostPostsaleDetailsCommand;
    Others: Array<AdditionalCostPostsaleDetailsCommand>;
}
export interface AdditionalCostPostsaleDetailsCommand
{
    BusinessLineId: Guid | null;
    TechAreaId: Guid | null;
    SupplyTipologyId: Guid | null;

    Description: string;
    ExpectedCost: number;
}
export interface EditTotalExpectedRevenueByFeeCommand
{
    Id: Guid;
    ExpectedFee: number;
}
export interface EditAttachmentsCommand
{
    Id: Guid;
    Notes: string;
    BlobsName: Array<string>;
}
export interface UploadFilesOfferEvaluationCommand
{
    Id: Guid;
    Files: Array<File>;
}
export interface EditDetailsInternalProjectCommand
{
    Id: Guid;
    Description: string;
    DaysMenExpected: number;
    ExpectedCost: number;
    Market: boolean;
    InternalUse: boolean;
    ACOMId: Guid | null;
}

// HubSpot
export interface ListHubSpotDealsResponse
{
    Results: Array<HubSpotDealDetailViewModel>;
}
export interface HubSpotDealDetailViewModel
{
    Id: string,
    CreatedAt: Date,
    ClosedAt: Date,
    Properties: ListSingleHubSpotDealsPropertiesViewModel;
}  
interface ListSingleHubSpotDealsPropertiesViewModel
{
    Amount: number;
    CloseDate: Date;
    CreateDate: Date;
    DealName: string;
    DealStage: string;
    HsLastModifiedDate: Date;
    HubSpotOwnerId: string;
    HubSpotOwnerFullName: string;
    BusinessLine: string;
    TechArea : string;
    TechnologiaLead: string;
}

// Professionals
export interface ListProfessionals
{
    Professionals: Array<string>;
}

// Customers
export interface ListCustomersView
{
    Customers: Array<CustomerView>,
}
export interface CustomerView
{
    Id: Guid,
    Code: string,
    BusinessName: string,
}

// Suppliers
export interface ListSuppliersView
{
    Suppliers: Array<SupplierView>,
}
export interface SupplierView
{
    Id: Guid,
    Code: string,
    BusinessName: string,
}

// BusinessStartup: Customer Order
export interface ListBusinessStartupCustomerOrderTable
{
    CustomerOrders: Array<BusinessStartupCustomerOrderViewTable>,
}
export interface BusinessStartupCustomerOrderViewTable
{
    Id: Guid,
    ACOM: string,
    Customer: string,
    FinalCustomer: string,
    Description: string,
    OrderProtocol: string,
    ContractProtocol: string,
}
export interface BusinessStartupCustomerOrderDetailsView
{
    Id: Guid,
    Status: BusinessStartupCustomerOrderStatus,
    OrderRequirementsReview: OrderRequirementsReviewSectionView;
}
export interface OrderRequirementsReviewSectionView
{
    ACOM: string;

    Customer: string;
    FinalCustomer: string;
    Description: string;

    AdvanceSupply: boolean;
    StartDate: Date;
    ExpectedEndDate: Date;
    Place: PlaceType;
    Address: string | null;
    CustomerContractManager: string;
    OtherCustomerReferrals: Array<string>;

    BusinessLine: string;
    TechArea: string;
    SupplyTipology: string;

    Deliverables: Array<DeliverablesModel>;
    AreaManager: string;
    Privacy: boolean;
    Notes: string;
}
export interface CreateBusinessStartupCustomerOrderCommand
{
    Customer: string;
    FinalCustomer: string;
    Description: string;
    AdvanceSupply: boolean;
    StartDate: Date;
    ExpectedEndDate: Date;
    Place: PlaceType;
    Address: string | null;
    CustomerContractManager: string;
    OtherCustomerReferrals: Array<string>;
    BusinessLineId: Guid | null;
    TechAreaId: Guid | null;
    SupplyTipologyId: Guid | null;
    Deliverables: Array<DeliverablesModel>;
    Privacy: boolean;
    Notes: string;
}
export interface CreateBusinessStartupCustomerOrderResponse
{
    Id: Guid;
}
export interface EditBusinessStartupCustomerOrderResponse
{
    Id: Guid;
}
export interface EditOrderRequirementsReviewCommand
{
    Id: Guid;
    FinalCustomer: string;
    Description: string;
    AdvanceSupply: boolean;
    StartDate: Date;
    ExpectedEndDate: Date;
    Place: PlaceType;
    Address: string | null;
    CustomerContractManager: string;
    OtherCustomerReferrals: Array<string>;
    Deliverables: Array<DeliverablesModel>;
    Privacy: boolean;
    Notes: string;
}
export interface DeliverablesModel
{
    DeliverableName: string;
    DeliveryBy: Date;
}
export enum PlaceType
{
    Proge = 1 << 0,
    Customer = 1 << 1,
}
export enum BusinessStartupCustomerOrderStatus
{
    Saved = 1 << 0,
    Activated = 1 << 1,
    Closed = 1 << 2,
}

// BusinessStartup: Internal Project
export interface ListBusinessStartupInternalProjectTable
{
    InternalProjects: Array<BusinessStartupInternalProjectViewTable>,
}
export interface BusinessStartupInternalProjectViewTable
{
    Id: Guid,
    Title: string,
    StartDate: Date,
    ExpectedEndDate: Date,
    Code: string,
}
export interface BusinessStartupInternalProjectDetailsView
{
    Id: Guid,
    Status: BusinessStartupInternalProjectStatus,
    ProjectRequirementsReview: ProjectRequirementsReviewSectionView;
}
export interface ProjectRequirementsReviewSectionView
{
    Title: string;
    Description: string;
    StartDate: Date;
    ExpectedEndDate: Date;

    BusinessLine: string;
    TechArea: string;
    SupplyTipology: string;

    Deliverables: Array<DeliverablesModel>;
    AreaManager: string;
    Notes: string;
}
export enum BusinessStartupInternalProjectStatus
{
    Saved = 1 << 0,
    Activated = 1 << 1,
    Closed = 1 << 2,
}