

















import Vue from "vue";
import { Component, Prop, Model } from "vue-property-decorator";
import { Skill, UserSkillResource } from "@/services/iResourceService";

@Component({})
export default class PgaUserSkill extends Vue {
	@Prop({ type: String }) Title?: string;
	@Model("", { type: Number }) Model!: Skill;
	@Prop({ default: false }) readonly readonly!: boolean;
	@Prop({ type: Object }) readonly User!: UserSkillResource;

	private skill: Skill = this.Model;

	get color(): string {
		switch (this.skill) {
			case Skill.Basic:
				return "error";
			case Skill.Intermediate:
				return "warning";
			case Skill.Advanced:
				return "success";
			default:
				return "";
		}
	}

	get icon(): string {
		switch (this.skill) {
			case Skill.Basic:
				return "mdi-star-circle";
			case Skill.Intermediate:
				return "mdi-star-circle";
			case Skill.Advanced:
				return "mdi-star-circle";
			default:
				return "mdi-star-circle";
		}
	}

	get tooltip(): string {
		switch (this.skill) {
			case Skill.Basic:
				return "Basic";
			case Skill.Intermediate:
				return "Intermediate";
			case Skill.Advanced:
				return "Advanced";
			default:
				return "Noob";
		}
	}

	get titleDefined(): boolean {
		return this.Title != null;
	}
	get title(): string {
		return this.Title || "";
	}

	private ChangeSkill(): void
	{
		if(!this.readonly)
		{
			++this.skill;
			if(this.skill > 3)
				this.skill = 0;
			
			this.$emit('change-skill', this.User.Id, this.skill );
		}
	}
}
