import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import iAxiosService from './iAxiosService'
import { injectable } from 'inversify'
import { SharedModule } from '@/feature/Shared/store'
import urljoin from 'url-join'
import Axios from 'axios';
import AuthRoutes from './AuthRoutes'

interface FailedJob {
    resolve: (value?: unknown) => void,
    reject: (reason?: any) => void,
}

@injectable()
export default class AxiosService implements iAxiosService {
    protected readonly API_URL: string = process.env.VUE_APP_BASE_URL ?? '';

    static isRefreshing = false;
    static failedQueue = new Array<FailedJob>();

    private static processQueue(error?: Error, token?: string) {
        AxiosService.failedQueue.forEach((prom: FailedJob) => {
            if (error) {
                prom.reject(error);
            } else {
                prom.resolve(token);
            }
        })

        AxiosService.failedQueue = [];
    }
    private static getTrimmedBaseUrl(): string {
        return this.trimUrl(process.env.VUE_APP_BASE_URL as string);
    }

    private static trimUrl(url: string): string {
        return url.replace(/\/$/g, "")
    }

    private static getRequestBaseUrl(url: string): string {
        const pathArray = url.split('/');
        const protocol = pathArray[0];
        const host = pathArray[2];
        return protocol + '//' + host;
    }

    public static Init(): void {
        this.registerRequestInterceptor();
        this.registerResponseInterceptor();
    }

    public static registerRequestInterceptor(): void {
        Axios.interceptors.request.use(config => {
            if (!AxiosService.isPga(config.url!) || AuthRoutes.IsAuthEndpoint(config.url!)) {
                return config;
            }

            // if it a call to PGA API add the PGA Access Token
            const token = SharedModule.PGAAccessToken;
            if (token && config && config.headers) {
                config.headers['Authorization'] = `Bearer ${token}`;
            }
            return config;
        }, error => Promise.reject(error));
    }

    public static isPga(url: string): boolean {
        const baseurl = this.getTrimmedBaseUrl();
        const reqBaseUrl = this.getRequestBaseUrl(url);

        return reqBaseUrl == baseurl;
    }



    public static registerResponseInterceptor(): void {
        Axios.interceptors.response.use(
            response => response,
            async error => {
                const {
                    config,
                    response: { status }
                } = error;

                if (status != 401 || config._retry) {
                    return Promise.reject(error);
                }

                if (this.isRefreshing) {
                    return new Promise(function (resolve, reject) {
                        AxiosService.failedQueue.push({ resolve, reject });
                    }).then(() => {
                        const token = SharedModule.PGAAccessToken;
                        if (token) {
                            config.headers['Authorization'] = `Bearer ${token}`;
                        }
                        return axios(config);
                    }).catch(err => {
                        return Promise.reject(err);
                    });
                }

                config._retry = true;
                this.isRefreshing = true;
                //SharedModule.SET_PGA_TOKENS(null);

                try {
                    await SharedModule.RefreshToken()
                    const token = SharedModule.PGAAccessToken;
                    config.headers['Authorization'] = `Bearer ${token}`;
                    AxiosService.processQueue(undefined, token);
                    return Promise.resolve(axios(config));
                } catch (e) {
                    error.nested = e;
                    try {
                        await SharedModule.silentCheckToken();
                        const token = SharedModule.PGAAccessToken;
                        config.headers['Authorization'] = `Bearer ${token}`;
                        AxiosService.processQueue(undefined, token);
                        return Promise.resolve(axios(config));
                    } catch (e2) {
                        e.nested = e2;
                        AxiosService.processQueue(error, undefined);
                        return Promise.reject(error);
                    }
                } finally {
                    this.isRefreshing = false;
                }
            });
    }

    public async BaseGet<T>(url: string): Promise<T> {
        const completeUrl = this.BuildCompleteUrl(url);
        const httpResponse = await axios.get<T>(completeUrl);
        return httpResponse.data;
    }

    public async CustomGet<T>(url: string, config: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        const completeUrl = this.BuildCompleteUrl(url);
        return await axios.get<T>(completeUrl, config);
    }

    public async BasePost<T, V>(url: string, data: T): Promise<V> {
        const completeUrl = this.BuildCompleteUrl(url);
        const httpResponse = await axios.post<V>(completeUrl, data);
        return httpResponse.data;
    }

    public async CustomPost<T, V>(url: string, data: T, config: AxiosRequestConfig): Promise<AxiosResponse<V>> {
        const completeurl = this.BuildCompleteUrl(url);
        const httpResponse = await axios.post<V>(completeurl, data, config);
        return httpResponse;
    }

    public async BaseDelete<T>(url: string): Promise<T> {
        const completeUrl = this.BuildCompleteUrl(url);
        const httpResponse = await axios.delete<T>(completeUrl);
        return httpResponse.data;
    }

    public async BasePatch<T, V>(url: string, data: T): Promise<V> {
        const completeUrl = this.BuildCompleteUrl(url);
        const httpResponse = await axios.patch<V>(completeUrl, data);
        return httpResponse.data;
    }

    private BuildCompleteUrl(...parts: string[]): string {
        return urljoin(this.API_URL, ...parts);
    }
}