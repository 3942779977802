







import Vue from 'vue'
import { Emit, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class PgaApproveButton extends Vue {
	@Prop({ default: "Approve" }) readonly text! : string;

    buttons = {
		approve: {
			Icon: "mdi-check",
            Text: "Approve",
		}
    }
    
    @Emit("click")
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    clicked() : void { }
}
