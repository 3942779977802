import IOrderService from './iOrderService';

// Import BusinessLine
import { BusinessLineModel, BusinessLineTableView,
        ApproveBusinessLineCommand, ApproveDeletionBusinessLineCommand, CreateBusinessLineCommand, DeleteBusinessLineCommand, EditAssociationBusinessLineACOMCommand, EditBusinessLineCommand,
        ApproveBusinessLineResponse, ApproveDeletionBusinessLineResponse, CreateBusinessLineResponse, DeleteBusinessLineResponse, EditAssociationBusinessLineACOMResponse, EditBusinessLineResponse } from "./iOrderService";

// Import TechArea
import { TechAreaModel, ListTechAreas,
        ApproveTechAreaCommand, ApproveDeletionTechAreaCommand, CreateTechAreaCommand, EditTechAreaCommand,
        ApproveTechAreaResponse, ApproveDeletionTechAreaResponse, CreateTechAreaResponse, DeleteTechAreaResponse, EditTechAreaResponse } from './iOrderService';

// Import SupplyTipology
import { TipologiaFornituraModel, ListTipologiaFornitura,
        ApproveDeletionSupplyTipologyCommand, ApproveSupplyTipologyCommand, TipologiaFornituraCommand, EditSupplyTipologyCommand,
        ApproveDeletionSupplyTipologyResponse, ApproveSupplyTipologyResponse, TipologiaFornituraResponse, EditSupplyTipologyResponse } from './iOrderService';

// Import EconomicLimit
import { EconomicLimitModel, LastEconomicLimit,
        CreateEconomicLimitCommand, EditEconomicLimitCommand,
        CreateEconomicLimitResponse, EditEconomicLimitResponse, CheckCreateEconomicLimitResponse, CheckEditEconomicLimitResponse, ApproveEconomicLimitResponse } from './iOrderService';

// Import SolutionProduct
import {  SolutionProductModel, ListSolutionProduct,
        CreateSolutionProductCommand, ApproveSolutionProductCommand, ApproveDeletionSolutionProductCommand, EditSolutionProductCommand,
        CreateSolutionProductResponse, DeleteSolutionProductResponse, ApproveSolutionProductResponse, ApproveDeletionSolutionProductResponse, EditSolutionProductResponse } from './iOrderService';

// Import AreaManager
import { ListAreaManagers, AreaManagerModel,
        ApproveAreaManagerCommand, ApproveDeletionAreaManagerCommand, CreateAreaManagerCommand, EditAreaManagerCommand,
        ApproveAreaManagerResponse, ApproveDeletionAreaManagerResponse, CreateAreaManagerResponse, DeleteAreaManagerResponse, EditAreaManagerResponse, } from './iOrderService';

// Import OfferEvaluation CustomerOrder
import { ListOfferEvaluationCustomerOrder, OfferEvaluationCustomerOrderDetailsView, OfferEvaluationCustomerOrderDetailsToEdit,
        CreateOfferEvaluationCustomerOrderHeaderCommand, SaveOfferEvaluationCustomerOrderCommand, EditOfferEvaluationCustomerOrderHeaderCommand, 
            ActiveOfferEvaluationCustomerOrderCommand, RequestApprovalOfferEvaluationCustomerOrderCommand, PreCheckReassignmentACOMCustomerOrderCommand,
            ReassignmentACOMCustomerOrderCommand,
        CreateOfferEvaluationCustomerOrderHeaderResponse, SaveOfferEvaluationCustomerOrderResponse, EditOfferEvaluationCustomerOrderHeaderResponse,
            ActiveOfferEvaluationCustomerOrderResponse, RequestApprovalOfferEvaluationCustomerOrderResponse, CreateAssessmentOfferEvaluationCustomerOrderResponse,
            NotifyAssessmentOfferEvaluationCustomerOrderResponse, RequestApprovalAssessmentOfferEvaluationCustomerOrderResponse, ApproveOfferEvaluationCustomerOrderResponse,
            ApproveAssessmentOfferEvaluationCustomerOrderResponse, DeleteOfferEvaluationCustomerOrderResponse, PreCheckReassignmentACOMCustomerOrderResponse,
            ReassignmentACOMCustomerOrderResponse, CheckIfExistsCustomerOrderByCRMDealIdResponse } from './iOrderService';

// Import OfferEvaluation InternalProject
import { ListOfferEvaluationInternalProject, OfferEvaluationInternalProjectDetailsView, OfferEvaluationInternalProjectDetailsToEdit,
        CreateOfferEvaluationInternalProjectHeaderCommand, SaveOfferEvaluationInternalProjectCommand, EditOfferEvaluationInternalProjectHeaderCommand,
        ActiveOfferEvaluationInternalProjectCommand, RequestApprovalOfferEvaluationInternalProjectCommand,
        CreateOfferEvaluationInternalProjectHeaderResponse, SaveOfferEvaluationInternalProjectResponse, EditOfferEvaluationInternalProjectHeaderResponse,
            ActiveOfferEvaluationInternalProjectResponse, RequestApprovalOfferEvaluationInternalProjectResponse, CreateAssessmentOfferEvaluationInternalProjectResponse,
            NotifyAssessmentOfferEvaluationInternalProjectResponse, RequestApprovalAssessmentOfferEvaluationInternalProjectResponse, ApproveOfferEvaluationInternalProjectResponse,
            ApproveAssessmentOfferEvaluationInternalProjectResponse, DeleteOfferEvaluationInternalProjectResponse } from './iOrderService';

// Import OfferEvaluation Section
import { EditFixedPriceProjectCommand, EditHumanResourcesCommand, EditOtherComponentsCommand, EditTotalAmountsCommand, 
            EditAdditionalCostsPresaleCommand, EditOtherCostsPresaleCommand, EditAdditionalCostsPostsaleCommand, EditTotalExpectedRevenueByFeeCommand,
            EditAttachmentsCommand, UploadFilesOfferEvaluationCommand, EditDetailsInternalProjectCommand,
        OfferEvaluationSectionResponse } from './iOrderService';

// Import HubSpot
import { ListHubSpotDealsResponse } from './iOrderService';

// Import Professionals
import { ListProfessionals } from './iOrderService';

// Import Customer
import { ListCustomersView } from './iOrderService';

// Import Supplier
import { ListSuppliersView } from './iOrderService';

// Import BusinessStartup: Customer Order
import { ListBusinessStartupCustomerOrderTable, BusinessStartupCustomerOrderDetailsView,
        CreateBusinessStartupCustomerOrderCommand, EditOrderRequirementsReviewCommand,
        CreateBusinessStartupCustomerOrderResponse, EditBusinessStartupCustomerOrderResponse } from './iOrderService';

// Import BusinessStartup: Internal Project
import { ListBusinessStartupInternalProjectTable, BusinessStartupInternalProjectDetailsView } from './iOrderService';

import AxiosService from './AxiosService';
import { injectable } from 'inversify';
import { Guid } from 'guid-typescript';

@injectable()
export default class OrderService extends AxiosService implements IOrderService
{
    protected readonly API_URL: string = process.env.VUE_APP_ORDER_API_URL || window.location.origin + "api/order";

    async GetTest(): Promise<any> {
        const a = await this.BasePatch<any, any>("ModuloValutazioneOfferta/PostOfferta", {
            "ClienteId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
            "Cliente": "BNL"
        });
        return a;
    }

    // TECH AREA API
    async CreateTechArea(techArea: CreateTechAreaCommand): Promise<CreateTechAreaResponse> {
        return await this.BasePost<CreateTechAreaCommand, CreateTechAreaResponse>("TechAreas", techArea)
    }
    async EditTechArea(id: Guid, cmd: EditTechAreaCommand) : Promise<EditTechAreaResponse>
    {
        return await this.BasePatch<EditTechAreaCommand, EditTechAreaResponse>(`techareas/${id.toString()}`, cmd);
    }
    async GetTechAreaById(id: Guid): Promise<TechAreaModel> {
        return await this.BaseGet<any>(`TechAreas/${id}`);
    }
    async GetAllTechAreas(): Promise<ListTechAreas> {
        return await this.BaseGet<any>("TechAreas");
    }
    async DeleteTechArea(id: Guid): Promise<DeleteTechAreaResponse> {
        return await this.BaseDelete(`TechAreas/${id}`);
    }
    async ApproveTechArea(cmd: ApproveTechAreaCommand): Promise<ApproveTechAreaResponse> {
        const url = `TechAreas/${cmd.Id.toString()}/approve`;
        const resp = await this.BaseGet<ApproveTechAreaResponse>(url);
        return resp;
    }
    async ApproveDeletionTechArea ( cmd: ApproveDeletionTechAreaCommand ): Promise<ApproveDeletionTechAreaResponse>
    {
        const url = `TechAreas/${cmd.Id.toString()}/approvedeletion`;
        const resp = await this.BaseGet<ApproveTechAreaResponse>(url);
        return resp;
    }
    async GetAllTechAreasDropdown(): Promise<ListTechAreas>
    {
        return await this.BaseGet<any>("techareas/dropdown");
    }
    async GetAllTechAreasDropdownRestricted(): Promise<ListTechAreas>
    {
        return await this.BaseGet<any>("techareas/dropdown/restricted");
    }

    // SUPPLY TIPOLOGY API
    async GetAllTipologiaFornitura(): Promise<ListTipologiaFornitura> {
        return await this.BaseGet<ListTipologiaFornitura>("/tipologiafornitura");
    }
    async CreateTipologiaFornitura(TipologiaFornitura: TipologiaFornituraCommand): Promise<TipologiaFornituraResponse> {
        return await this.BasePost<TipologiaFornituraCommand, TipologiaFornituraResponse>("tipologiafornitura", TipologiaFornitura)
    }
    async EditSupplyTipology(id: Guid, command: EditSupplyTipologyCommand): Promise<EditSupplyTipologyResponse>
    {
        return await this.BasePatch<EditSupplyTipologyCommand, EditSupplyTipologyResponse>(`tipologiafornitura/${id.toString()}`, command);
    }
    async GetTipologiaFornituraById(id: string): Promise<TipologiaFornituraModel> {
        return await this.BaseGet<TipologiaFornituraModel>(`tipologiafornitura/${id}`);
    }
    async DeleteTipologiaFornitura(id: string): Promise<TipologiaFornituraResponse> {
        return await this.BaseDelete(`tipologiafornitura/${id}`);
    }
    async ApproveSupplyTipology(cmd: ApproveSupplyTipologyCommand): Promise<ApproveSupplyTipologyResponse> {
        const url = `tipologiafornitura/${cmd.Id.toString()}/approve`;
        const resp = await this.BaseGet<ApproveSupplyTipologyResponse>(url);
        return resp;
    }
    async ApproveDeletionSupplyTipology(cmd: ApproveDeletionSupplyTipologyCommand): Promise<ApproveDeletionSupplyTipologyResponse> {
        const url = `tipologiafornitura/${cmd.Id.toString()}/approvedeletion`;
        const resp = await this.BaseGet<ApproveDeletionSupplyTipologyResponse>(url);
        return resp;
    }
    async GetAllSupplyTipologiesDropdown(): Promise<ListTipologiaFornitura>
    {
        return await this.BaseGet<ListTipologiaFornitura>("/tipologiafornitura/dropdown");
    }

    // BUSINESS LINE API
    async GetBusinessLines(): Promise<Array<BusinessLineTableView>> {
        return await this.BaseGet<Array<BusinessLineTableView>>("businesslines");
    }
    async GetBusinessLine(id: string): Promise<BusinessLineModel> {
        return await this.BaseGet<BusinessLineModel>(`businesslines/${id}`);
    }
    async CreateBusinessLine(cmd: CreateBusinessLineCommand): Promise<CreateBusinessLineResponse> {
        return await this.BasePost<CreateBusinessLineCommand, CreateBusinessLineResponse>("businesslines", cmd);
    }
    async EditBusinessLine(cmd: EditBusinessLineCommand): Promise<EditBusinessLineResponse>
    {
        const url = `businesslines/${cmd.Id.toString()}/edit`;
        return await this.BasePatch<EditBusinessLineCommand, EditBusinessLineResponse>(url, cmd);
    }
    async DeleteBusinessLine(cmd: DeleteBusinessLineCommand): Promise<DeleteBusinessLineResponse> {
        return await this.BaseDelete(`businesslines/${cmd.Id}`);
    }
    async ApproveBusinessLine(cmd: ApproveBusinessLineCommand): Promise<ApproveBusinessLineResponse> {
        const url = `businesslines/${cmd.Id.toString()}/approve`;
        const resp = await this.BaseGet<ApproveBusinessLineResponse>(url);
        return resp;
    }
    async EditAssociationBusinessLineACOM(cmd: EditAssociationBusinessLineACOMCommand): Promise<EditAssociationBusinessLineACOMResponse> {
        const url = `businesslines/${cmd.Id.toString()}`;
        const resp = await this.BasePatch<EditAssociationBusinessLineACOMCommand, EditAssociationBusinessLineACOMResponse>(url, cmd);
        return resp;
    }
    async ApproveDeletionBusinessLine(cmd: ApproveDeletionBusinessLineCommand): Promise<ApproveDeletionBusinessLineResponse> {
        const url = `businesslines/${cmd.Id.toString()}/approvedeletion`;
        const resp = await this.BaseGet<ApproveDeletionBusinessLineResponse>(url);
        return resp;
    }
    async GetBusinessLinesDropdown(): Promise<Array<BusinessLineTableView>>
    {
        return await this.BaseGet<Array<BusinessLineTableView>>("businesslines/dropdown");
    }

    // ECONOMIC LIMIT API
    async GetEconomicLimits(): Promise<Array<EconomicLimitModel>> {
        return await this.BaseGet<Array<EconomicLimitModel>>("economiclimits");
    }
    async GetEconomicLimit(id: string): Promise<EconomicLimitModel> {
        const el = await this.BaseGet<EconomicLimitModel>(`economiclimits/${id}`);

        const elState: EconomicLimitModel =
        {
            Id: el.Id,
            Year: el.Year,
            OfferLimit: el.OfferLimit,
            PurchaseInnerLimit: el.PurchaseInnerLimit,
            PurchaseAnnualLimit: el.PurchaseAnnualLimit,
            PurchaseOuterLimit: el.PurchaseOuterLimit,
            MOLMin: el.MOLMin,
            MaxBudgetInnerProjects: el.MaxBudgetInnerProjects,
            ApproveDG: el.ApproveDG,
            ApproveDHRG: el.ApproveDHRG
        };

        return elState;
    }
    async CreateEconomicLimit(CreateEconomicLimitCommand: CreateEconomicLimitCommand): Promise<CreateEconomicLimitResponse> {
        return await this.BasePost<CreateEconomicLimitCommand, CreateEconomicLimitResponse>("economiclimits", CreateEconomicLimitCommand);
    }
    async EditEconomicLimit(id: string, economicLimit: EditEconomicLimitCommand): Promise<EditEconomicLimitResponse> {
        return await this.BasePatch<EditEconomicLimitCommand, EditEconomicLimitResponse>(`economiclimits/${id}`, economicLimit);
    }
    async GetLastEconomicLimit(): Promise<LastEconomicLimit> {
        const lastEL = await this.BaseGet<LastEconomicLimit>("economiclimits/lastEL");

        const lastELSate: LastEconomicLimit =
        {
            Id: lastEL.Id,
            Year: new Date(lastEL.Year)
        };

        return lastELSate;
    }
    async CheckCreateEconomicLimit() : Promise<boolean> 
    {
        return (await this.BaseGet<CheckCreateEconomicLimitResponse>("economiclimits/checkcreate")).CanCreate;
    }
    async CheckEditEconomicLimit(id: Guid) : Promise<boolean>
    {
        return (await this.BaseGet<CheckEditEconomicLimitResponse>(`economiclimits/checkedit/${id.toString()}`)).CanEdit;
    }
    async ApproveEconomicLimit(id: Guid) : Promise<Guid>
    {
        return (await this.BaseGet<ApproveEconomicLimitResponse>(`economiclimits/${id.toString()}/approve`)).Id;
    }

    // SOLUTION PRODUCT API
    async GetAllSolutionProducts(): Promise<ListSolutionProduct> {
        return await this.BaseGet<ListSolutionProduct>("solutionproducts");
    }
    async GetSolutionProductById(id: Guid): Promise<SolutionProductModel> {
        return await this.BaseGet<SolutionProductModel>(`solutionproducts/${id}`);
    }
    async CreateSolutionProduct(cmd: CreateSolutionProductCommand): Promise<CreateSolutionProductResponse> {
        return await this.BasePost<CreateSolutionProductCommand, CreateSolutionProductResponse>("solutionproducts", cmd);
    }
    async EditSolutionProduct(id: Guid, cmd: EditSolutionProductCommand): Promise<EditSolutionProductResponse>
    {
        return await this.BasePatch<EditSolutionProductCommand, EditSolutionProductResponse>(`solutionproducts/${id.toString()}`, cmd);
    }
    async DeleteSolutionProduct(id: Guid):  Promise<DeleteSolutionProductResponse> {
        return await this.BaseDelete(`solutionproducts/${id}`);
    }
    async ApproveSolutionProduct(cmd: ApproveSolutionProductCommand): Promise<ApproveSolutionProductResponse>
    {
        const url = `solutionproducts/${cmd.Id.toString()}/approve`;
        const resp = await this.BaseGet<ApproveSolutionProductResponse>(url);
        return resp;
    }
    async ApproveDeletionSolutionProduct(cmd: ApproveDeletionSolutionProductCommand): Promise<ApproveDeletionSolutionProductResponse>
    {
        const url = `solutionproducts/${cmd.Id.toString()}/approvedeletion`;
        const resp = await this.BaseGet<ApproveDeletionSolutionProductResponse>(url);
        return resp;
    }
    async GetAllSolutionProductsDropdown(): Promise<ListSolutionProduct>
    {
        return await this.BaseGet<ListSolutionProduct>("solutionproducts/dropdown");
    }

    // AREA MANAGER API
    async GetAllAreaManagers(): Promise<ListAreaManagers> {
        return await this.BaseGet<ListAreaManagers>("areamanagers");
    }
    async GetAreaManagerById(id: Guid): Promise<AreaManagerModel> {
        return await this.BaseGet<AreaManagerModel>(`areamanagers/${id.toString()}`);
    }
    async CreateAreaManager(cmd: CreateAreaManagerCommand): Promise<CreateAreaManagerResponse> {
        return await this.BasePost<CreateAreaManagerCommand, CreateAreaManagerResponse>("areamanagers", cmd);
    }
    async EditAreaManager(id: Guid, cmd: EditAreaManagerCommand): Promise<EditAreaManagerResponse>
    {
        return await this.BasePatch<EditAreaManagerCommand, EditAreaManagerResponse>(`areamanagers/${cmd.Id.toString()}`, cmd);
    }
    async DeleteAreaManager(id: Guid): Promise<DeleteAreaManagerResponse> {
        return await this.BaseDelete(`areamanagers/${id.toString()}`);
    }
    async ApproveAreaManager ( cmd: ApproveAreaManagerCommand ): Promise<ApproveAreaManagerResponse>
    {
        const url = `areamanagers/${cmd.Id.toString()}/approve`;
        const resp = await this.BaseGet<ApproveAreaManagerResponse>(url);
        return resp;
    }
    async ApproveDeletionAreaManager ( cmd: ApproveDeletionAreaManagerCommand ): Promise<ApproveDeletionAreaManagerResponse>
    {
        const url = `areamanagers/${cmd.Id.toString()}/approvedeletion`;
        const resp = await this.BaseGet<ApproveAreaManagerResponse>(url);
        return resp;
    }
    async GetAllAreaManagersDropdown(): Promise<ListAreaManagers>
    {
        return await this.BaseGet<ListAreaManagers>("areamanagers/dropdown");
    }

    // OfferEvaluation CustomerOrder API
    async GetAllOfferEvaluationCustomerOrders() : Promise<ListOfferEvaluationCustomerOrder>
    {
        return await this.BaseGet<ListOfferEvaluationCustomerOrder>("offerevaluation/customerorder");
    }
    async GetAllOfferEvaluationCustomerOrdersByACOMId(acomId: Guid) : Promise<ListOfferEvaluationCustomerOrder>
    {
        return await this.BaseGet<ListOfferEvaluationCustomerOrder>(`offerevaluation/customerorder/acom/${acomId}`);
    }
    async GetAllOfferEvaluationCustomerOrdersClosed() : Promise<ListOfferEvaluationCustomerOrder>
    {
        return await this.BaseGet<ListOfferEvaluationCustomerOrder>("offerevaluation/customerorder/closed");
    }
    async GetOfferEvaluationCustomerOrderById(id: Guid) : Promise<OfferEvaluationCustomerOrderDetailsView>
    {
        return await this.BaseGet<OfferEvaluationCustomerOrderDetailsView>(`offerevaluation/customerorder/${id.toString()}`);
    }
    async GetOfferEvaluationCustomerOrderByIdToEdit(id: Guid) : Promise<OfferEvaluationCustomerOrderDetailsToEdit>
    {
        return await this.BaseGet<OfferEvaluationCustomerOrderDetailsToEdit>(`offerevaluation/customerorder/${id.toString()}/toedit`);
    }
    async CreateOfferEvaluationCustomerOrderHeader(cmd: CreateOfferEvaluationCustomerOrderHeaderCommand) : Promise<CreateOfferEvaluationCustomerOrderHeaderResponse>
    {
        return await this.BasePost<CreateOfferEvaluationCustomerOrderHeaderCommand, CreateOfferEvaluationCustomerOrderHeaderResponse>("offerevaluation/customerorder", cmd);
    }
    async EditOfferEvaluationCustomerOrderHeader(cmd: EditOfferEvaluationCustomerOrderHeaderCommand) : Promise<EditOfferEvaluationCustomerOrderHeaderResponse>
    {
        return await this.BasePatch<EditOfferEvaluationCustomerOrderHeaderCommand, EditOfferEvaluationCustomerOrderHeaderResponse>(`offerevaluation/customerorder/${cmd.Id}`, cmd);
    }
    async SaveOfferEvaluationCustomerOrder(cmd: SaveOfferEvaluationCustomerOrderCommand) : Promise<SaveOfferEvaluationCustomerOrderResponse>
    {
        return await this.BasePost<SaveOfferEvaluationCustomerOrderCommand, SaveOfferEvaluationCustomerOrderResponse>(`offerevaluation/customerorder/${cmd.Id.toString()}`, cmd);
    }
    async DeleteOfferEvaluationCustomerOrder(id: Guid) : Promise<DeleteOfferEvaluationCustomerOrderResponse>
    {
        return await this.BaseDelete<DeleteOfferEvaluationCustomerOrderResponse>(`offerevaluation/customerorder/${id.toString()}`);
    }
    async ActiveOfferEvaluationCustomerOrder(cmd: ActiveOfferEvaluationCustomerOrderCommand) : Promise<ActiveOfferEvaluationCustomerOrderResponse>
    {
        return await this.BasePatch<ActiveOfferEvaluationCustomerOrderCommand, ActiveOfferEvaluationCustomerOrderResponse>(`offerevaluation/customerorder/${cmd.Id.toString()}/active`, cmd);
    }
    async RequestApprovalOfferEvaluationCustomerOrder(cmd: RequestApprovalOfferEvaluationCustomerOrderCommand) : Promise<RequestApprovalOfferEvaluationCustomerOrderResponse>
    {
        return await this.BaseGet<RequestApprovalOfferEvaluationCustomerOrderResponse>(`offerevaluation/customerorder/${cmd.Id.toString()}/requestapproval`);
    }
    async ApproveOfferEvaluationCustomerOrder(id: Guid) : Promise<ApproveOfferEvaluationCustomerOrderResponse>
    {
        return await this.BaseGet(`offerevaluation/customerorder/${id.toString()}/approve`);
    }
    async CreateAssessmentOfferEvaluationCustomerOrder(id: Guid) : Promise<CreateAssessmentOfferEvaluationCustomerOrderResponse>
    {
        return await this.BasePatch(`offerevaluation/customerorder/${id.toString()}/assessment`, null);
    }
    async NotifyAssessmentOfferEvaluationCustomerOrder(id: Guid) : Promise<NotifyAssessmentOfferEvaluationCustomerOrderResponse>
    {
        return await this.BasePatch(`offerevaluation/customerorder/${id.toString()}/notifyassessment`, null);
    }
    async RequestApprovalAssessmentOfferEvaluationCustomerOrder(id: Guid) : Promise<RequestApprovalAssessmentOfferEvaluationCustomerOrderResponse>
    {
        return await this.BaseGet(`offerevaluation/customerorder/${id.toString()}/requestapprovalassessment`);
    }
    async ApproveAssessmentOfferEvaluationCustomerOrder(id: Guid) : Promise<ApproveAssessmentOfferEvaluationCustomerOrderResponse>
    {
        return await this.BaseGet(`offerevaluation/customerorder/${id.toString()}/approveassessment`);
    }
    async PreCheckReassignmentACOMCustomerOrder(cmd: PreCheckReassignmentACOMCustomerOrderCommand) : Promise<PreCheckReassignmentACOMCustomerOrderResponse>
    {
        return await this.BasePatch<PreCheckReassignmentACOMCustomerOrderCommand, PreCheckReassignmentACOMCustomerOrderResponse>("offerevaluation/customerorder/precheckreassignment", cmd);
    }
    async ReassignmentACOMCustomerOrder(cmd: ReassignmentACOMCustomerOrderCommand) : Promise<ReassignmentACOMCustomerOrderResponse>
    {
        return await this.BasePatch<ReassignmentACOMCustomerOrderCommand, ReassignmentACOMCustomerOrderResponse>("offerevaluation/customerorder/reassignment", cmd);
    }
    async CheckIfExistsCustomerOrderByCRMDealId(crmDealId: string) : Promise<CheckIfExistsCustomerOrderByCRMDealIdResponse>
    {
        return await this.BaseGet<CheckIfExistsCustomerOrderByCRMDealIdResponse>(`offerevaluation/customerorder/crmdealid=${crmDealId}`);
    }

    // OfferEvaluation InternalProject API
    async GetAllOfferEvaluationInternalProjects() : Promise<ListOfferEvaluationInternalProject>
    {
        return await this.BaseGet<ListOfferEvaluationInternalProject>("offerevaluation/internalproject");
    }
    async GetAllOfferEvaluationInternalProjectsClosed() : Promise<ListOfferEvaluationInternalProject>
    {
        return await this.BaseGet<ListOfferEvaluationInternalProject>("offerevaluation/internalproject/closed");
    }
    async GetOfferEvaluationInternalProjectById(id: Guid) : Promise<OfferEvaluationInternalProjectDetailsView>
    {
        return await this.BaseGet<OfferEvaluationInternalProjectDetailsView>(`offerevaluation/internalproject/${id.toString()}`);
    }
    async GetOfferEvaluationInternalProjectByIdToEdit(id: Guid) : Promise<OfferEvaluationInternalProjectDetailsToEdit>
    {
        return await this.BaseGet<OfferEvaluationInternalProjectDetailsToEdit>(`offerevaluation/internalproject/${id.toString()}/toedit`);
    }
    async CreateOfferEvaluationInternalProjectHeader(cmd: CreateOfferEvaluationInternalProjectHeaderCommand) : Promise<CreateOfferEvaluationInternalProjectHeaderResponse>
    {
        return await this.BasePost<CreateOfferEvaluationInternalProjectHeaderCommand, CreateOfferEvaluationInternalProjectHeaderResponse>("offerevaluation/internalproject", cmd);
    }
    async EditOfferEvaluationInternalProjectHeader(cmd: EditOfferEvaluationInternalProjectHeaderCommand) : Promise<EditOfferEvaluationInternalProjectHeaderResponse>
    {
        return await this.BasePatch<EditOfferEvaluationInternalProjectHeaderCommand, EditOfferEvaluationInternalProjectHeaderResponse>(`offerevaluation/internalproject/${cmd.Id}`, cmd);
    }
    async SaveOfferEvaluationInternalProject(cmd: SaveOfferEvaluationInternalProjectCommand) : Promise<SaveOfferEvaluationInternalProjectResponse>
    {
        return await this.BasePost<SaveOfferEvaluationInternalProjectCommand, SaveOfferEvaluationInternalProjectResponse>(`offerevaluation/internalproject/${cmd.Id.toString()}`, cmd);
    }
    async DeleteOfferEvaluationInternalProject(id: Guid) : Promise<DeleteOfferEvaluationInternalProjectResponse>
    {
        return await this.BaseDelete<DeleteOfferEvaluationInternalProjectResponse>(`offerevaluation/internalproject/${id.toString()}`);
    }
    async ActiveOfferEvaluationInternalProject(cmd: ActiveOfferEvaluationInternalProjectCommand) : Promise<ActiveOfferEvaluationInternalProjectResponse>
    {
        return await this.BasePatch<ActiveOfferEvaluationInternalProjectCommand, ActiveOfferEvaluationInternalProjectResponse>(`offerevaluation/internalproject/${cmd.Id.toString()}/active`, cmd);
    }
    async RequestApprovalOfferEvaluationInternalProject(cmd: RequestApprovalOfferEvaluationInternalProjectCommand) : Promise<RequestApprovalOfferEvaluationInternalProjectResponse>
    {
        return await this.BaseGet(`offerevaluation/internalproject/${cmd.Id.toString()}/requestapproval`);
    }
    async ApproveOfferEvaluationInternalProject(id: Guid) : Promise<ApproveOfferEvaluationInternalProjectResponse>
    {
        return await this.BaseGet(`offerevaluation/internalproject/${id.toString()}/approve`);
    }
    async CreateAssessmentOfferEvaluationInternalProject(id: Guid) : Promise<CreateAssessmentOfferEvaluationInternalProjectResponse>
    {
        return await this.BasePatch(`offerevaluation/internalproject/${id.toString()}/assessment`, null);
    }
    async NotifyAssessmentOfferEvaluationInternalProject(id: Guid) : Promise<NotifyAssessmentOfferEvaluationInternalProjectResponse>
    {
        return await this.BasePatch(`offerevaluation/internalproject/${id.toString()}/notifyassessment`, null);
    }
    async RequestApprovalAssessmentOfferEvaluationInternalProject(id: Guid) : Promise<RequestApprovalAssessmentOfferEvaluationInternalProjectResponse>
    {
        return await this.BaseGet(`offerevaluation/internalproject/${id.toString()}/requestapprovalassessment`);
    }
    async ApproveAssessmentOfferEvaluationInternalProject(id: Guid) : Promise<ApproveAssessmentOfferEvaluationInternalProjectResponse>
    {
        return await this.BaseGet(`offerevaluation/internalproject/${id.toString()}/approveassessment`);
    }

    // OfferEvaluation Section API
    async EditFixedPriceProject(resource: string, cmd: EditFixedPriceProjectCommand) : Promise<OfferEvaluationSectionResponse>
    {
        return await this.BasePatch<EditFixedPriceProjectCommand, OfferEvaluationSectionResponse>(`offerevaluation/${resource}/${cmd.Id.toString()}/fixedpriceproject`, cmd);
    }

    async EditHumanResources(resource: string, cmd: EditHumanResourcesCommand) : Promise<OfferEvaluationSectionResponse>
    {
        return await this.BasePatch<EditHumanResourcesCommand, OfferEvaluationSectionResponse>(`offerevaluation/${resource}/${cmd.Id.toString()}/humanresources`, cmd);
    }

    async EditOtherComponents(resource: string, cmd: EditOtherComponentsCommand) : Promise<OfferEvaluationSectionResponse>
    {
        return await this.BasePatch<EditOtherComponentsCommand, OfferEvaluationSectionResponse>(`offerevaluation/${resource}/${cmd.Id.toString()}/othercomponents`, cmd);
    }

    async EditTotalAmounts(resource: string, cmd: EditTotalAmountsCommand) : Promise<OfferEvaluationSectionResponse>
    {
        return await this.BasePatch<EditTotalAmountsCommand, OfferEvaluationSectionResponse>(`offerevaluation/${resource}/${cmd.Id.toString()}/totalamounts`, cmd);
    }
    async EditAdditionalCostsPresale(resource: string, cmd: EditAdditionalCostsPresaleCommand) : Promise<OfferEvaluationSectionResponse>
    {
        return await this.BasePatch<EditAdditionalCostsPresaleCommand, OfferEvaluationSectionResponse>(`offerevaluation/${resource}/${cmd.Id.toString()}/additionalcostspresale`, cmd);
    }
    async EditOtherCostsPresale(resource: string, cmd: EditOtherCostsPresaleCommand) : Promise<OfferEvaluationSectionResponse>
    {
        return await this.BasePatch<EditOtherCostsPresaleCommand, OfferEvaluationSectionResponse>(`offerevaluation/${resource}/${cmd.Id.toString()}/othercostspresale`, cmd);
    }
    async EditAdditionalCostsPostsale(resource: string, cmd: EditAdditionalCostsPostsaleCommand) : Promise<OfferEvaluationSectionResponse>
    {
        return await this.BasePatch<EditAdditionalCostsPostsaleCommand, OfferEvaluationSectionResponse>(`offerevaluation/${resource}/${cmd.Id.toString()}/additionalcostspostsale`, cmd);
    }
    async EditTotalExpectedRevenueByFee(resource: string, cmd: EditTotalExpectedRevenueByFeeCommand) : Promise<OfferEvaluationSectionResponse>
    {
        return await this.BasePatch<EditTotalExpectedRevenueByFeeCommand, OfferEvaluationSectionResponse>(`offerevaluation/${resource}/${cmd.Id.toString()}/feetotalrevenue`, cmd);
    }
    async EditAttachments(resource: string, cmd: EditAttachmentsCommand) : Promise<OfferEvaluationSectionResponse>
    {
        return await this.BasePatch<EditAttachmentsCommand, OfferEvaluationSectionResponse>(`offerevaluation/${resource}/${cmd.Id.toString()}/attachments`, cmd);
    }
    async UploadFilesOfferEvaluation(resource: string, cmd: UploadFilesOfferEvaluationCommand) : Promise<OfferEvaluationSectionResponse>
    {
        const formData = new FormData();
        cmd.Files.forEach(f => formData.append('files', f, f.name));
        return await this.BasePost<FormData, OfferEvaluationSectionResponse>(`offerevaluation/${resource}/${cmd.Id.toString()}/uploadfiles`, formData);
    }

    async EditDetailsInternalProject(cmd: EditDetailsInternalProjectCommand) : Promise<OfferEvaluationSectionResponse>
    {
        return await this.BasePatch<EditDetailsInternalProjectCommand, OfferEvaluationSectionResponse>(`offerevaluation/internalproject/${cmd.Id.toString()}/detailsinternalproject`, cmd);
    }

    // OfferEvaluation Section API Draft
    async EditFixedPriceProjectDraft(resource: string, cmd: EditFixedPriceProjectCommand) : Promise<OfferEvaluationSectionResponse>
    {
        return await this.BasePatch<EditFixedPriceProjectCommand, OfferEvaluationSectionResponse>(`offerevaluation/${resource}/${cmd.Id.toString()}/fixedpriceproject/draft`, cmd);
    }
    async EditHumanResourcesDraft(resource: string, cmd: EditHumanResourcesCommand) : Promise<OfferEvaluationSectionResponse>
    {
        return await this.BasePatch<EditHumanResourcesCommand, OfferEvaluationSectionResponse>(`offerevaluation/${resource}/${cmd.Id.toString()}/humanresources/draft`, cmd);
    }
    async EditOtherComponentsDraft(resource: string, cmd: EditOtherComponentsCommand) : Promise<OfferEvaluationSectionResponse>
    {
        return await this.BasePatch<EditOtherComponentsCommand, OfferEvaluationSectionResponse>(`offerevaluation/${resource}/${cmd.Id.toString()}/othercomponents/draft`, cmd);
    }
    async EditTotalAmountsDraft(resource: string, cmd: EditTotalAmountsCommand) : Promise<OfferEvaluationSectionResponse>
    {
        return await this.BasePatch<EditTotalAmountsCommand, OfferEvaluationSectionResponse>(`offerevaluation/${resource}/${cmd.Id.toString()}/totalamounts/draft`, cmd);
    }
    async EditAdditionalCostsPresaleDraft(resource: string, cmd: EditAdditionalCostsPresaleCommand) : Promise<OfferEvaluationSectionResponse>
    {
        return await this.BasePatch<EditAdditionalCostsPresaleCommand, OfferEvaluationSectionResponse>(`offerevaluation/${resource}/${cmd.Id.toString()}/additionalcostspresale/draft`, cmd);
    }
    async EditOtherCostsPresaleDraft(resource: string, cmd: EditOtherCostsPresaleCommand) : Promise<OfferEvaluationSectionResponse>
    {
        return await this.BasePatch<EditOtherCostsPresaleCommand, OfferEvaluationSectionResponse>(`offerevaluation/${resource}/${cmd.Id.toString()}/othercostspresale/draft`, cmd);
    }
    async EditAdditionalCostsPostsaleDraft(resource: string, cmd: EditAdditionalCostsPostsaleCommand) : Promise<OfferEvaluationSectionResponse>
    {
        return await this.BasePatch<EditAdditionalCostsPostsaleCommand, OfferEvaluationSectionResponse>(`offerevaluation/${resource}/${cmd.Id.toString()}/additionalcostspostsale/draft`, cmd);
    }
    async EditTotalExpectedRevenueByFeeDraft(resource: string, cmd: EditTotalExpectedRevenueByFeeCommand) : Promise<OfferEvaluationSectionResponse>
    {
        return await this.BasePatch<EditTotalExpectedRevenueByFeeCommand, OfferEvaluationSectionResponse>(`offerevaluation/${resource}/${cmd.Id.toString()}/feetotalrevenue/draft`, cmd);
    }

    async EditDetailsInternalProjectDraft(cmd: EditDetailsInternalProjectCommand) : Promise<OfferEvaluationSectionResponse>
    {
        return await this.BasePatch<EditDetailsInternalProjectCommand, OfferEvaluationSectionResponse>(`offerevaluation/internalproject/${cmd.Id.toString()}/detailsinternalproject/draft`, cmd);
    }

    //HubSpot
    async GetAllHubSpotDealsPage(after?: string) : Promise<ListHubSpotDealsResponse>
    {
        const url = (after)? `hubspot/deal?after=${after}` : "hubspot/deal";
        return await this.BaseGet<ListHubSpotDealsResponse>(url);
    }

    // Professionals
    async GetAllProfessionals(): Promise<ListProfessionals>
    {
        return await this.BaseGet<ListProfessionals>("professionals");
    }

    // Customer API
    async GetCustomersByBusinessName(businessName: string) : Promise<ListCustomersView>
    {
        return await this.BaseGet<ListCustomersView>(`customers/businessName=${businessName}`);
    }

    // Supplier API
    async GetSuppliersByBusinessName(businessName: string) : Promise<ListSuppliersView>
    {
        return await this.BaseGet<ListSuppliersView>(`suppliers/businessName=${businessName}`);
    }

    // BusinessStartup CustomerOrder API
    async GetAllBusinessStartupCustomerOrders() : Promise<ListBusinessStartupCustomerOrderTable>
    {
        return await this.BaseGet<ListBusinessStartupCustomerOrderTable>("businessstartupcustomerorders?statuses=1&statuses=2");
    }
    async GetAllBusinessStartupCustomerOrdersClosed() : Promise<ListBusinessStartupCustomerOrderTable>
    {
        return await this.BaseGet<ListBusinessStartupCustomerOrderTable>("businessstartupcustomerorders?statuses=4");
    }
    async GetBusinessStartupCustomerOrderById(id: Guid) : Promise<BusinessStartupCustomerOrderDetailsView>
    {
        return await this.BaseGet<BusinessStartupCustomerOrderDetailsView>(`businessstartupcustomerorders/${id.toString()}`);
    }
    async CreateBusinessStartupCustomerOrder(cmd: CreateBusinessStartupCustomerOrderCommand) : Promise<CreateBusinessStartupCustomerOrderResponse>
    {
        return await this.BasePost<CreateBusinessStartupCustomerOrderCommand, CreateBusinessStartupCustomerOrderResponse>("businessstartupcustomerorders", cmd);
    }
    async EditOrderRequirementsReview(cmd: EditOrderRequirementsReviewCommand) : Promise<EditBusinessStartupCustomerOrderResponse>
    {
        return await this.BasePatch<EditOrderRequirementsReviewCommand, EditBusinessStartupCustomerOrderResponse>(`businessstartupcustomerorders/${cmd.Id}/orderrequirementsreview`, cmd);
    }

    // BusinessStartup InternalProject API
    async GetAllBusinessStartupInternalProjects() : Promise<ListBusinessStartupInternalProjectTable>
    {
        return await this.BaseGet<ListBusinessStartupInternalProjectTable>("businessstartupinternalprojects?statuses=1&statuses=2");
    }
    async GetAllBusinessStartupInternalProjectsClosed() : Promise<ListBusinessStartupInternalProjectTable>
    {
        return await this.BaseGet<ListBusinessStartupInternalProjectTable>("businessstartupinternalprojects?statuses=4");
    }
    async GetBusinessStartupInternalProjectById(id: Guid) : Promise<BusinessStartupInternalProjectDetailsView>
    {
        return await this.BaseGet<BusinessStartupInternalProjectDetailsView>(`businessstartupinternalprojects/${id.toString()}`);
    }
}