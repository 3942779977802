






import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { SharedModule } from "@/feature/Shared/store";

@Component({})
export default class PgaAuthorizedContent extends Vue {
    @Prop({ type: String, default: "" }) readonly permission!: string;
    @Prop({ type: Array, default: function() { return new Array<string>(); } }) readonly permissions!: Array<string>;

    public get isSingleAuthorized() : boolean {
      return SharedModule.Permissions.includes(this.permission);
    }

    public get isAuthorized() : boolean {
      return this.permissions.some(p => SharedModule.Permissions.includes(p));
    }
}
