



















import { Vue, Component } from "vue-property-decorator";

import PgaNavigationDrawer from "./feature/Shared/Layout/PgaNavigationDrawer.vue";
import PgaAppBar from "./feature/Shared/Layout/PgaAppBar.vue";
import PgaFullscreenLoadingSpinner from "@/components/PgaFullscreenLoadingSpinner.vue";

import PgaAlertError from "@/components/PgaAlertError.vue";
import PgaAlertWarning from "@/components/PgaAlertWarning.vue";
import PgaSendEmailToPermissions from './feature/Shared/Email/PgaSendEmailToPermissions.vue';
import PgaSendEmailToUsers from './feature/Shared/Email/PgaSendEmailToUsers.vue';
import PgaTransition from "@/components/PgaTransition.vue";

@Component({  
  name: "App",
  components: {
    PgaNavigationDrawer,
    PgaAppBar,
    PgaFullscreenLoadingSpinner,
    PgaAlertError,
    PgaTransition,
    PgaSendEmailToPermissions,
    PgaSendEmailToUsers,
    PgaAlertWarning,
  }
})
export default class App extends Vue {

}
