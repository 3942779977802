import { VuexModule, Module, Mutation, Action, getModule } from 'vuex-module-decorators'
import store from '@/infrastructure/store'
import pgaDiContainer from '@/App.container';
import IMsalService from '@/services/iMsalService';
import MsalService from '@/services/MsalService';
import GraphService from '@/services/GraphService';
import IGraphService from '@/services/iGraphService';
import IdentityService from '@/services/IdentityService';
import IIdentityService, { ChildrenMenu } from '@/services/iIdentityService';
import AuthService from '@/services/AuthService';
import IAuthService, { AuthResponse as PGAAuthResponse } from '@/services/iAuthService';
import Axios from 'axios';
import { AccountInfo, AuthenticationResult, InteractionRequiredAuthError } from '@azure/msal-browser';
import { BreakpointName } from 'vuetify/types/services/breakpoint';
import { Guid } from 'guid-typescript';

export interface SharedState {
    Account: AccountInfo | null;
    IdToken: string | null;
    Token: string | null;
    Name: string;
    AvatarImageUrl: string;
    IsLogged: boolean;
    PGAAccessToken: string;
    Menu: ChildrenMenu[];
    FlattenMenu: ChildrenMenu[];
    Permissions: string[];
    IsLoading: boolean;
    AlertErrorMessage: string;
    AlertIsVisible: boolean;
    AlertWarningMessage: string;
    accountHomeId: string;
    authResult: AuthenticationResult | null;
    UserId: Guid;
}

@Module({ namespaced: true, dynamic: true, store, name: 'SharedStoreModule' })
class SharedStore extends VuexModule implements SharedState {
    public IsLogged = false;    
    public Token: string | null = '';
    public RawIdToken: string | null = '';
    public PGAAccessToken = '';
    public Name = '';
    public AvatarImageUrl = '';
    public Menu: ChildrenMenu[] = [];
    public FlattenMenu: ChildrenMenu[] = [];
    public Account: AccountInfo | null = null;
    public IdToken: string | null = null;
    public Permissions: string[] = [];
    private msalService: IMsalService = pgaDiContainer.get<IMsalService>(MsalService);
    private graphService: IGraphService = pgaDiContainer.get<IGraphService>(GraphService);
    private authService: IAuthService = pgaDiContainer.get<IAuthService>(AuthService);
    private identityService: IIdentityService = pgaDiContainer.get<IIdentityService>(IdentityService);
    public IsLoading = false;
    public AlertErrorMessage = "";
    public AlertIsVisible = false;
    public AlertWarningMessage = "";
    public ShowNotifications = false;
    public accountHomeId!: string;
    public authResult: AuthenticationResult | null = null;
    // When It's true some components will be showed in accessability mode
    public Accessibility = false;
    public UserId = Guid.createEmpty();

    @Action({ rawError: true })
    public async silentCheckToken(): Promise<void> {
        await this.handleRedirectPromise();
        const account = await this.msalService.getAllAccounts();
        //const account = this.msalService.getAccountByHomeId(this.authResult!.account!.homeAccountId);
        if (account && account.length > 0) {
            await this.msalService.acquireTokenSilent(account[0]).then(async tokenResponse => {
                this.SET_ISLOADING(true);
                this.SET_ID_TOKEN(tokenResponse);
                await this.pgaLogin(tokenResponse?.account);
                return Promise.resolve();
            }).catch(async (error) => {
                if (error instanceof InteractionRequiredAuthError) {
                    // fallback to interaction when silent call fails
                    return this.msalService.acquireTokenRedirect();
                } else
                    return Promise.reject();
            }).finally(() => {
                this.SET_ISLOADING(false);
            });
        }
        else {            
            return await this.msalService.loginRedirect();
        }
    }

    @Action({ rawError: true })
    public async login() {
        try {
            this.SET_ISLOADING(true);
            try {
                await this.msalService.loginRedirect();
            } catch (err) {
                console.error(err);
            }

        } catch (reason) {
            alert(`error performing log in: ${reason}`);
        } finally {
            this.SET_ISLOADING(false);
        }
    }

    @Action({ rawError: true })
    public async handleRedirectPromise() {
        return await this.msalService.handleRedirectPromise().then(async (resp: AuthenticationResult | null) => {
            this.SET_ISLOADING(true);
            this.SET_ID_TOKEN(resp);
            await this.pgaLogin(resp?.account ? resp.account : null);            
        })
        .catch((error) => {
            console.log(error);
        }).finally(() => {
            this.SET_ISLOADING(false);
        })
    }

    @Action({ rawError: true })
    private async pgaLogin(account: AccountInfo | null) {
        try {
            if (!this.RawIdToken) {
                return Promise.reject("AAD token not set");
            }
            const v = await this.authService.Login(this.RawIdToken)
            this.SET_PGA_TOKENS(v);
            this.SET_ACCOUNT(account);
            this.SET_LOGIN_STATE(true);
            await Promise.all([
                this.GetMyUserId(),
                this.GetMenu(),
                this.GetPermissions(),
                this.graphService.GetAvatar().then(a => this.SET_AVATAR_IMAGE_URL(a)),
            ]);
            this.SET_ACCESSIBILITY_MODE();
            this.SET_SHOW_NOTIFICATIONS();
        } catch (reason) {
            alert(`error performing log in: ${reason}`);
        }
    }

    @Action({ rawError: true })
    public async FetchGeneralConfiguration() {
        Axios.all([
            this.identityService.MyPermissions(),
        ])
    }

    @Action({ rawError: true })
    public async RefreshToken() {
        const response = await this.authService.RefreshToken();
        this.SET_PGA_TOKENS(response);
    }

    @Action({ rawError: true })
    public async GetMyUserId() {
        const response = await this.identityService.MyUserId();
        this.SET_PGA_MYUSERID(response);
    }

    @Action({ rawError: true })
    public async GetPermissions() {
        const response = await this.identityService.MyPermissions();
        this.SET_PGA_PERMISSIONS(response);
    }

    @Action({ rawError: true })
    public async GetMenu() {
        const response = await this.identityService.GetMenu();
        this.SET_MENU(response.Menu);
        this.SET_FLATTEN_MENU(response.FlattenMenu);
    }

    @Action({ rawError: true })
    public logout() {
        this.msalService.logout();
        this.SET_ACCOUNT(null);
        this.SET_ID_TOKEN(null)
        this.SET_LOGIN_STATE(false);
        this.SET_PGA_MYUSERID(Guid.createEmpty());
    }

    @Action({ rawError: true })
    public openTeams(email: string, message = "") : void
    {
        window.open(`https://teams.microsoft.com/l/chat/0/0?users=${email}&message=${message}`, "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=400,height=400");
    }

    @Mutation
    public SET_FLATTEN_MENU(Menu: ChildrenMenu[]) {
        this.FlattenMenu = Menu;
    }

    @Mutation
    public SET_MENU(Menu: ChildrenMenu[]) {
        this.Menu = Menu;
    }

    @Mutation
    public SET_PGA_PERMISSIONS(response: string[]) {
        this.Permissions = response;
    }

    @Mutation
    public SET_PGA_MYUSERID(response: Guid) {
        this.UserId = response;
    }

    @Mutation
    public SET_ACCOUNT(account: AccountInfo | null): void {
        this.Account = account;
        this.Name = account?.name || '';
    }

    @Mutation
    public SET_LOGIN_STATE(logged: boolean): void {
        this.IsLogged = logged;
    }

    @Mutation
    public SET_ISLOADING(IsLoading: boolean): void {
        this.IsLoading = IsLoading;
    }

    @Mutation
    public SET_ALERT_ERROR_MESSAGE(AlertErrorMessage: string): void {
        this.AlertErrorMessage = AlertErrorMessage;
    }

    @Mutation
    public SET_ALERT_IS_VISIBLE(AlertIsVisible: boolean): void
    {
        this.AlertIsVisible = AlertIsVisible;
    }

    @Mutation
    public SET_ALERT_WARNING_MESSAGE(warningMessage: string): void
    {
        this.AlertWarningMessage = warningMessage;
    }

    @Mutation
    public SET_AVATAR_IMAGE_URL(imageUrl: string | null): void {
        this.AvatarImageUrl = imageUrl!;
    }

    @Mutation
    public SET_ID_TOKEN(token: AuthenticationResult | null): void {
        this.authResult = token!;
        this.IdToken = token?.idToken ?? null;
        this.Token = token?.accessToken || token?.idToken || null;
        this.RawIdToken = token?.idToken ?? null;
        this.accountHomeId = token?.account?.homeAccountId || "";
    }

    @Mutation
    public SET_PGA_TOKENS(token: PGAAuthResponse | null): void {
        this.PGAAccessToken = token?.AccessToken ?? '';
    }

    get name() {
        return this.Account?.name;
    }

    @Mutation
    private SET_ACCESSIBILITY_MODE(): void {
        if (this.Permissions.includes("NeedAccessibility"))
            this.Accessibility = true;
        else
            this.Accessibility = false;
    }
    @Mutation
    private SET_SHOW_NOTIFICATIONS(): void {
        this.ShowNotifications = this.IsLogged;
    }
}

export const SharedModule = getModule(SharedStore);

export function deepSearchText ( text, obj: any )
{
    if ( obj instanceof Array )
    {
        let result = false;
        for ( let i = 0; i < obj.length; i++ )
        {
            result = Object.values( obj[i] ).some( val => deepSearchText( text, val ) );
            if(result)
                return true;
        }
        return false;
    }
    else if ( typeof obj === "string" )
    {
        const regexGUID = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
        if(regexGUID.test(obj))
            return false;
        return (obj.toLowerCase()).includes( text.toLowerCase() );
    }
    else if ( typeof obj === "number" )
    {
        return (obj.toString()).includes( text.toLowerCase() );
    }
    else if ( typeof obj === "boolean" )
    {
        return obj ? text.toLowerCase() === "true" : text.toLowerCase() === "false";
    }
    else if ( obj === null )
    {
        return false;
    }

    return Object.values( obj ).some( val => deepSearchText( text, val ) );
}

export function parseErrors(error: any): Map<string, Array<string>> {
    const errs = new Map<string, Array<string>>();
    for (const [key, value] of Object.entries(error.response.data)) {
        const messages = value as Array<string>;
        errs.set(key, messages);
    }
    return errs;
}

export function deepComparison(obj1: any, obj2: any): boolean
{
    if(obj1 === null && obj2 === null)
        return true;
    if(obj1 === null)
        return false;
    if(obj2 === null)
        return false;
    for (const p in (obj1 ?? []))
    {
        //Check if at least one object is null and compare them
        if(obj1 === null || obj2 === null)
            return obj1 === obj2;
		//Check property exists on both objects
		if (Object.prototype.hasOwnProperty.call(obj1, p) !== Object.prototype.hasOwnProperty.call(obj2, p))
            return false;
 
		switch (typeof (obj1[p])) {
			//Deep compare objects
			case 'object':
				if (!deepComparison(obj1[p], obj2[p]))
                    return false;
				break;
			//Compare function code
			case 'function':
				if (typeof (obj2[p]) == 'undefined' || (p != 'compare' && obj1[p].toString() != obj2[p].toString()))
                    return false;
				break;
			//Compare values
			default:
				if (obj1[p] != obj2[p])
                    return false;
		}
	}
    
	//Check object 2 for any extra properties
	for (const p in obj2)
    {
		if (typeof (obj1[p]) == 'undefined') return false;
	}
	return true;
}

export function IsMobile (sizeWindow: BreakpointName)
{
    switch ( sizeWindow )
    {
        case 'xs':
        case 'sm':
        case 'md': return true
        default: return false
    }
}

export function getFileName (fullFileName: string, separator = "/") : string
{
    const fileNameSplitted = fullFileName.split(separator);
    return fileNameSplitted[fileNameSplitted.length - 1] ?? "";
}

export function deepCopy(obj1: any): any
{
    return JSON.parse(JSON.stringify(obj1));
}