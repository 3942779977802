













import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component({})
export default class PgaExpansionPanel extends Vue
{
    @Prop({ default: false }) readonly inset!: boolean;
    @Prop({ default: "" }) readonly header!: string;
}
