







import Vue from 'vue'
import { Emit, Component, Prop } from 'vue-property-decorator'

@Component( {} )
export default class PgaEditButton extends Vue
{
	@Prop( { default: "Edit" } ) readonly Text!: string;
	@Prop( { default: "mdi-plus" } ) readonly Icon!: string;

	@Emit( "click" )
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	clicked (): void { }
}
