import * as signalR from "@microsoft/signalr";
import { SharedModule } from '../store';

export default
    {
        install(Vue: any): void {
            const connection = new signalR.HubConnectionBuilder()
                .withUrl(`${process.env.VUE_APP_NOTIFICATION_API_URL || window.location.origin}pgahub`, {
                    accessTokenFactory: () => SharedModule.PGAAccessToken,
                    // skipNegotiation: true,
                    // transport: signalR.HttpTransportType.WebSockets

                })
                .configureLogging(signalR.LogLevel.Trace)
                .build();

            let startedPromise!: Promise<unknown>;
            function start() {
                if (!SharedModule.IsLogged) {
                    return new Promise((resolve, reject) => setTimeout(() => start().then(resolve).catch(reject), 5000));
                }
                startedPromise = connection.start().catch((err: any) => {
                    console.error('Failed to connect with hub', err);
                    return new Promise((resolve, reject) => setTimeout(() => start().then(resolve).catch(reject), 5000));
                });
                return startedPromise;
            }

            start();            
            connection.on("SendNoticeEventToClient", (message: any) => {                
                Vue.prototype.$eventHub.$emit("notification", message);
            });
        }
    }