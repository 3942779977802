import IAuthService, { AuthResponse } from './iAuthService'
import AxiosService from './AxiosService'
import { injectable } from 'inversify';
import AuthRoutes from './AuthRoutes';

@injectable()
export default class AuthService extends AxiosService implements IAuthService {
    protected API_URL: string = AuthRoutes.ApiUrl;

    async Login(loginToken: string): Promise<AuthResponse> {
        const r = await this.CustomGet<AuthResponse>(AuthRoutes.AuthenticationPath, { headers: { "Authorization": `Bearer ${loginToken}` } });
        return r.data;
    }

    async RefreshToken(): Promise<AuthResponse> {
        const data = await this.BaseGet<AuthResponse>("/auth/refreshtoken");
        return data;
    }
}