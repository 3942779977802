import "isomorphic-fetch";
import IGraphService from './iGraphService'
import { injectable } from 'inversify'
import { AxiosRequestConfig } from 'axios';
import { SharedModule } from '@/feature/Shared/store'
import AxiosService from './AxiosService';
//import MsalService from './MsalService';
//import { Client, ClientOptions} from '@microsoft/microsoft-graph-client';
//import { } from '@microsoft/microsoft-graph-types';
//import { ImplicitMSALAuthenticationProvider, MSALAuthenticationProviderOptions } from "@microsoft/microsoft-graph-client/lib/src/browser";

@injectable()
export default class GraphService extends AxiosService implements IGraphService {
    protected readonly API_URL: string = 'https://graph.microsoft.com/v1.0/';

    get baseRequestConfig() : AxiosRequestConfig {
        return { responseType: 'blob', headers: { 'Authorization': `Bearer ${SharedModule.Token}` } }
    }
    //protected GraphScopers: string[] = [...this.authPar.scopes!];
    //protected providerOptions = new MSALAuthenticationProviderOptions(this.GraphScopers);
    //protected authProvider = new ImplicitMSALAuthenticationProvider(this.userAgentApplication, this.providerOptions);
    //protected options: ClientOptions = {
    //    authProvider: this.authProvider, // An instance created from previous step
    //};
    //protected Client = MicrosoftGraph.Client;
    //protected client: Client = Client.initWithMiddleware(this.options);
    
    GetAvatar(): Promise<string> {
        return this.CustomGet<any>("me/photos('48x48')/$value", this.baseRequestConfig)
            .then(response => {
                const imageUrl = window.URL.createObjectURL(response.data);
                return imageUrl;
            }).catch(err => { return ''; });
    }


    GetMeetings(): Promise<any> {
        return this.BaseGet<any>("me/events?$select=subject,organizer,attendees,start,end,location,onlineMeeting,bodyPreview,webLink,body")
            .then(response => {
                return response.data.value;
            }).catch(err => { return ''; });
    }
}