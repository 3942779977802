

































import { Component, Prop, Model } from "vue-property-decorator";

import ValidationMixin from "@/mixins/ValidationMixin";
import { mixins } from "vue-class-component";

@Component( {} )
export default class PgaDatePicker extends mixins( ValidationMixin )
{
	private showMenu = false;

	@Prop( { type: String, default: "" } ) readonly label!: string;
	@Prop( { type: Boolean, default: false } ) readonly readonly!: boolean;

	@Prop({ type: String, default: "" }) readonly keyError!: string;
	@Prop({ default: function() { return new Map<string, Array<string>>() } }) readonly Errors!: Map<string, Array<string>>;

	@Model( "input", { type: Date } ) Model!: Date;
	updateValue ( value: Date ): void
	{
		this.showMenu = false;
		this.$emit( "input", new Date(value) );
	}

	private get localeDate() : string
	{
		return new Date(this.Model).toLocaleDateString();
	}

	private get isoDate() : string
	{
		return new Date(this.Model).toISOString().split('T')[0];
	}
}
