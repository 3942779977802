
























import { Component, Prop, Model } from "vue-property-decorator";

import ValidationMixin from "@/mixins/ValidationMixin";
import { mixins } from "vue-class-component";

@Component({})
export default class PgaSelect extends mixins(ValidationMixin)
{
	// @Prop({ default: 0 }) readonly value!: number;
    @Prop({ type: String, default: "" }) readonly label!: string;
    @Prop({ type: Boolean, default: false }) readonly readonly!: boolean;
    @Prop({ type: Boolean, default: false }) readonly multiple!: boolean;
    @Prop({ type: Boolean, default: false }) readonly dense!: boolean;
    @Prop({ type: Boolean, default: false }) readonly clearable!: boolean;
    @Prop({ type: Boolean, default: true }) readonly attach!: boolean;
    
    @Prop({ default: "" }) readonly itemText!: string;
    @Prop({ default: "" }) readonly itemValue!: string;
    @Prop({ type: Boolean, default: false }) readonly returnObject!: boolean;
    @Prop({ default: function() { return new Array<unknown>() } }) readonly items!: Array<unknown>;

	@Prop({ default: "" }) readonly keyError!: string;
	@Prop({ default: function() { new Map<string, Array<string>>() } }) readonly Errors!: Map<string, Array<string>>;

    @Prop({ type: undefined }) value!: Array<unknown>;
	@Model("input", { type: undefined }) Model!: Array<unknown>;
	updateValue(value: Array<unknown>) : void
	{
		this.$emit('input', value);
    }
    
    private dropDownPosition = {};
    
    private onResize(): void
	{
        //TODO: find a way to know value of zoom into page
        // const zoom = (window.outerWidth / window.innerWidth) * 100;
		if (window.innerHeight > 960)
		{
			this.dropDownPosition = {};
		}
		else
		{
			this.dropDownPosition = { maxHeight: 240, offsetY: true };
        }
    }

    mounted() : void
    {
        window.addEventListener("resize", this.onResize);
        window.addEventListener("zoom", this.onResize);
    }
}
