





















import { SharedModule } from '@/feature/Shared/store';
import Vue from 'vue';
import { Component, Prop, Model } from 'vue-property-decorator';

@Component({})
export default class PgaSwitch extends Vue
{
    @Prop({ default: "", type: String }) readonly label!: string;
    @Prop({ default: "", type: String }) readonly title!: string;
    @Prop({ default: "success", type: String }) readonly color!: string;
    @Prop({ default: false, type: Boolean }) readonly readonly!: boolean;
    @Prop({ default: false, type: Boolean }) readonly inset!: boolean;
    @Prop({ default: false, type: Boolean }) readonly dense!: boolean;
    @Prop({ default: false, type: Boolean }) readonly disabled!: boolean;

    @Prop({ default: function() { return new Array<string>() } }) readonly pgaAriaLabel!: Array<string>;
    
    @Model("change", { type: Boolean}) Model!: boolean;
    updateValue(value: boolean) : void
	{
		this.$emit('change', value);
    }

    get accessibility() : boolean
    {
        return SharedModule.Accessibility;
    }
    
    private showLabel() : string
    {
        if(this.accessibility)
            return this.Model? this.pgaAriaLabel[0] : this.pgaAriaLabel[1];
        return this.label;
    }
} 
