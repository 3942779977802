import { Vue, Component } from "vue-property-decorator";

@Component({})
export default class ValidationMixin extends Vue {
  protected Errors!: Map<string, Array<string>>;

  errors(field: string): Array<string> {
    const errs = this.Errors?.get(field);
    if (!errs) return new Array<string>();

    return errs;
  }
  errorCount(field: string): number {
    return this.errors(field).length;
  }
  hasErrors(field: string): boolean {
    return this.Errors?.get(field) !== undefined;
  }
}