



















import { Component, Prop, Model } from "vue-property-decorator";

import ValidationMixin from "@/mixins/ValidationMixin";
import { mixins } from "vue-class-component";
import { Guid } from 'guid-typescript';
import { IsMobile } from '../../feature/Shared/store';

@Component({})
export default class PgaSlider extends mixins(ValidationMixin)
{
	@Prop({ type: String }) readonly Id!: Guid;

	@Prop({ default: false, type: Boolean }) readonly readonly!: boolean;

	@Prop({ default: 0, type: Number }) readonly min!: number;
	@Prop({ default: 100, type: Number }) readonly max!: number;
	@Prop({ default: 2, type: Number }) readonly tickSize!: number;
	@Prop({ default: 1, type: Number }) readonly step!: number;

	@Prop({ default: function(){ new Array<string>() } }) readonly tickLabels!: Array<string>;
	@Prop({ default: function(){ new Array<string>() } }) readonly tickLabelsAlternative!: Array<string>;

	@Prop({ type: String, default: "ExpertiseIds" }) readonly errorKey!: string;
	@Prop({ type: String, default: "Expertises" }) readonly errorMessageKey!: string;
	@Prop({ default: function() { new Map<string, Array<string>>() } }) readonly Errors!: Map<string, Array<string>>;

	@Prop({ default: 0, type: Number }) readonly value!: number;
	@Model("input", { type: Number }) Model!: number;
	updateValue(value: number): void
	{
		this.$emit("input", this.Id, value);
	}

	get arrayLabel(): Array<string>
	{
		if(IsMobile(this.$vuetify.breakpoint.name))
			return this.tickLabelsAlternative;
		else
			return this.tickLabels;		
	}

	private get showError() : boolean
	{
		if(this.Errors)
		{
			const hasErrors = this.hasErrors(this.errorKey);
			const rowHasErrors = (this.errors(this.errorKey).includes(this.Id.toString()) ?? false);
			return hasErrors && rowHasErrors;
		}
		return false;
	}

	private get showErrorMessage(): string
	{
		return this.errors(this.errorMessageKey)[0] ?? "";
	}
}
