








import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { SharedModule } from "@/feature/Shared/store";

@Component({})
export default class PgaAuthorizedOnlyOneContent extends Vue
{
	@Prop({ default: function() { return new Array<string>() } }) readonly permissions!: Array<string>;

	// Array indexes of Permission that user has with those passed
	private indexPerms = new Array<number>();
	
	public isAuthorized(i: number) : boolean
	{		
		// If user has at least one permission, then check if the index passed is equal with minimum of IndexPerms
		return this.indexPerms.length > 0 && i == Math.min(...this.indexPerms);
	}

	mounted() : void
	{
		// Takes index of permissions which user has
		let perms = new Array<number>();
		this.permissions.forEach((p, index) => {
			if (SharedModule.Permissions.includes(p))
				perms.push(index);
		});
		this.indexPerms = perms;
	}
}
