



















import Vue from 'vue';
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

import PgaTextField from './inputs/PgaTextField.vue';

@Component({
	components: { PgaTextField }
})
export default class PgaTimeLine extends Vue
{
    @Prop() readonly listItems!: Array<any>;
	@Prop({ default: true }) readonly dense!: boolean;
	
	private Search = "";
	get search() : string
	{
		return this.Search;
	}
	set search(value: string)
	{
		this.Search = value;
	}
	
	get listItemsFiltered() : Array<any>
	{
		if(this.Search == "" || this.Search == null || this.Search == undefined)
		{
			return this.listItems;
		}
		else
		{
			return this.listItems.filter(i => objectMatchesText(this.Search, i));
		}
	}
}

function objectMatchesText(text, obj)
{
	if (typeof obj === "string")
	{
		return obj.includes(text);
	}

    return Object.values(obj).some(val => objectMatchesText(text, val));
}
