import { Guid } from 'guid-typescript';

export default interface IResourceService
{
    // Certifications
    GetAllCertifications (): Promise<ListCertifications>;
    GetCertificationById ( id: Guid ): Promise<CertificationModel>;
    CreateCertification ( cmd: CreateCertificationCommand ): Promise<CreateCertificationResponse>;
    EditCertification ( id: Guid, cmd: EditCertificationCommand ): Promise<EditCertificationResponse>;
    DeleteCertification ( id: Guid ): Promise<DeleteCertificationResponse>;
    SelectUsersCertification ( id: Guid, cmd: SelectUsersCertificationCommand ): Promise<SelectUsersCertificationResponse>;
    GetMyCertifications (): Promise<MyCertificationsTableView>;
    SelectMyCertifications ( cmd: SelectMyCertificationsCommand ): Promise<SelectMyCertificationsResponse>;
    GetAllCertificationsDropdown (): Promise<ListCertifications>;

    // Expertises
    GetAllExpertises (): Promise<ListExpertises>;
    GetExpertise ( id: Guid ): Promise<ExpertiseModel>;
    CreateExpertise ( cmd: CreateExpertiseCommand ): Promise<CreateExpertiseResponse>;
    EditExpertise ( id: Guid, cmd: EditExpertiseCommand ): Promise<EditExpertiseResponse>;
    DeleteExpertise ( id: Guid ): Promise<DeleteExpertiseResponse>;
    SelectUsersExpertise ( id: Guid, cmd: SelectUsersExpertiseCommand ): Promise<SelectUsersExpertiseResponse>;
    GetMyExpertises (): Promise<MyExpertisesTableView>;
    SelectMyExpertises ( cmd: SelectMyExpertisesCommand ): Promise<SelectMyExpertisesResponse>;
    GetAllExpertisesProposedByAreaManager (): Promise<ListExpertisesProposed>;
    ApproveExpertiseProposed ( cmd: ApproveUserExpertiseProposedCommand ): Promise<ApproveUserExpertiseProposedResponse>;
    DenyExpertiseProposed ( cmd: DenyUserExpertiseProposedCommand ): Promise<DenyUserExpertiseProposedResponse>;

    // Technologies
    GetAllTechnologies (): Promise<ListTechnologies>;
    GetTechnology ( id: Guid ): Promise<TechnologyModel>;
    CreateTechnology ( cmd: CreateTechnologyCommand ): Promise<CreateTechnologyResponse>;
    EditTechnology ( id: Guid, cmd: EditTechnologyCommand ): Promise<EditTechnologyResponse>;
    DeleteTechnology ( id: Guid ): Promise<DeleteTechnologyResponse>;
    GetAllTechnologiesDropdown (): Promise<ListTechnologies>;

    // Vendors
    GetAllVendors (): Promise<ListVendors>;
    GetVendor ( id: Guid ): Promise<VendorModel>;
    CreateVendor ( cmd: CreateVendorCommand ): Promise<CreateVendorResponse>;
    DeleteVendor ( id: Guid ): Promise<DeleteVendorResponse>;
    GetAllVendorsDropdown (): Promise<ListVendors>;
    ApproveVendor(cmd: ApproveVendorCommand): Promise<ApproveVendorResponse>;
    ApproveDeletionVendor(cmd: ApproveDeletionVendorCommand): Promise<ApproveDeletionVendorResponse>;


    // SIP Users
    GetAllSIPUsers (): Promise<ListSIPUsers>;
}


//Certifications
export interface CertificationModel
{
    Id: Guid;
    TenantId: Guid;
    Code: string;
    Description: string;
    IsActive: boolean;
    Type: TypeCertification;
    Vendor: VendorModel;
    ModifiedDate: Date;
    ModifiedBy: UserResource;
    Expertises: Array<ExpertiseTableModel>;
    Users: Array<UserAttainmentDateResource>;
}
export interface CertificationTableModel
{
    Id: Guid;
    Code: string;
    Description: string;
    IsActive: boolean;
    Type: TypeCertification;
    Vendor: VendorModel;
}
export interface ListCertifications
{
    Certifications: Array<CertificationTableModel>;
}
export interface CreateCertificationCommand
{
    Code: string;
    Description: string;
    IsActive: boolean;
    Type: TypeCertification;
    VendorId: Guid | null;
    ExpertiseIds: Array<Guid>;
}
export interface CreateCertificationResponse
{
    Id: Guid;
}
export interface EditCertificationCommand
{
    Id: Guid;
    Code: string;
    Description: string;
    IsActive: boolean;
    Type: TypeCertification;
    VendorId: Guid;
    ExpertiseIds: Array<Guid>;
}
export interface EditCertificationResponse
{
    Id: Guid
}
export interface DeleteCertificationCommand
{
    Id: Guid,
}
export interface DeleteCertificationResponse
{
    Id: Guid,
}
export interface SelectUsersCertificationCommand
{
    Users: Array<UserIdDate>,
}
export interface SelectUsersCertificationResponse
{
    Id: Guid,
}
export interface UserIdDate
{
    UserId: Guid,
    AttainmentDate: Date,
    ExpireDate: Date | null,
}
export interface MyCertificationsTableView
{
    Certifications: Array<MyCertificationViewModel>,
}
export interface MyCertificationViewModel
{
    Id: Guid;
    Code: string;
    Description: string;
    Vendor: VendorModel;
    IsActive: boolean;
    Type: TypeCertification;
    AttainmentDate: Date;
    ExpireDate: Date | null;
}
export interface SelectMyCertificationsCommand
{
    Certifications: Array<CertificationIdDate>,
}
export interface SelectMyCertificationsResponse
{
    Id: Guid,
}
export interface CertificationIdDate
{
    CertificationId: Guid,
    AttainmentDate: Date,
    ExpireDate: Date | null,
}
export enum TypeCertification
{
    Exam = 1,
    Certification = 2,
}


//Expertises
export interface ExpertiseModel
{
    Id: Guid;
    Code: number;
    Description: string;
    ModifiedDate: Date;
    ModifiedBy: UserResource;
    TechAreas: Array<TechAreaModel>;
    Technologies: Array<TechnologyTableModel>;
    Users: Array<UserSkillResource>;
    Handleable: boolean;
}
export interface ExpertiseTableModel
{
    Id: Guid;
    Code: number;
    Description: string;
    TechAreas: Array<TechAreaModel>;
    Technologies: Array<TechnologyTableModel>;
}
export interface ListExpertises
{
    Expertises: Array<ExpertiseTableModel>;
}
export interface CreateExpertiseCommand
{
    Description: string,
    TechAreaIds: Array<Guid>,
    TechnologyIds: Array<Guid>,
}
export interface CreateExpertiseResponse
{
    Id: Guid,
}
export interface EditExpertiseCommand
{
    Id: Guid;
    Description: string,
    TechAreaIds: Array<Guid>,
    TechnologyIds: Array<Guid>,
}
export interface EditExpertiseResponse
{
    Id: Guid,
}
export interface DeleteExpertiseCommand
{
    Id: Guid,
}
export interface DeleteExpertiseResponse
{
    Id: Guid,
}
export interface SelectUsersExpertiseCommand
{
    Users: Array<UserIdSkill>,
}
export interface SelectUsersExpertiseResponse
{
    Id: Guid,
}
export interface UserIdSkill
{
    UserId: Guid,
    Skill: Skill,
}
export interface MyExpertisesTableView
{
    Expertises: Array<MyExpertiseViewModel>,
}
export interface MyExpertiseViewModel
{
    Id: Guid,
    Code: number,
    Description: string,
    Skill: Skill,
    Status: StatusSkill,
}
export interface SelectMyExpertisesCommand
{
    Expertises: Array<ExpertiseIdSkill>,
}
export interface SelectMyExpertisesResponse
{
    Id: Guid,
}
export interface ExpertiseIdSkill
{
    ExpertiseId: Guid,
    Skill: Skill,
}
export interface ListExpertisesProposed
{
    Expertises: Array<ExpertiseProposed>;
}
export interface ExpertiseProposed
{
    Expertise: ExpertiseModel;
    User: UserEmailResource;
}
export interface ApproveUserExpertiseProposedCommand
{
    UserId: Guid;
    ExpertiseId: Guid;
}
export interface ApproveUserExpertiseProposedResponse
{
    IsApproved: boolean;
}
export interface DenyUserExpertiseProposedCommand
{
    UserId: Guid;
    ExpertiseId: Guid;
}
export interface DenyUserExpertiseProposedResponse
{
    IsDenied: boolean;
}

//Technologies
export interface TechnologyModel
{
    Id: Guid;
    Code: number;
    Description: string;
    TechAreas: Array<TechAreaModel>;
    ModifiedDate: Date;
    ModifiedBy: UserResource;
    Certifications: Array<CertificationTableModel>;
    Handleable: boolean;
}
export interface TechnologyTableModel
{
    Id: Guid;
    Code: number;
    Description: string;
    TechAreas: Array<TechAreaModel>;
    Certifications: Array<CertificationTableModel>;
}
export interface ListTechnologies
{
    Technologies: Array<TechnologyTableModel>;
}
export interface CreateTechnologyCommand
{
    Description: string,
    TechAreaIds: Array<Guid>,
    CertificationIds: Array<Guid>,
}
export interface CreateTechnologyResponse
{
    Id: Guid,
}
export interface EditTechnologyCommand
{
    Id: Guid;
    Description: string,
    TechAreaIds: Array<Guid>,
    CertificationIds: Array<Guid>,
}
export interface EditTechnologyResponse
{
    Id: Guid,
}
export interface DeleteTechnologyCommand
{
    Id: Guid,
}
export interface DeleteTechnologyResponse
{
    Id: Guid,
}

//Vendors
export interface VendorModel
{
    Id: Guid;
    Code: string;
    BusinessName: string;
    Status: EntityResourceStatus;
}
export interface ListVendors
{
    Vendors: Array<VendorModel>;
}
export interface CreateVendorCommand
{
    BusinessName: string;
}
export interface CreateVendorResponse
{
    Id: Guid;
}
export interface DeleteVendorCommand
{
    Id: Guid;
}
export interface DeleteVendorResponse
{
    Id: Guid;
}
export interface ApproveVendorCommand
{
    Id: Guid;
}
export interface ApproveVendorResponse
{
    Id: Guid;
}
export interface ApproveDeletionVendorCommand
{
    Id: Guid;
}
export interface ApproveDeletionVendorResponse
{
    Id: Guid;
}


//SIP Users
export interface ListSIPUsers
{
    Users: Array<SIPUserModel>;
}

export interface SIPUserModel
{
    CodPers: number;
    CognPers: string;
    NomePers: string;
    EmailPers: string;
}


//Shared
export interface TechAreaModel
{
    Id: Guid,
    Code: string,
    Name: string,
}

export interface UserResource
{
    Id: Guid,
    FullName: string,
}
export interface UserResource
{
    Id: Guid,
    FullName: string,
}

export interface UserEmailResource
{
    Id: Guid,
    FullName: string,
    Email: string,
}

export interface UserSkillResource
{
    Id: Guid,
    FullName: string,
    Skill: Skill,
}

export interface UserAttainmentDateResource
{
    Id: Guid,
    FullName: string,
    AttainmentDate: Date,
    ExpireDate: Date | null,
}

export enum EntityResourceStatus
{
    Created = 1 << 0,
    Approved = 1 << 1,
    DeletionRequest = 1 << 2,
    Deleted = 1 << 3,
}

export enum Skill
{
    Basic = 1,
    Intermediate = 2,
    Advanced = 3,
}

export enum StatusSkill
{
    Approved = 1,
    Proposed = 2,
    Expired = 3,
}