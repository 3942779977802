















import { Component, Prop, Model } from "vue-property-decorator";

import ValidationMixin from "@/mixins/ValidationMixin";
import { mixins } from "vue-class-component";

@Component({})
export default class PgaDateField extends mixins(ValidationMixin)
{
	@Prop({ default: "" }) readonly label!: string;
	@Prop({ default: false }) readonly readonly!: boolean;
	@Prop({ default: false }) readonly dense!: boolean;

	@Prop({ default: "" }) readonly keyError!: string;
	@Prop({ default: function(){ new Map<string, Array<string>>() } }) readonly Errors!: Map<string, Array<string>>;

	@Model("input", { type: undefined }) Model!: Date;
	updateValue(value: Date) : void
	{
		this.$emit('input', value);
	}

	mounted() : void
	{
		if(typeof(this.Model) === "string")
		{
			this.Model = new Date(this.Model);
		}
	}
}
