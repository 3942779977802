



































































































































//import Vue from 'vue';
import { Vue, Component } from "vue-property-decorator";
import { LayoutModule } from "./store";
import { SharedModule } from "@/feature/Shared/store";
import { ChildrenMenu } from "@/services/iIdentityService";

@Component({
  data: () => ({
    navigation: {
      expandOnHover: true,
      miniVariant: true,
      dark: true,
    },
    drawerRight: null,
    right: false,
    left: false,
  }),
  components: {},
  computed: {
    drawer: {
      get() {
        return LayoutModule.drawer;
      },
      set(value) {
        LayoutModule.SET_DRAWER(value);
      },
    },
  },
})
export default class PgaNavigationDrawer extends Vue {
  async Login() {
    await SharedModule.silentCheckToken();
  }

  Logout() {
    SharedModule.logout();
  }

  get IsLogged() {
    return SharedModule.IsLogged;
  }

  get Menu() {
    return SharedModule.Menu;
  }

  get Permissions() {
    return SharedModule.Permissions;
  }

  get LoggedUserName() {
    return SharedModule.Name;
  }

  get LoggedUserImage() {
    return SharedModule.AvatarImageUrl;
  }

  checkChildrenPermission(childrenMenu: Array<ChildrenMenu>): boolean {
    const permission = this.Permissions;
    var z = childrenMenu
      .map((a) => a.PermissionCode)
      .some((a) => this.Permissions.includes(a));
    //.filter(a=>this.Permissions.includes(a))
    return z;
  }
}
