
























import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { SharedModule } from "@/feature/Shared/store";

@Component({})
export default class PgaAlertWarning extends Vue
{
	@Prop({ type: String, default: "" }) readonly message! : string;
	@Prop({ type: Boolean, default: false }) readonly alert! : boolean;
	@Prop({ type: Boolean, default: false }) absolute! : boolean;

	get warningMessage() : string
	{
		return SharedModule.AlertWarningMessage;
	}
	set warningMessage(warning: string)
	{
		SharedModule.SET_ALERT_WARNING_MESSAGE(warning);
	}

	get isVisibleAlert() : boolean
	{
		return this.warningMessage != "";
	}

	clicked(): void
	{
		SharedModule.SET_ALERT_WARNING_MESSAGE("");
	}
}
