import { Vue, Component } from "vue-property-decorator";

import PgaBottomNavigation from "@/feature/Shared/Layout/PgaBottomNavigation.vue";
import PgaAuthorizedContent from "@/components/PgaAuthorizedContent.vue";
import PgaAuthorizedOnlyOneContent from "@/components/PgaAuthorizedOnlyOneContent.vue";
import PgaCardFieldGroup from "@/components/PgaCardFieldGroup.vue";

import PgaSkeletonLoader from "@/components/PgaSkeletonLoader.vue";
import PgaSwitch from "@/components/PgaSwitch.vue";
import PgaDialog from '@/components/PgaDialog.vue';
import PgaAlertError from '@/components/PgaAlertError.vue';
import PgaDataTable from '@/components/PgaDataTable.vue';
import PgaEntityStatus from '@/components/PgaEntityStatus.vue';
import PgaTimeLine from '@/components/PgaTimeLine.vue';
import PgaExpansionPanel from '@/components/PgaExpansionPanel.vue';
import PgaLoadingSpinner from '@/components/PgaLoadingSpinner.vue';
import PgaUserSkill from '@/components/PgaUserSkill.vue';
import PgaPartialContent from '@/components/PgaPartialContent.vue';

import PgaCreateButton from '../components/buttons/PgaCreateButton.vue';
import PgaSaveButton from '../components/buttons/PgaSaveButton.vue';
import PgaApproveButton from '../components/buttons/PgaApproveButton.vue';
import PgaCancelButton from '../components/buttons/PgaCancelButton.vue';
import PgaEditButton from '../components/buttons/PgaEditButton.vue';
import PgaDeleteButton from '../components/buttons/PgaDeleteButton.vue';
import PgaRequestChangesButton from '../components/buttons/PgaRequestChangesButton.vue';
import PgaGeneralButton from '../components/buttons/PgaGeneralButton.vue';

import PgaNumberField from '@/components/inputs/PgaNumberField.vue';
import PgaTextField from '@/components/inputs/PgaTextField.vue';
import PgaTextArea from '@/components/inputs/PgaTextArea.vue';
import PgaCurrencyField from '../components/inputs/PgaCurrencyField.vue';
import PgaSelect from '../components/inputs/PgaSelect.vue';
import PgaCombobox from '../components/inputs/PgaCombobox.vue';
import PgaDateField from '../components/inputs/PgaDateField.vue';
import PgaDatePicker from '../components/inputs/PgaDatePicker.vue';
import PgaInputFile from '@/components/inputs/PgaInputFile.vue';
import PgaDropdownSearch from '@/components/inputs/PgaDropdownSearch.vue';

import PgaSlider from '@/components/inputs/PgaSlider.vue';
import PgaProgressLinear from '@/components/inputs/PgaProgressLinear.vue';
import PgaTooltipIcon from '@/components/PgaTooltipIcon.vue';

@Component( {
	components: {
		PgaBottomNavigation,
		PgaAuthorizedContent,
		PgaAuthorizedOnlyOneContent,
		PgaCardFieldGroup,

		PgaSkeletonLoader,
		PgaSwitch,
		PgaDialog,
		PgaAlertError,
		PgaDataTable,
		PgaEntityStatus,
		PgaTimeLine,
		PgaExpansionPanel,
		PgaLoadingSpinner,
		PgaUserSkill,
		PgaPartialContent,

		PgaCreateButton,
		PgaSaveButton,
		PgaApproveButton,
		PgaCancelButton,
		PgaEditButton,
		PgaDeleteButton,
		PgaRequestChangesButton,
		PgaGeneralButton,

		PgaNumberField,
		PgaTextField,
		PgaTextArea,
		PgaCurrencyField,
		PgaSelect,
		PgaCombobox,
		PgaDateField,
		PgaInputFile,
		PgaDropdownSearch,
		PgaDatePicker,

		PgaSlider,
		PgaProgressLinear,
		PgaTooltipIcon,
	}
} )
export default class SharedMixin extends Vue
{
	//public heightClient: number = 0;
	public Navigate ( url: string )
	{
		this.$router.push( url ).catch( err =>
		{
			const a = err;
		});
	}

	public FormatMoney(number: number)
	{
		const formatter: Intl.NumberFormat = new Intl.NumberFormat('it-IT',
			{
				style: 'currency',
				currency: 'EUR',
			});
	
		return formatter.format(number);
	}
}