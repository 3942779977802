import { Guid } from "guid-typescript";

export default interface iNotificationService
{
    // Notification
    ListNotificationByUser() : Promise<Array<NotificationModel>>;
    MarkAsReadNotification(id: Guid) : Promise<MarkAsReadResponse>;
    DeleteNotification(id: Guid) : Promise<DeleteNotificationResponse>
    CreateNotificationByRole(id: Guid, command: CreateNotificationByRoleCommand) : Promise<CreateNotificationByRoleResponse>;
    DismissNotificationsByUser(): Promise<Array<NotificationModel>>;
    DeleteNotificationsByUser(): Promise<Array<NotificationModel>>

    // Activity
    ListActivities() : Promise<ListActivities>;
    DeleteActivity(id: Guid) : Promise<DeleteActivityResponse>;

    // Email
    SendEmailToPermissions(command: SendEmailToPermissionsCommand) : Promise<void>;
    SendEmailToUsers(command: SendEmailToUsersCommand) : Promise<void>;
}

// NOTIFICATION
export interface NotificationModel
{
    Id: Guid;

    Title: string;
    Description: string;
    Uri: string;

    Read: boolean;
    IsActive: boolean;
    
    PushTime: Date;
    ExpiresAt: Date;
    CreatedAt: Date;
}

export interface MarkAsReadResponse
{
    IsRead: boolean;
}
export interface DeleteNotificationResponse
{
    Id: Guid;
}
export interface CreateNotificationByRoleCommand
{
    Title: string;
    Description: string;
    Uri: string;
}
export interface CreateNotificationByRoleResponse
{
    Id: Guid;
}

// ACTIVITY
export interface ActivityModel 
{
    Id: Guid;
    UserId: Guid | null;
    RelatedEntityId: Guid;
    Type: ActivityType;
    
    Title: string;
    Description: string;
    CreatedAt: Date;

    UrlRelativePath: string;
    Payload: Array<ResourceAction>;
}
export interface ListActivities
{
    Activities: Array<ActivityModel>;
}
export interface DeleteActivityCommand
{
    Id: Guid;
}
export interface DeleteActivityResponse
{
    Id: Guid;
}
export enum ActivityType
{
    // BusinessLine
    BusinessLineCreationApproval = 1,
    BusinessLineDeletionApproval = 2,

    // SupplyTipology
    SupplyTipologyCreationApproval = 3,
    SupplyTipologyDeletionApproval = 4,

    // TechArea
    TechAreaCreationApproval = 5,
    TechAreaDeletionApproval = 6,

    // Scheduling
    ScheduleExpertisesProposed = 7,

    // Vendors
    VendorCreationApproval = 8,
    VendorDeletionApproval = 15,

    // AreaManager
    AreaManagerCreationApproval = 9,
    AreaManagerDeletionApproval = 10,

    // EconomicLimit
    EconomicLimitCreationApprovalDHRG = 11,
    EconomicLimitCreationApprovalDG = 12,

    // SolutionProduct
    SolutionProductCreationApproval = 13,
    SolutionProductDeletionApproval = 14,
}

export interface ActivityRole
{
    Id: Guid;
}

export interface ResourceAction
{
    Name: string;
    CompleteResourcePath: string;
    Service: string;
}

// EMAIL
export interface SendEmailToPermissionsCommand
{
    PermissionIds: Array<string>;
    Subject: string;
    Body: string;
}
export interface SendEmailToUsersCommand
{
    UserIds: Array<string>;
    Subject: string;
    Body: string;
}