



















import Vue from "vue";
import Component from "vue-class-component";
import { Emit, Prop } from "vue-property-decorator";
import { ActivityModel } from '@/services/iNotificationService';
import { ActivitiesStore } from '../store';

@Component({})
export default class PgaActivityItem extends Vue
{
    @Prop() readonly activity!: ActivityModel;
    @Prop() readonly colspan!: number;
    private message = "";

    @Emit("details")
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    private details(): void { }

    async action() : Promise<void>
    {
        let response = false;
        const payloads = this.activity.Payload;
        for(let i = 0; i < payloads.length; i++)
        {
            try
            {
                response = await ActivitiesStore.MakeActionFromActivity(payloads[i].CompleteResourcePath);
            }
            catch(error)
            {
                this.message = error.response.data;
                break;
            }
        }

        if(response)
        {
            const activities = ActivitiesStore.Activities;
            ActivitiesStore.SET_ACTIVITIES(activities.filter(a => a.Id != this.activity.Id));
        }
    }
}
