







import Vue from 'vue'
import { Emit, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class PgaCreateButton extends Vue
{
    @Prop({ default: "Create"}) readonly Text! : string;
    
    buttons = {
		create: {
			Icon: "mdi-plus",
            Text: "Create",
		}
    }
    
    @Emit("click")
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    clicked() : void { }
}
