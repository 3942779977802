







import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
@Component({})
export default class PgaSkeletonLoader extends Vue {
  @Prop({ default: false }) readonly isLoaded!: unknown;

  public get isOK(): boolean {
    return (
      this.isLoaded !== false &&
      this.isLoaded !== null &&
      this.isLoaded !== undefined
    );
  }
}
