




//import Vue from 'vue';
import { Vue, Component } from "vue-property-decorator";
@Component({
  data: () => ({
    componentKey: 0
  }),
  methods: {},
})
export default class PgaNavigationDrawer extends Vue {
  mounted(): void {
    Vue.prototype.$eventHub.$on(this.$route.path, (payload: any) => {
      this.updateData(payload);
    });  
  }
  updateData(payload: any):void {
    this.$props.componentKey = payload;
  }  
  beforeDestroy() {
    Vue.prototype.$eventHub.$off(this.$route.path);    
  }
}
