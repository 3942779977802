
















import Vue from "vue";
import { Component, Model, Prop } from "vue-property-decorator";
import { EntityOrderStatus } from "@/services/iOrderService";

@Component({})
export default class PgaEntityStatus extends Vue {
	@Prop({ type: String }) Title?: string;
	@Model("", { type: Number }) Model!: EntityOrderStatus;

	get color(): string {
		switch (this.Model) {
			case EntityOrderStatus.Created:
				return "info";
			case EntityOrderStatus.Approved:
				return "success";
			case EntityOrderStatus.DeletionRequest:
				return "warning";
			case EntityOrderStatus.Deleted:
				return "error";
			default:
				return "primary";
		}
	}

	get icon(): string {
		switch (this.Model) {
			case EntityOrderStatus.Created:
				return "mdi-new-box";
			case EntityOrderStatus.Approved:
				return "mdi-check-decagram";
			case EntityOrderStatus.DeletionRequest:
				return "mdi-delete-alert";
			case EntityOrderStatus.Deleted:
				return "mdi-delete-circle";
			default:
				return "mdi-alert-circle";
		}
	}

	get tooltip(): string {
		switch (this.Model) {
			case EntityOrderStatus.Created:
				return "Created";
			case EntityOrderStatus.Approved:
				return "Approved";
			case EntityOrderStatus.DeletionRequest:
				return "Deletion Requested";
			case EntityOrderStatus.Deleted:
				return "Deleted";
			default:
				return "Error";
		}
	}

	get titleDefined(): boolean {
		return this.Title != null;
	}
	get title(): string {
		return this.Title || "";
	}
}
