import Vue from 'vue';
import Vuetify from 'vuetify';
import it from 'vuetify/src/locale/it';
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify);

export default new Vuetify({
    lang: {
      locales: { it },
      current: 'it',
    },
    theme: {
      themes: {
        light: {
          primary: '#0069d9',
        },
      },
    },
});
