




















import { Vue, Component, Prop } from "vue-property-decorator";
@Component({
  data: () => ({
    bottomNav: "recent",
    buttons: [
      { Icon: "mdi-arrow-left", Text: "Back", Url: "-1"},
      { Icon: "mdi-arrow-right", Text: "Forward", Url: "1"}
    ]
  }),
  
})
export default class PgaBottomNavigation extends Vue
{
  @Prop({ default: true, type: Boolean }) readonly isLoaded!: boolean;

  navigate(url: string) {
    switch (url) {
      case "-1":
        this.$router.go(-1);
        break;
      case "1":
        this.$router.go(1);
        break;
      default:
        this.$router.push(url).catch(err => {
          const a = err;
        });
        break;
    }
  }
}
