import { Module, VuexModule, Action, getModule, Mutation } from "vuex-module-decorators";
import store from '@/infrastructure/store'
import { Guid } from "guid-typescript";

import pgaDiContainer from '@/App.container';
import iNotificationService, { ActivityModel, ActivityType, ListActivities, DeleteActivityResponse } from '@/services/iNotificationService';
import NotificationService from '@/services/NotificationService';

import iAxiosService from '@/services/iAxiosService';
import AxiosService from '@/services/AxiosService';
import { SharedModule } from "../store";

export interface ActivityState
{
    Activities: Array<ActivityModel>;
}

@Module({ namespaced: true, dynamic: true, store, name: 'ActivityStoreModule' })
class ActivityStore extends VuexModule implements ActivityState
{
    private _notificationService: iNotificationService = pgaDiContainer.get<iNotificationService>(NotificationService);
    private _axiosService: iAxiosService = pgaDiContainer.get<iAxiosService>(AxiosService);
    
    public Activities: Array<ActivityModel> = new Array<ActivityModel>();

    //GET: list all Activities
    @Action({ rawError: true})
    public async ListActivities() : Promise<void>
    {
        const listActivities: ListActivities = await this._notificationService.ListActivities();
        this.SET_ACTIVITIES(listActivities.Activities);
    }
    @Mutation
    public SET_ACTIVITIES(activities: Array<ActivityModel>)
    {
        activities.forEach(a => a.CreatedAt = new Date(a.CreatedAt))
        this.Activities = activities;
    }
    
    //Make Action
    @Action({ rawError: true})
    public async MakeActionFromActivity(url: string) : Promise<boolean>
    {
        return await this._axiosService.BaseGet<boolean>(url);
    }

    //Dismiss Activity
    @Action({ rawError: true})
    public async DismissActivity(id: Guid) : Promise<void>
    {
        try
        {
            SharedModule.SET_ISLOADING(true);
            await this._notificationService.DeleteActivity(id);
            ActivitiesStore.SET_ACTIVITIES(ActivitiesStore.Activities.filter(a => a.Id != id));
        }
        finally
        {
            SharedModule.SET_ISLOADING(false);
        }
    }
}

export const ActivitiesStore = getModule(ActivityStore);

export function TypeActivityText(type: ActivityType): string
{
    switch (type)
    {
        case 1:
            return "BusinessLine Creation Approval";
        case 2:
            return "BusinessLine Deletion Approval";
        case 3:
            return "SupplyTipology Creation Approval";
        case 4:
            return "SupplyTipology Deletion Approval";
        case 5:
            return "TechArea Creation Approval";
        case 6:
            return "TechArea Deletion Approval";
        case 7:
            return "Expertises Skill Approval";
        case 8:
            return "Vendor Creation Approval";
        case 9:
            return "AreaManager Creation Approval";
        case 10:
            return "AreaManager Deletion Approval";
        case 11:
            return "EconomicLimit Creation Approval DHRG";
        case 12:
            return "EconomicLimit Creation Approval DG";
        case 13:
            return "SolutionProduct Creation Approval";
        case 14:
            return "SolutionProduct Deletion Approval";
        case 15:
            return "Vendor Deletion Approval";
        default:
            return "Generic";
    }
}