

































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import moment from 'moment';

import { NotificationModel } from '@/services/iNotificationService';
import { NotificactionModule } from '../store';

import PgaSingleNotification from './PgaSingleNotification.vue';
import PgaDialog from '@/components/PgaDialog.vue';

@Component( {
	components:
	{
		PgaSingleNotification,
		PgaDialog
	}
} )
export default class PgaNotificationItem extends Vue
{
	@Prop( { default: new Array<NotificationModel>() } ) readonly notifications!: Array<NotificationModel>;

	dialogModel =
		{
			ShowDialog: false,
			Title: '',
			IconTitle: 'mdi-email-variant',
			Message: '',
			Color: 'primary',
			IconButton: 'mdi-checkbox-marked-circle-outline',
			FooterMessage: '',
		};

	async dialogNotification ( notification: NotificationModel ): Promise<void>
	{
		this.dialogModel.ShowDialog = true;
		this.dialogModel.Title = notification.Title;
		this.dialogModel.Message = notification.Description;
		this.dialogModel.FooterMessage = this.timeAgo( notification.CreatedAt );

		if ( !notification.Read )
			await NotificactionModule.MarkAsReadtNotifications( notification.Id );
	}

	private resetDialogNotification (): void
	{
		this.dialogModel.ShowDialog = false;
		this.dialogModel.Title = '';
		this.dialogModel.Message = '';
	}

	get emptyNotification (): boolean
	{
		return NotificactionModule.Notifications.length === 0;
	}

	private notRead ( read: boolean ): string
	{
		return read ? "" : "is-not-read"
	}

	private hideDialog (): void
	{
		this.resetDialogNotification();
	}

	private timeAgo ( createdAt: Date ): string
	{
		return moment( createdAt.toISOString(), "YYYY-MM-DDThh:mm:ss" ).fromNow();
	}
}
