
















import { Component, Prop, Model, Ref, Watch } from "vue-property-decorator";

import ValidationMixin from "@/mixins/ValidationMixin";
import { mixins } from "vue-class-component";
import { setValue, getValue } from "vue-currency-input";

@Component({})
export default class PgaCurrencyField extends mixins(ValidationMixin)
{
	@Ref('input') readonly textFieldRef!: HTMLInputElement;

	@Prop({ default: "", type: String }) readonly label!: string;
	@Prop({ default: false, type: Boolean }) readonly readonly!: boolean;
	@Prop({ default: false, type: Boolean }) readonly dense!: boolean;

	@Prop({ default: "", type: String }) readonly keyError!: string;
	@Prop({ default: function(){ return new Map<string, Array<string>>() } }) readonly Errors!: Map<string, Array<string>>;
	
	private formattedValue = 0;

	@Watch('Model')
	onPropertyChanged(value: number) : void
	{
		setValue(this.textFieldRef, value);
	}

	@Model("input", { type: Number}) Model!: number;
	updateValue(value: number) : void
	{
		this.$emit('input', getValue(this.textFieldRef) ?? 0);
		this.formattedValue = value;
	}

	mounted() : void
	{
		setValue(this.textFieldRef, this.Model);
	}
}
