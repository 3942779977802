
























import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Emit } from "vue-property-decorator";
import { SharedModule } from "@/feature/Shared/store";

@Component({})
export default class PgaAlertError extends Vue
{
	@Prop({ type: String, default: "" }) readonly message! : string;
	@Prop({ type: Boolean, default: false }) readonly alert! : boolean;
	@Prop({ type: Boolean, default: false }) absolute! : boolean;

	get errorMessage() : string
	{
		return SharedModule.AlertErrorMessage;
	}
	set errorMessage(error: string)
	{
		SharedModule.SET_ALERT_ERROR_MESSAGE(error);
	}

	get isVisibleAlert() : boolean
	{
		return SharedModule.AlertIsVisible || this.errorMessage != "";
	}
	set isVisibleAlert(value: boolean)
	{
		SharedModule.SET_ALERT_IS_VISIBLE(value);
	}

	@Emit("click")
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	clicked(): void
	{
		SharedModule.SET_ALERT_ERROR_MESSAGE("");
		SharedModule.SET_ALERT_IS_VISIBLE(false);
	}
}
