










import Vue from 'vue'
import { Emit, Component } from 'vue-property-decorator'

@Component({})
export default class PgaCancelButton extends Vue{
    buttons = {
		cancel: {
			Icon: "mdi-undo-variant",
            Text: "Cancel",
		}
    }
    
    @Emit("click")
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    clicked() : void { }
}
