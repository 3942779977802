
























import { Component, Prop, Model } from "vue-property-decorator";

import ValidationMixin from "@/mixins/ValidationMixin";
import { mixins } from "vue-class-component";

@Component({})
export default class PgaCombobox extends mixins(ValidationMixin)
{
    @Prop({ default: "" }) readonly label!: string;
    @Prop({ default: false }) readonly readonly!: boolean;
    @Prop({ default: true }) readonly multiple!: boolean;
    @Prop({ default: false }) readonly dense!: boolean;
    
    @Prop({ default: "" }) readonly itemText!: string;
    @Prop({ default: "" }) readonly itemValue!: string;
    @Prop({ default: false }) readonly returnObject!: boolean;
    @Prop({ default: function() { return new Array<unknown>() } }) readonly items!: Array<unknown>;

	@Prop({ default: "" }) readonly keyError!: string;
	@Prop({ default: function(){ new Map<string, Array<string>>() } }) readonly Errors!: Map<string, Array<string>>;

    @Model("input", { type: undefined }) Model!: Array<unknown>;
	updateValue(value: Array<unknown>) : void
	{
		this.$emit('input', value);
    }

    private dropDownPosition = {};
    private searchInput = "";
    
    private onResize(): void
	{
        //TODO: find a way to know value of zoom into page
        // const zoom = (window.outerWidth / window.innerWidth) * 100;
		if (window.innerHeight > 960)
		{
			this.dropDownPosition = {};
		}
		else
		{
			this.dropDownPosition = { maxHeight: 240, offsetY: true };
        }
        console.log(window);
    }

    mounted() : void
    {
        window.addEventListener("resize", this.onResize);
        window.addEventListener("zoom", this.onResize);
    }
}
