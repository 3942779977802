


















import Vue from 'vue'
import { Emit, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class PgaGeneralButton extends Vue
{
	@Prop({ type: String, default: "" }) readonly text! : string;
    @Prop({ type: String, default: "" }) readonly icon! : string;
    @Prop({ type: String, default: "" }) readonly color! : string;
    @Prop({ type: String, default: "" }) readonly iconColor! : string;
    @Prop({ type: Boolean, default: false }) readonly asIcon! : boolean;

    @Prop({ type: Boolean, default: false }) readonly fab! : boolean;
    @Prop({ type: Boolean, default: false }) readonly dark! : boolean;
    @Prop({ type: Boolean, default: false }) readonly right! : boolean;
    @Prop({ type: Boolean, default: false }) readonly absolute! : boolean;
    @Prop({ type: Boolean, default: false }) readonly bottom! : boolean;
    @Prop({ type: Boolean, default: false }) readonly large! : boolean;
    @Prop({ type: Boolean, default: false }) readonly small! : boolean;
    @Prop({ type: Boolean, default: false }) readonly xSmall! : boolean;

    @Emit("click")
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    clicked() : void { }
}
