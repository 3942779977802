







// @ is an alias to /src
import { Vue, Component } from "vue-property-decorator";
import { SharedModule } from '../Shared/store';
import LandingPage from './LandingPage.vue';
import DashboardIndex from './DashboardIndex.vue';

@Component({
    components: {
        LandingPage,
        DashboardIndex,
    },
})
export default class HomeIndex extends Vue
{
    get IsLogged() : boolean
    {
        return SharedModule.IsLogged;
    }
}
