





















	import Vue from 'vue'
	import { Emit, Component, Prop } from 'vue-property-decorator'

	@Component( {} )
	export default class PgaApproveButton extends Vue
	{
		@Prop( { type: String, default: "" } ) readonly text!: string;
		@Prop( { type: String, default: "" } ) readonly color!: string;
		@Prop( { type: String, default: "" } ) readonly icon!: string;
		@Prop( { type: Boolean, default: false } ) readonly xLarge!: boolean;
        @Prop( { type: Boolean, default: false } ) readonly large!: boolean;
		@Prop( { type: Boolean, default: false } ) readonly medium!: boolean;
		@Prop( { type: Boolean, default: false } ) readonly small!: boolean;
		@Prop( { type: Boolean, default: false } ) readonly xSmall!: boolean;

		@Emit( "click" )
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		clicked (): void { }
	}
