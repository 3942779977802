












import { Component, Prop } from "vue-property-decorator";

import ValidationMixin from "@/mixins/ValidationMixin";
import { mixins } from "vue-class-component";

@Component({})
export default class PgaProgressLinear extends mixins(ValidationMixin)
{    
    @Prop({ default: "" }) readonly color!: string;
    @Prop({ default: false }) readonly stripped!: boolean;
    @Prop({ default: 10 }) readonly height!: number;
    @Prop({ default: 0 }) readonly value!: number;

    @Prop({ default: function(){ new Array<string>() }} ) readonly content!: Array<string>;

    get textBar() : string
    {
        const index = (this.value * 3 / 100) - 1;
        return this.content[index];
    }
}
