
























import { Component, Prop, Model, Emit } from "vue-property-decorator";

import ValidationMixin from "@/mixins/ValidationMixin";
import { mixins } from "vue-class-component";

import PgaGeneralButton from '../buttons/PgaGeneralButton.vue';

@Component({
    components:
    {
        PgaGeneralButton,
    }
})
export default class PgaDropdownSearch extends mixins(ValidationMixin)
{
    @Prop({ default: "", type: String }) readonly label!: string;
    @Prop({ default: false, type: Boolean }) readonly readonly!: boolean;
    @Prop({ default: false, type: Boolean }) readonly multiple!: boolean;
    @Prop({ default: false, type: Boolean }) readonly dense!: boolean;
    @Prop({ default: false, type: Boolean }) readonly clearable!: boolean;
    @Prop({ default: false, type: Boolean }) readonly loading!: boolean;
    @Prop({ default: false, type: Boolean }) showMenu!: boolean;
    
    @Prop({ default: "", type: String }) readonly itemText!: string;
    @Prop({ default: "", type: String }) readonly itemValue!: string;
    @Prop({ default: false, type: Boolean }) readonly returnObject!: boolean;
    @Prop({ default: function() { return new Array<unknown>() } }) readonly items!: Array<unknown>;

	@Prop({ default: "", type: String }) readonly keyError!: string;
	@Prop({ default: function(){ new Map<string, Array<string>>() } }) readonly Errors!: Map<string, Array<string>>;

    @Model("input", { type: undefined }) Model!: Array<unknown>;
	updateValue(value: Array<unknown>) : void
	{
        value = value ?? new Array<unknown>();
		this.$emit('input', value);
    }

    private dropDownPosition = {};
    private SearchInput = "";

    get searchInput() : string
    {
        return this.SearchInput;
    }
    set searchInput(value: string)
    {
        this.SearchInput = value;
        this.$emit('search-input', value);
        this.showMenu = false;
    }

    @Emit('click')
    private changeShowMenu(value: boolean) : void
    {
        this.showMenu = value;
    }
    
    private onResize(): void
	{
        //TODO: find a way to know value of zoom into page
        // const zoom = (window.outerWidth / window.innerWidth) * 100;
		if (window.innerHeight > 960)
		{
			this.dropDownPosition = {};
		}
		else
		{
			this.dropDownPosition = { maxHeight: 240, offsetY: true };
        }
    }

    mounted() : void
    {
        this.Model = this.Model ?? new Array<unknown>();
        window.addEventListener("resize", this.onResize);
        window.addEventListener("zoom", this.onResize);
    }
}
