













import Vue from "vue";
import { Component, Prop, PropSync, Watch } from "vue-property-decorator";
import { NavigationGuardNext, Route } from "vue-router";

@Component({})
export default class PgaTransition extends Vue {
  DEFAULT_TRANSITION = "fade";
  DEFAULT_TRANSITION_MODE = "out-in";
  prevHeight = "0";
  transitionName: string | null = this.DEFAULT_TRANSITION;
  transitionMode: string | null = this.DEFAULT_TRANSITION_MODE;
  transitionEnterActiveClass: string | null = "";

  created(): void {
    this.$router.beforeEach(
      (to: Route, from: Route, next: NavigationGuardNext) => {
        let transitionName =
          (to !== undefined &&
          to.meta !== undefined &&
          to.meta.transitionName !== undefined &&
          to.meta.transitionName != undefined
            ? to.meta.transitionName
            : undefined) ||
          (from !== undefined &&
          from.meta !== undefined &&
          from.meta.transitionName !== undefined &&
          from.meta.transitionName !== undefined
            ? from.meta.transitionName
            : undefined) ||
          this.DEFAULT_TRANSITION;

        if (transitionName === "slide") {
          const toDepth = to.path.split("/").length;
          const fromDepth = from.path.split("/").length;
          transitionName = toDepth < fromDepth ? "slide-right" : "slide-left";
        }

        this.transitionMode = this.DEFAULT_TRANSITION_MODE;
        this.transitionEnterActiveClass = `${transitionName}-enter-active`;

        if (
          to !== undefined &&
          to.meta !== undefined &&
          to.meta.transitionName !== undefined &&
          to.meta.transitionName === "zoom"
        ) {
          this.transitionMode = "in-out";
          this.transitionEnterActiveClass = "zoom-enter-active";
          document.body.style.overflow = "hidden";
        }

        if (
          from !== undefined &&
          from.meta !== undefined &&
          from.meta.transitionName !== undefined &&
          from.meta.transitionName === "zoom"
        ) {
          this.transitionMode = null;
          this.transitionEnterActiveClass = null;
          document.body.style.overflow = "null";
        }

        this.transitionName = transitionName;

        next();
      }
    );
  }

  beforeLeave(element) {
    this.prevHeight = getComputedStyle(element).height;
  }

  enter(element) {
    const { height } = getComputedStyle(element);

    element.style.height = this.prevHeight;

    setTimeout(() => {
      element.style.height = height;
    });
  }

  afterEnter(element) {
    element.style.height = "auto";
  }
}
