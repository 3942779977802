
























import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { ActivityModel } from '@/services/iNotificationService';

import { mixins } from "vue-class-component";
import SharedMixin from "@/mixins/SharedMixin";

import PgaActivityItem from './PgaActivityItem.vue';
import { ActivityType } from '@/services/iNotificationService';
import { TypeActivityText } from '../store';

@Component({
    components: { PgaActivityItem }
})
export default class PgaActivitiesList extends mixins(SharedMixin)
{
    @Prop() readonly activities!: Array<ActivityModel>;
    @Prop( { default: true }) readonly isLoading!: boolean;

    private activity!: ActivityModel;

    headers: Array<unknown> =
    [
        {
            text: "Id",
            align: " d-none",
            sortable: true,
            value: "Id",
        },
        {
            text: "Created At",
            align: "start",
            sortable: true,
            value: "CreatedAt",
        },
        {
            text: "Title",
            align: "start",
            sortable: true,
            value: "Title",
        },
        {
            text: "Activity Type",
            align: "start",
            sortable: true,
            value: "Type",
        }
    ];

    private typeText(type: ActivityType) : string
    {
        return TypeActivityText(type);
    }

    private showDetails(activity: ActivityModel) : void
    {
        this.$router.push({path: activity.UrlRelativePath});
    }
}
