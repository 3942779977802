















//import Vue from 'vue';
import { Vue, Component } from "vue-property-decorator";
import { LayoutModule } from "./store";
import PgaMenuNotification from '@/feature/Shared/Notification/Components/PgaMenuNotification.vue';
import { SharedModule } from '../store';

@Component( {
	components: { PgaMenuNotification },
	computed: {
		isMobile ()
		{
			switch ( this.$vuetify.breakpoint.name )
			{
				case 'xs':
				case 'sm':
				case 'md': return true
				default: return false
			}
		}
	}
} )
export default class PgaNavigationDrawer extends Vue
{
	drawer (): void
	{
		LayoutModule.revertDrawer();
	}

	get showNotifications (): boolean
	{
		return SharedModule.ShowNotifications;
	}

	get accessibility (): boolean
	{
		return SharedModule.Accessibility;
	}
}
