








































import Component, { mixins } from "vue-class-component";
import SharedMixin from "@/mixins/SharedMixin";

import { EmailsStore } from "./store";

@Component({})
export default class PgaSendEmailToUsers extends mixins(SharedMixin)
{
	private isLoading = false;

	get show() : boolean
	{
		return EmailsStore.ShowFormEmailToUsers;
	}
	set show(value: boolean)
	{
		EmailsStore.SET_SHOW_FORM_EMAIL_TO_USERS(value);
	}
	
	get subject() : string
	{
		return EmailsStore.EmailToUsers.Command?.Subject ?? "";
	}
	set subject(value: string)
	{
		EmailsStore.SET_SEND_EMAIL_TO_USERS_SUBJECT(value);
	}

	get body() : string
	{
		return EmailsStore.EmailToUsers.Command?.Body ?? "";
	}
	set body(value: string)
	{
		EmailsStore.SET_SEND_EMAIL_TO_USERS_BODY(value);
	}

	get Errors(): Map<string, Array<string>>
	{
		return EmailsStore.EmailToUsers.Errors;
	}
	
	private async send(): Promise<void>
	{
		this.isLoading = true;
		await EmailsStore.SendEmailToUsers();
		this.isLoading = false;
		this.show = false;
		this.$router.go(-1);
	}
	
	private close() : void
	{
		this.show = false;
		EmailsStore.SET_SEND_EMAIL_TO_USERS_ERRORS(new Map<string, Array<string>>());
	}
}
