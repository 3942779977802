import iNotificationService from "./iNotificationService";

// Notifications
import{ NotificationModel,
     CreateNotificationByRoleCommand,
     DeleteNotificationResponse, CreateNotificationByRoleResponse, DeleteActivityResponse } from "./iNotificationService";

// Activities
import{ ListActivities } from "./iNotificationService";

// Emails
import{ SendEmailToPermissionsCommand, SendEmailToUsersCommand } from "./iNotificationService"; 

import AxiosService from './AxiosService';
import { injectable } from 'inversify';
import { Guid } from 'guid-typescript';
import { MarkAsReadResponse } from './iNotificationService';

@injectable()
export default class NotificationService extends AxiosService implements iNotificationService
{
    protected readonly API_URL: string = process.env.VUE_APP_NOTIFICATION_API_URL || window.location.origin + "api/notification";

    // Notification API
    async ListNotificationByUser(): Promise<Array<NotificationModel>>
    {
        const listNotifications: any = await this.BaseGet<Array<NotificationModel>>("notifications");
        return listNotifications.Notifications;
    }

    async DismissNotificationsByUser(): Promise<Array<NotificationModel>>
    {
        const listNotifications: any = await this.BaseDelete<Array<NotificationModel>>("notifications/dismiss");
        return listNotifications.Notifications;
    }

    async DeleteNotificationsByUser(): Promise<Array<NotificationModel>>
    {
        const listNotifications: any = await this.BaseDelete<Array<NotificationModel>>("notifications");
        return listNotifications.Notifications;
    }

    async MarkAsReadNotification(id: Guid): Promise<MarkAsReadResponse>
    {
        return this.BasePatch<any, MarkAsReadResponse>(`notifications/markasread/${id}`, null);
    }
    async DeleteNotification(id: Guid): Promise<DeleteNotificationResponse>
    {
        return await this.BaseDelete(`notifications/${id}`);
    }
    async CreateNotificationByRole(id: Guid, command: CreateNotificationByRoleCommand): Promise<CreateNotificationByRoleResponse>
    {
        return this.BasePost<CreateNotificationByRoleCommand, CreateNotificationByRoleResponse>(`notifications/${id}`, command);
    }

    //Activities API
    async ListActivities(): Promise<ListActivities>
    {
        const listActivities: ListActivities = await this.BaseGet<ListActivities>("activities");
        return listActivities;
    }
    async DeleteActivity(id: Guid): Promise<DeleteActivityResponse>
    {
        const response = await this.BaseDelete<DeleteActivityResponse>(`activities/${id}`);
        return response;
    }

    //Email API
    async SendEmailToPermissions(command: SendEmailToPermissionsCommand) : Promise<void>
    {
        await this.BasePost<SendEmailToPermissionsCommand, void>('emails/permissions', command);
    }
    async SendEmailToUsers(command: SendEmailToUsersCommand) : Promise<void>
    {
        await this.BasePost<SendEmailToUsersCommand, void>('emails/users', command);
    }
}