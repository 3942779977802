







import Vue from 'vue'
import { Emit, Component, Prop } from 'vue-property-decorator';

@Component({})
export default class PgaDeleteButton extends Vue{
	@Prop({ default: "Delete" }) readonly text! : string;

    buttons = {
		delete: {
			Icon: "mdi-delete-circle",
            Text: "Delete",
		}
    }
    
    @Emit("click")
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    clicked() : void { }
}
