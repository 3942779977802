

















import { Component, Prop, Model } from "vue-property-decorator";

import ValidationMixin from "@/mixins/ValidationMixin";
import { mixins } from "vue-class-component";

@Component({})
export default class PgaTextField extends mixins(ValidationMixin)
{
	@Prop({ type: String, default: "" }) readonly label!: string;
	@Prop({ type: Boolean, default: false }) readonly readonly!: boolean;
	@Prop({ type: String, default: "" }) readonly prependIcon!: string;
	@Prop({ type: Boolean, default: false }) readonly dense!: boolean;
	@Prop({ type: String, default: "" }) readonly prefix!: string;

	@Prop({ type: String, default: "" }) readonly keyError!: string;
	@Prop({ default: function() { return new Map<string, Array<string>>() } }) readonly Errors!: Map<string, Array<string>>;

	@Prop({ default: "" }) readonly value!: string | number;
	@Model("input", { type: String || Number }) Model!: string | number;
	updateValue(value: string) : void
	{
		value = value ?? "";
		this.$emit('input', value);
	}
}
