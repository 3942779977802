import { Module, VuexModule, Action, getModule, Mutation } from "vuex-module-decorators";
import store from '@/infrastructure/store';

import pgaDiContainer from '@/App.container';

import iNotificationService,
    { SendEmailToPermissionsCommand, SendEmailToUsersCommand } from '@/services/iNotificationService';
import NotificationService from '@/services/NotificationService';

import { parseErrors } from "../store";

export interface EmailState
{
    ShowFormEmailToPermissions: boolean;
    ShowFormEmailToUsers: boolean;
    EmailToPermissions: SendEmailToPermissionsModel;
    EmailToUsers: SendEmailToUsersModel;
}

export interface SendEmailToPermissionsModel
{
    Command: SendEmailToPermissionsCommand | null;
    Errors: Map<string, Array<string>>;
}

export interface SendEmailToUsersModel
{
    Command: SendEmailToUsersCommand | null;
    Errors: Map<string, Array<string>>;
}

@Module({ namespaced: true, dynamic: true, store, name: 'EmailStoreModule' })
class EmailStore extends VuexModule implements EmailState
{
    private _notificationService: iNotificationService = pgaDiContainer.get<iNotificationService>(NotificationService);
    
    public ShowFormEmailToPermissions = false;
    public ShowFormEmailToUsers = false;

    public EmailToPermissions: SendEmailToPermissionsModel = 
    {
        Command: null,
        Errors: new Map<string, Array<string>>(),
    };

    public EmailToUsers: SendEmailToUsersModel = 
    {
        Command: null,
        Errors: new Map<string, Array<string>>(),
    };
    
    @Mutation
    public SET_SHOW_FORM_EMAIL_TO_PERMISSIONS(value: boolean)
    {
        this.ShowFormEmailToPermissions = value;
    }
    @Mutation
    public SET_SHOW_FORM_EMAIL_TO_USERS(value: boolean)
    {
        this.ShowFormEmailToUsers = value;
    }

    // Send Email to Permissions
    @Action({ rawError: true})
    public async SendEmailToPermissions() : Promise<void>
    {
        if(this.EmailToPermissions.Command === null)
            return Promise.reject("No command has been initialized, try reload the application");
        try
        {
            await this._notificationService.SendEmailToPermissions(this.EmailToPermissions.Command);
        }
        catch(error)
        {
            const errs = parseErrors(error);
            this.SET_SEND_EMAIL_TO_PERMISSIONS_ERRORS(errs);

            throw errs;
        }
    }
    @Mutation
    public SET_SEND_EMAIL_TO_PERMISSIONS_COMMAND(command: SendEmailToPermissionsCommand)
    {
        this.EmailToPermissions.Command = command;
    }
    @Mutation
    public SET_SEND_EMAIL_TO_PERMISSIONS_SUBJECT(subject: string)
    {
        if(this.EmailToPermissions.Command)
            this.EmailToPermissions.Command.Subject = subject;
    }
    @Mutation
    public SET_SEND_EMAIL_TO_PERMISSIONS_BODY(body: string)
    {
        if(this.EmailToPermissions.Command)
            this.EmailToPermissions.Command.Body = body;
    }
    @Mutation
    public SET_SEND_EMAIL_TO_PERMISSIONS_ERRORS(errors: Map<string, Array<string>>)
    {
        this.EmailToPermissions.Errors = errors;
    }

    // Send Email to Users
    @Action({ rawError: true})
    public async SendEmailToUsers() : Promise<void>
    {
        if(this.EmailToUsers.Command === null)
            return Promise.reject("No command has been initialized, try reload the application");
        try
        {
            await this._notificationService.SendEmailToUsers(this.EmailToUsers.Command);
        }
        catch(error)
        {
            const errs = parseErrors(error);
            this.SET_SEND_EMAIL_TO_USERS_ERRORS(errs);

            throw errs;
        }
    }
    @Mutation
    public SET_SEND_EMAIL_TO_USERS_COMMAND(command: SendEmailToUsersCommand)
    {
        this.EmailToUsers.Command = command;
    }
    @Mutation
    public SET_SEND_EMAIL_TO_USERS_SUBJECT(subject: string)
    {
        if(this.EmailToUsers.Command)
            this.EmailToUsers.Command.Subject = subject;
    }
    @Mutation
    public SET_SEND_EMAIL_TO_USERS_BODY(body: string)
    {
        if(this.EmailToUsers.Command)
            this.EmailToUsers.Command.Body = body;
    }
    @Mutation
    public SET_SEND_EMAIL_TO_USERS_ERRORS(errors: Map<string, Array<string>>)
    {
        this.EmailToUsers.Errors = errors;
    }
}

export const EmailsStore = getModule(EmailStore);