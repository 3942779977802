






















































































import { Component, Prop, Model } from "vue-property-decorator";

import ValidationMixin from "@/mixins/ValidationMixin";
import { mixins } from "vue-class-component";
import { deepSearchText } from '../feature/Shared/store';

import PgaSwitch from "@/components/PgaSwitch.vue";

@Component( {
	components:
	{
		PgaSwitch,
	}
} )
export default class PgaDataTable extends mixins( ValidationMixin )
{
	@Prop( { default: "" } ) readonly title!: string;
	@Prop( { default: 10 } ) readonly itemsPerPage!: number;
	@Prop( { type: Boolean, default: false } ) readonly showExpand!: boolean;
	@Prop( { type: Boolean, default: false } ) readonly showSelect!: boolean;
	@Prop( { type: Boolean, default: false } ) readonly singleSelect!: boolean;
	@Prop( { type: Boolean, default: false } ) readonly loading!: boolean;
	@Prop( { type: String, default: "$expand" } ) readonly expandIcon!: boolean;

	@Prop( { type: Boolean, default: false } ) readonly hideDefaultFooter!: boolean;
	@Prop( { type: Boolean, default: false } ) readonly disablePagination!: boolean;

	@Prop( { type: Boolean, default: false } ) readonly showGroupBy!: boolean;
	@Prop( { default: function () { return new Array<string>() } } ) readonly groupBy!: Array<string> | string;

	@Prop( { default: function () { return new Array<string>() } } ) readonly colProps!: Array<string>;
	@Prop( { default: "" } ) readonly sortBy!: string;
	@Prop( { type: Boolean, default: false } ) readonly sortDesc!: boolean;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	@Prop( { default: function () { return new Array<any>() } } ) readonly headers!: Array<any>;
	@Prop( { default: function () { return new Array<unknown>() } } ) readonly data!: Array<unknown>;
	@Prop( { default: "Id" } ) readonly itemKey!: string;

	@Model( "input", { type: Array } ) Model!: Array<unknown>;
	updateValue ( value: Array<unknown> ): void
	{
		this.$emit( 'input', value );
	}

	private expanded = [];
	private readonly
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	private searchColumn = new Array<any>();

	private AdvancedSearch = false;
	get advancedSearch() : boolean
	{
		return this.AdvancedSearch;
	}
	set advancedSearch(value: boolean)
	{
		if(value === false)
			this.searchColumn.forEach(sc => sc.value = "");
		this.AdvancedSearch = value;
	}


	private Search = "";
	get search() : string
	{
		if(this.advancedSearch)
		{
			const searchColumnsArray = new Array<string>();
			for(let i = 0; i < this.searchColumn.length; i++)
			{
				const sc = this.searchColumn[i];
				if(sc.value !== "")
				{
					searchColumnsArray.push(sc.keySearch + ":" + sc.value + ";");
				}
			}
			this.Search = searchColumnsArray.join('');
		}
		return this.Search;
	}
	set search(value: string)
	{
		this.Search = value;
	}

	private rowClicked ( row: unknown ): void
	{
		this.$emit( "row-clicked", row )
	}

	// Search
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	get headerSearchable() : Array<any>
	{
		return this.headers.filter(h => h.keySearch !== undefined 
										&& h.keySearch !== null 
										&& h.keySearch !== "" );
	}
	
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	get customFilter() : any
	{
		const countSemiColumn = this.search?.split(';').length - 1;
		const regexString = `^([a-z]{2,}:.{0,};){${countSemiColumn}}$`;
		const regexAdvancedSearch = new RegExp(regexString);
		if(regexAdvancedSearch.test(this.search))
			return this.advancedDeepSearch;
		return this.deepSearch;
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	private advancedDeepSearch(value: any, search: string | null, item: any)
	{
		let result = new Array<boolean>();
		const searchToken = search?.split(';').filter(st => st.includes(':'));
		const keyValueMap = new Map<string, string>();
		searchToken?.forEach(t =>
		{
			const keyValueCouple = t.split(':');
			keyValueMap.set(keyValueCouple[0], keyValueCouple[1]);
		});
		
		keyValueMap.forEach((value, key) =>
		{
			const headerItemByKeySearch = this.headerSearchable.find(hs => hs.keySearch === key);
			if( headerItemByKeySearch !== null && headerItemByKeySearch !== undefined )
			{
				var subItem = {};
				if(headerItemByKeySearch.value.includes('.'))
				{
					subItem = headerItemByKeySearch.value.split('.').reduce((o,i)=>o[i], item)
				}
				else
				{
					subItem = item[headerItemByKeySearch.value];
				}
				result.push(deepSearchText( value, subItem ));
			}
		})
		return result.every(r => r === true);
	}
	
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	private deepSearch(value: any, search: string | null, item: any)
	{
		return deepSearchText( search, item );
	}

	mounted() : void
	{
		this.headerSearchable.forEach(hs => 
		{
			const column = 
			{	
				keySearch: hs.keySearch,
				columnName: hs.text, 
				value: "",
			}
			this.searchColumn.push(column);
		});
	}
}


