import iMsalService from './iMsalService'
import { injectable } from 'inversify'
import { Configuration, LogLevel, PublicClientApplication, RedirectRequest, SilentRequest, AuthenticationResult, AccountInfo } from '@azure/msal-browser';

@injectable()
export default class MsalService implements iMsalService {    
    protected configuration: Configuration = {        
        auth: {
            clientId: process.env.VUE_APP_AAD_CLIENTID || '',
            navigateToLoginRequestUrl: false,            
        },
        cache: {
            cacheLocation: 'sessionStorage',
            storeAuthStateInCookie: false
        },
        system: {
            loggerOptions: {
                loggerCallback: (level: LogLevel, message: string, containsPii: boolean): void => {
                    if (containsPii) {
                        return;
                    }
                    switch (level) {
                        case LogLevel.Error:
                            console.error(message);
                            return;
                        case LogLevel.Info:
                            console.info(message);
                            return;
                        case LogLevel.Verbose:
                            console.debug(message);
                            return;
                        case LogLevel.Warning:
                            console.warn(message);
                            return;
                    }
                },
                piiLoggingEnabled: false
            },
            windowHashTimeout: 60000,
            iframeHashTimeout: 6000,
            loadFrameTimeout: 0,
            asyncPopups: false
        }
    };
    
    protected authPar: RedirectRequest = {
        scopes: ['user.read'],
        prompt: 'select_account',                        
    };  
    
    protected userAgentApplication: PublicClientApplication = new PublicClientApplication(this.configuration);
    
    public async loginPopup(): Promise<AuthenticationResult> {        
        return await this.userAgentApplication.loginPopup(this.authPar);
    }
    
    public async loginRedirect(): Promise<void> {        
        return await this.userAgentApplication.loginRedirect(this.authPar);
    }
    
    public async acquireTokenSilent(account: AccountInfo): Promise<AuthenticationResult> {        
        const par = {
            account: account,
        } as SilentRequest;
        
        return await this.userAgentApplication.acquireTokenSilent(par);
    }
    
    public async acquireTokenPopup(): Promise<AuthenticationResult> {       
        return await this.userAgentApplication.acquireTokenPopup(this.authPar);
    }
    
    public acquireTokenRedirect(): Promise<void> {       
        return this.userAgentApplication.acquireTokenRedirect(this.authPar);
    }    
    
    public getAccountByUsername(userName: string): AccountInfo | null {
        return this.userAgentApplication.getAccountByUsername(userName);
    }

    public getAccountByHomeId(homeId: string): AccountInfo | null {
        return this.userAgentApplication.getAccountByHomeId(homeId);
    }

    public getAccountByLocalId(localId: string): AccountInfo | null {
        return this.userAgentApplication.getAccountByLocalId(localId);
    }

    public getAllAccounts(): AccountInfo[] {
        return this.userAgentApplication.getAllAccounts();
    }
    
    public logout(): Promise<void> {
        return this.userAgentApplication.logout();
    }
    
    public handleRedirectPromise(hash?: string): Promise<AuthenticationResult | null>{
        return this.userAgentApplication.handleRedirectPromise(hash);
    }
    
}