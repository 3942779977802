








































































































import Component, { mixins } from "vue-class-component";
import { Emit, Prop } from "vue-property-decorator";
import SharedMixin from "@/mixins/SharedMixin";

import { ActivityModel, ResourceAction } from '@/services/iNotificationService';
import { ActivitiesStore } from '../store';

@Component({})
export default class PgaActivitySingleItem extends mixins(SharedMixin)
{
    @Prop() readonly activity!: ActivityModel;

    private payloadSet!: Set<ResourceAction>;
    private message = "";
    private isLoading = false;

    private showDialog = false;
    private titleDialog = `Deleting Activity...`;
    private messageDialog = `Are you sure to want delete ${this.activity.Title} activity?`;

    @Emit("details")
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    private details(): void { }

    @Emit("dismiss")
    private dismiss(): void 
    {
        this.HideDialog();
    }

    private get hasActions() : boolean
    {
        return this.activity.Payload.length > 0;
    }

    get CreatedAt() : string
    {
        const timeGMT = this.activity.CreatedAt.toUTCString();
        const time = timeGMT.substring(0, timeGMT.length - 4);

        return time;
    }

    private ShowDialog() : void
    {
        this.showDialog = true;
    }
    private HideDialog() : void
    {
        this.showDialog = false;
    }
    
    private async action() : Promise<void>
    {
        let response = false;
        const payloads = this.activity.Payload;
        for(let i = 0; i < payloads.length; i++)
        {
            try
            {
                this.isLoading = true;
                response = await ActivitiesStore.MakeActionFromActivity(payloads[i].CompleteResourcePath);
            }
            catch(error)
            {
                this.message = error.response.data;
                break;
            }
            finally
            {
                this.isLoading = false;
            }
        }

        if(response)
        {
            const activities = ActivitiesStore.Activities;
            ActivitiesStore.SET_ACTIVITIES(activities.filter(a => a.Id != this.activity.Id));
        }
    }
}
