import { Module, VuexModule, Action, getModule, Mutation } from "vuex-module-decorators";
import store from '@/infrastructure/store'

import pgaDiContainer from '@/App.container';
import iNotificationService, { NotificationModel, DeleteNotificationResponse, MarkAsReadResponse } from '@/services/iNotificationService';
import NotificationService from '@/services/NotificationService';
import { Guid } from 'guid-typescript';

export interface NotificationState
{
    Notifications: Array<NotificationModel>;
    IsLoading: boolean;
}

@Module( { namespaced: true, dynamic: true, store, name: 'NotificactionStoreModule' } )
class NotificactionStore extends VuexModule implements NotificationState
{
    public IsLoading = false;
    private _notificationService: iNotificationService = pgaDiContainer.get<iNotificationService>( NotificationService );

    Notifications: Array<NotificationModel> = new Array<NotificationModel>();

    //GET: list all notifications
    @Action( { rawError: true } )
    public async ListNotifications (): Promise<Promise<void>>
    {
        this.SET_LOADER( true );
        const notifications = await this._notificationService.ListNotificationByUser();
        notifications.forEach( n => n.CreatedAt = new Date( n.CreatedAt ) );
        this.SET_NOTIFICATIONS( notifications );
        this.SET_LOADER( false );
    }

    @Action( { rawError: true } )
    public async DismissNotifications (): Promise<Promise<void>>
    {
        this.SET_LOADER( true );
        const notifications = await this._notificationService.DismissNotificationsByUser();
        // this.SET_NOTIFICATIONS(notifications);
        this.SET_LOADER( false );
    }

    @Action( { rawError: true } )
    public async DeleteNotifications (): Promise<Promise<void>>
    {
        this.SET_LOADER( true );
        const notifications = await this._notificationService.DeleteNotificationsByUser();
        this.SET_NOTIFICATIONS( notifications );
        this.SET_LOADER( false );
    }

    @Mutation
    public SET_NOTIFICATIONS ( notifications: Array<NotificationModel> )
    {
        this.Notifications = notifications;
    }

    @Mutation
    public ADD_SINGLE_NOTIFICATION( notification: NotificationModel )
    {
        notification.CreatedAt = new Date( notification.CreatedAt );
        this.Notifications.unshift( notification );
    }

    //PATCH: mark as read notification
    @Action( { rawError: true } )
    public async MarkAsReadtNotifications ( id: Guid ): Promise<void>
    {
        this.SET_NOTIFICATION_AS_READ( id, true );
        const response: MarkAsReadResponse = await this._notificationService.MarkAsReadNotification( id );
        this.SET_NOTIFICATION_AS_READ( id, response.IsRead );
    }
    @Mutation
    public async SET_NOTIFICATION_AS_READ ( id: Guid, response: boolean )
    {
        const index: number = this.Notifications.findIndex( item => item.Id === id );
        this.Notifications[index].Read = response ? response : true;
    }

    //DELETE: delete one notification
    @Action( { rawError: true } )
    public async DeleteNotification ( id: Guid )
    {
        const response: DeleteNotificationResponse = await this._notificationService.DeleteNotification( id );
        this.DELETE_NOTIFICATION( response );
    }
    @Mutation
    public DELETE_NOTIFICATION ( response: DeleteNotificationResponse )
    {
        this.Notifications = this.Notifications.filter( item => item.Id != response.Id );
    }

    @Mutation
    public SET_LOADER ( loading: boolean )
    {
        this.IsLoading = loading;
    }
}

export const NotificactionModule = getModule( NotificactionStore );