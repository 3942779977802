






















































































import Vue from "vue";
import Component from "vue-class-component";
import { NotificationModel } from "@/services/iNotificationService";

import PgaNotificationItem from "./PgaNotificationItem.vue";
import { NotificactionModule } from "../store";
import { SharedModule } from "../../store";


@Component( {
	components: {
		PgaNotificationItem,
	},
} )
export default class PgaMenuNotification extends Vue
{
	private menu = false;

	get Notifications (): Array<NotificationModel>
	{
		return NotificactionModule.Notifications;
	}

	get isLoading (): boolean
	{
		return NotificactionModule.IsLoading;
	}

	getNotification ( message: string ): void
	{
		const notification: NotificationModel = JSON.parse( message );
		NotificactionModule.ADD_SINGLE_NOTIFICATION( notification );
	}

	async created (): Promise<void>
	{
		await NotificactionModule.ListNotifications();
		Vue.prototype.$eventHub.$eventHub.$on( 'notification', this.getNotification );
	}

	beforeDestroy (): void
	{
		Vue.prototype.$eventHub.$eventHub.$off( 'notification', this.getNotification );
	}

	//Comment this once that SignalR works with valid certicate
	async deleteNotifications (): Promise<void>
	{
		await NotificactionModule.ListNotifications();
	}

	async dismissNotifications (): Promise<void>
	{
		await NotificactionModule.DismissNotifications();
		await NotificactionModule.ListNotifications();
	}

	async reloadNotifications (): Promise<void>
	{
		await NotificactionModule.ListNotifications();
	}

	notificationsNotReadLength (): number
	{
		return this.Notifications.filter( ( n ) => n.Read === false ).length;
	}

	get accessibility (): boolean
	{
		return SharedModule.Accessibility;
	}
}
