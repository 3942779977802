import Vue from 'vue'
import Vuex from 'vuex'
import { LayoutState } from '../feature/Shared/Layout/store';
import { CreateOfferState } from '../feature/Offerte/ValutazioneOfferta/store';
import { OfferServiceState } from '../feature/Offerte/store';
import { CreateAvvioAttivitaState } from '@/feature/Offerte/AvvioAttivita/store';
import { CreateVerbaleStartupState } from '@/feature/Offerte/VerbaleStartup/store';
import { SharedState } from '../feature/Shared/store';
import { RolesState } from '@/feature/Provisioning/Roles/store';
import { UsersState } from '@/feature/Provisioning/Users/store';
import { TenantsState } from '@/feature/Provisioning/Tenants/store';


Vue.use(Vuex);
export interface RootState {
    //Provisioning
    roles: RolesState,
    users: UsersState,
    tenants: TenantsState,
    //Shared
    shared: SharedState;
    layout: LayoutState;    
    //Offer
    createOffer: CreateOfferState;
    offerService: OfferServiceState;
    createAvvioAttivita: CreateAvvioAttivitaState;
    createVerbaleStartupState: CreateVerbaleStartupState;
}

// Declare empty store first, dynamically register all modules later.
export default new Vuex.Store<RootState>({});