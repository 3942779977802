





import Vue from 'vue';
import Component from "vue-class-component";

import { ActivitiesStore } from './store';
import { ActivityModel } from '@/services/iNotificationService';
import PgaActivitiesList from './Components/PgaActivitiesList.vue';
import PgaActivitiesListItems from './Components/PgaActivitiesListItems.vue';
import { Prop } from 'vue-property-decorator';

@Component({
    components: { PgaActivitiesList, PgaActivitiesListItems }
})
export default class PgaActivitiesPageIndex extends Vue
{
    @Prop({ default: false }) isTimeline!: boolean;
    private isLoading = true;

    get listActivities() : Array<ActivityModel>
    {
        return ActivitiesStore.Activities;
    }

    async mounted() : Promise<void>
    {
        this.isLoading = true;
        try
        {
            await ActivitiesStore.ListActivities();
        }
        finally
        {
            this.isLoading = false;
        }
    }
}
