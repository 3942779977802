




import Vue from "vue";
import { mixins } from "vue-class-component";
import { Component, Prop} from "vue-property-decorator";
import {SharedModule} from "@/feature/Shared/store";
import SharedMixin from "@/mixins/SharedMixin";

@Component({
  computed: {
    isLoading(): boolean {
      return SharedModule.IsLoading;
    },
  },
})
export default class PgaFullscreenLoadingSpinner extends mixins(SharedMixin) {
}
