import AxiosService from './AxiosService'
import { injectable } from 'inversify';
import { Guid } from 'guid-typescript';
import IResourceService from './iResourceService';

// Import Expertises
import { ExpertiseModel, MyExpertisesTableView, ListExpertisesProposed,
        CreateExpertiseCommand, EditExpertiseCommand, SelectUsersExpertiseCommand, SelectMyExpertisesCommand, ApproveUserExpertiseProposedCommand, DenyUserExpertiseProposedCommand,
        CreateExpertiseResponse, EditExpertiseResponse, DeleteExpertiseResponse, SelectUsersExpertiseResponse, SelectMyExpertisesResponse, ApproveUserExpertiseProposedResponse, DenyUserExpertiseProposedResponse } from './iResourceService';

// Import Certifications
import { ListCertifications, CertificationModel, MyCertificationsTableView,
        CreateCertificationCommand, EditCertificationCommand, DeleteCertificationCommand, SelectUsersCertificationCommand, SelectMyCertificationsCommand,
        CreateCertificationResponse, EditCertificationResponse, DeleteCertificationResponse, SelectUsersCertificationResponse, SelectMyCertificationsResponse } from './iResourceService';

// Import Technologies
import { ListTechnologies, TechnologyModel,
    CreateTechnologyCommand, EditTechnologyCommand, 
    CreateTechnologyResponse, EditTechnologyResponse, DeleteTechnologyResponse } from './iResourceService';

// Import Vendors
import { ListVendors, VendorModel,
    CreateVendorCommand, ApproveVendorCommand, ApproveDeletionVendorCommand,
    CreateVendorResponse, DeleteVendorResponse, ApproveVendorResponse, ApproveDeletionVendorResponse } from './iResourceService';

// Import SIP Users
import { ListSIPUsers, SIPUserModel, ListExpertises } from './iResourceService';

@injectable()
export default class ResourceService extends AxiosService implements IResourceService {    

    protected readonly API_URL: string = process.env.VUE_APP_RESOURCE_API_URL || window.location.origin + "api/v1/resource";

    // Certifications
    async GetAllCertifications(): Promise<ListCertifications>
    {
        return await this.BaseGet<ListCertifications>('/certifications');
    }
    async GetMyCertifications(): Promise<MyCertificationsTableView>
    {
        return await this.BaseGet<MyCertificationsTableView>('/me/certifications');
    }
    async CreateCertification(cmd: CreateCertificationCommand): Promise<CreateCertificationResponse>
    {
        return await this.BasePost<CreateCertificationCommand, CreateCertificationResponse>("/certifications", cmd)
    }
    async GetCertificationById(id: Guid): Promise<CertificationModel>
    {
        return await this.BaseGet<CertificationModel>(`/certifications/${id.toString()}`);
    }
    async EditCertification(id: Guid, cmd: EditCertificationCommand) : Promise<EditCertificationResponse>
    {
        return await this.BasePatch<EditCertificationCommand, EditCertificationResponse>(`/certifications/${id.toString()}`, cmd);
    }
    async DeleteCertification(id: Guid): Promise<DeleteCertificationResponse>
    {
        return await this.BaseDelete<DeleteCertificationResponse>(`/certifications/${id.toString()}`);
    }
    async SelectUsersCertification(id: Guid, cmd: SelectUsersCertificationCommand) : Promise<SelectUsersCertificationResponse>
    {
        return await this.BasePatch<SelectUsersCertificationCommand, SelectUsersCertificationResponse>(`/certifications/${id.toString()}/selectusers`, cmd);
    }
    async SelectMyCertifications(cmd: SelectMyCertificationsCommand): Promise<SelectMyCertificationsResponse>
    {
        return await this.BasePatch<SelectMyCertificationsCommand, SelectMyCertificationsResponse>("/me/selectmycertifications", cmd);
    }
    async GetAllCertificationsDropdown(): Promise<ListCertifications>
    {
        return await this.BaseGet<ListCertifications>('/certifications/dropdown');
    }

    // Expertise
    async GetAllExpertises(): Promise<ListExpertises>
    {
        return await this.BaseGet<ListExpertises>('/expertises');
    }
    async GetExpertise(id: Guid): Promise<ExpertiseModel>
    {
        return await this.BaseGet<ExpertiseModel>(`/expertises/${id.toString()}`);
    }
    async GetMyExpertises(): Promise<MyExpertisesTableView>
    {
        return await this.BaseGet<MyExpertisesTableView>('/me/expertises');
    }
    async CreateExpertise(cmd: CreateExpertiseCommand): Promise<CreateExpertiseResponse>
    {
        return await this.BasePost<CreateExpertiseCommand, CreateExpertiseResponse>("/expertises", cmd);
    }
    async EditExpertise(id: Guid, cmd: EditExpertiseCommand) : Promise<EditExpertiseResponse>
    {
        return await this.BasePatch<EditExpertiseCommand, EditExpertiseResponse>(`/expertises/${id.toString()}`, cmd);
    }
    async DeleteExpertise(id: Guid): Promise<DeleteExpertiseResponse>
    {
        return await this.BaseDelete<DeleteExpertiseResponse>(`/expertises/${id.toString()}`);
    }
    async SelectUsersExpertise(id: Guid, cmd: SelectUsersExpertiseCommand) : Promise<SelectUsersExpertiseResponse>
    {
        return await this.BasePatch<SelectUsersExpertiseCommand, SelectUsersExpertiseResponse>(`/expertises/${id.toString()}/selectusers`, cmd);
    }
    async SelectMyExpertises(cmd: SelectMyExpertisesCommand): Promise<SelectMyExpertisesResponse>
    {
        return await this.BasePatch<SelectMyExpertisesCommand, SelectMyExpertisesResponse>("/me/selectmyexpertises", cmd);
    }
    async GetAllExpertisesProposedByAreaManager() : Promise<ListExpertisesProposed>
    {
        return await this.BaseGet<ListExpertisesProposed>("/expertises/expertisesproposed");
    }
    async ApproveExpertiseProposed(cmd: ApproveUserExpertiseProposedCommand) : Promise<ApproveUserExpertiseProposedResponse>
    {
        return await this.BasePatch<ApproveUserExpertiseProposedCommand, ApproveUserExpertiseProposedResponse>('/expertises/approveproposal', cmd);
    }
    async DenyExpertiseProposed(cmd: DenyUserExpertiseProposedCommand) : Promise<DenyUserExpertiseProposedResponse>
    {
        return await this.BasePatch<DenyUserExpertiseProposedCommand, DenyUserExpertiseProposedResponse>('/expertises/denyproposal', cmd);
    }

    // Technologies
    async GetAllTechnologies(): Promise<ListTechnologies>
    {
        return await this.BaseGet<ListTechnologies>('/technologies');
    }
    async GetTechnology(id: Guid): Promise<TechnologyModel>
    {
        return await this.BaseGet<TechnologyModel>(`/technologies/${id.toString()}`);
    }
    async CreateTechnology(cmd: CreateTechnologyCommand): Promise<CreateTechnologyResponse>
    {
        return await this.BasePost<CreateTechnologyCommand, CreateTechnologyResponse>("/technologies", cmd);
    }
    async EditTechnology(id: Guid, cmd: EditTechnologyCommand) : Promise<EditTechnologyResponse>
    {
        return await this.BasePatch<EditTechnologyCommand, EditTechnologyResponse>(`/technologies/${id.toString()}`, cmd);
    }
    async DeleteTechnology(id: Guid) : Promise<DeleteTechnologyResponse>
    {
        return await this.BaseDelete<DeleteTechnologyResponse>(`/technologies/${id.toString()}`);
    }
    async GetAllTechnologiesDropdown(): Promise<ListTechnologies>
    {
        return await this.BaseGet<ListTechnologies>('/technologies/dropdown');
    }

    // Vendors
    async GetAllVendors(): Promise<ListVendors>
    {
        return await this.BaseGet<ListVendors>('/vendors');
    }
    async GetVendor(id: Guid): Promise<VendorModel>
    {
        return await this.BaseGet<VendorModel>(`/vendors/${id.toString()}`);
    }
    async CreateVendor(cmd: CreateVendorCommand): Promise<CreateVendorResponse>
    {
        return await this.BasePost<CreateVendorCommand, CreateVendorResponse>("/vendors", cmd);
    }
    async DeleteVendor(id: Guid): Promise<DeleteVendorResponse>
    {
        return await this.BaseDelete<DeleteVendorResponse>(`/vendors/${id.toString()}`);
    }
    async GetAllVendorsDropdown(): Promise<ListVendors>
    {
        return await this.BaseGet<ListVendors>('/vendors/dropdown');
    }
    async ApproveVendor(cmd: ApproveVendorCommand): Promise<ApproveVendorResponse>
    {
        const url = `vendors/${cmd.Id.toString()}/approve`;
        const resp = await this.BaseGet<ApproveVendorResponse>(url);
        return resp;
    }
    async ApproveDeletionVendor(cmd: ApproveDeletionVendorCommand): Promise<ApproveDeletionVendorResponse>
    {
        const url = `vendors/${cmd.Id.toString()}/approvedeletion`;
        const resp = await this.BaseGet<ApproveDeletionVendorResponse>(url);
        return resp;
    }

    //SIP Users
    async GetAllSIPUsers(): Promise<ListSIPUsers> {
        const users =  await this.BaseGet<Array<SIPUserModel>>('/sipemployees');
        return { Users: users }; 
    }
}