










import { Vue, Component } from "vue-property-decorator";
import PgaActivitiesPageIndex from '@/feature/Shared/Activity/PgaActivitiesPageIndex.vue';

@Component({
    components:
    {
        PgaActivitiesPageIndex,
    },
})
export default class DashboardIndex extends Vue { }
