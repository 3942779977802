import AxiosService from './AxiosService'
import { injectable } from 'inversify';
import { Guid } from 'guid-typescript';

import IIdentityService from './iIdentityService';

// Import User
import {  ListUsers, UserModel, UserSimple, 
        CreateUserCommand, EditUserCommand, DeleteUserCommand,
        CreateUserResponse, EditUserResponse, DeleteUserResponse } from './iIdentityService';

// Import Role
import {  ListRoles, RoleModel, 
    CreateRoleCommand, EditRoleCommand, DeleteRoleCommand, SelectUsersPerRoleCommand,
    CreateRoleResponse, EditRoleResponse, DeleteRoleResponse, SelectUsersPerRoleResponse } from './iIdentityService';

// Import Tenant
import {  TenantModel,
    CreateTenantCommand, EditTenantCommand,
    CreateTenantResponse, EditTenantResponse } from './iIdentityService';

// Import Permission
import {  PermissionModel, ListPermissions } from './iIdentityService';

// Import Menu
import {  GetMenuResponse, ChildrenMenu } from './iIdentityService';

@injectable()
export default class ProvisioningService extends AxiosService implements IIdentityService
{
    protected readonly API_URL: string = process.env.VUE_APP_IDENTITY_API_URL || window.location.origin + "api/v1/identity";

    // My UserId
    async MyUserId() : Promise<Guid>
    {
        return await this.BaseGet<Guid>(`/me/myuserid`);
    }

    // Menu
    async GetMenu(): Promise<GetMenuResponse>
    {
        const response = await this.BaseGet<GetMenuResponse>(`/menus`);
        return response;
    }

    // Permissions
    async MyPermissions(): Promise<Array<string>>
    {
        const data = await this.BaseGet<Array<string>>("/me/mypermissions");
        return data;
    }
    async ListPermissions(): Promise<ListPermissions>
    {
        const data = await this.BaseGet<ListPermissions>("/permissions");
        return data;
    }

    // Users
    async ListUsers(): Promise<ListUsers>
    {
        return await this.BaseGet<ListUsers>("/users");
    }
    async GetUser(id: Guid): Promise<UserModel>
    {
        const response = await this.BaseGet<any>(`/users/${id.toString()}`);
        const responseUser: UserModel = response.User;

        return responseUser;
    }
    async CreateUser(cmd: CreateUserCommand): Promise<CreateUserResponse>
    {
        return await this.BasePost<CreateUserCommand, CreateUserResponse>("/users", cmd);
    }
    async EditUser(cmd: EditUserCommand): Promise<EditUserResponse>
    {
        return await this.BasePatch<EditUserCommand, EditUserResponse>(`/users/${cmd.Id.toString()}`, cmd);
    }
    async DeleteUser(id: Guid): Promise<DeleteUserResponse>
    {
        return await this.BaseDelete<DeleteUserResponse>(`/users/${id.toString()}`);
    }
    async GetACOMs(): Promise<Array<UserSimple>>
    {
        const ACOM_Id = "afcafe67-65d3-44d9-a456-79a01dff32b9";
        return (await this.BaseGet<any>(`/users/dropdown?roles=${ACOM_Id}`)).Users;
    }
    async GetACOMsWithDC(): Promise<Array<UserSimple>>
    {
        const ACOM_Id = "afcafe67-65d3-44d9-a456-79a01dff32b9";
        const DC_Id = "F180424C-5B76-4455-920B-54AAC5050ABA";
        return (await this.BaseGet<any>(`/users/dropdown?roles=${ACOM_Id}&roles=${DC_Id}`)).Users;
    }
    async GetAllHADAndHI(): Promise<Array<UserSimple>>
    {
        const HAD_Id = "6411EAFD-C90E-47F9-A946-BE379ECAF234";
        const HI_Id = "29A7E1F4-19B4-4F75-952D-A7DB6D4C378A";
        return (await this.BaseGet<any>(`/users/dropdown?roles=${HAD_Id}&roles=${HI_Id}`)).Users;
    }
    async GetAllUsersByRoleId(roleId: Guid) : Promise<Array<UserSimple>>
    {
        return (await this.BaseGet<any>(`/users/dropdown?roles=${roleId.toString()}`)).Users;
    }
    async GetUsersDropdown() : Promise<ListUsers>
    {
        return await this.BaseGet<ListUsers>("/users/dropdown");
    }

    // Roles
    async CreateRole(cmd: CreateRoleCommand): Promise<CreateRoleResponse>
    {
        const response = await this.BasePost<CreateRoleCommand, CreateRoleResponse>(`/roles`, cmd);
        return response;
    }
    async ListRoles(): Promise<ListRoles>
    {
        const response = await this.BaseGet<ListRoles>("/roles");
        return response;
    }
    async GetRole(id: Guid): Promise<RoleModel>
    {
        const response = await this.BaseGet<any>(`/roles/${id.toString()}`);
        const responseRole = response.Role;

        return responseRole;
    }
    async EditRole(cmd: EditRoleCommand): Promise<EditRoleResponse>
    {
        const response = await this.BasePatch<EditRoleCommand, EditRoleResponse>(`/roles/${cmd.Id}`, cmd);
        return response;
    }
    async DeleteRole(roleId: Guid): Promise<DeleteRoleResponse>
    {
        const response = await this.BaseDelete<DeleteRoleResponse>(`/roles/${roleId}`);
        return response;
    }
    async SelectUsersPerRole(cmd: SelectUsersPerRoleCommand) : Promise<SelectUsersPerRoleResponse>
    {
        const response = await this.BasePatch<SelectUsersPerRoleCommand, SelectUsersPerRoleResponse>(`/roles/${cmd.Id}/selectusers`, cmd);
        return response;
    }

    // Tenants
    public async CreateTenant(cmd: CreateTenantCommand): Promise<CreateTenantResponse>
    {
        return await this.BasePost<CreateTenantCommand, CreateTenantResponse>("/tenants", cmd);
    }
    public async EditTenant(id: string, cmd: EditTenantCommand): Promise<EditTenantResponse>
    {
        return await this.BasePatch<EditTenantCommand, EditTenantResponse>(`/tenants/${id}`, cmd);
    }
    public async GetTenantToEdit(id: string): Promise<TenantModel>
    {
        return await this.BaseGet<TenantModel>(`/tenants/${id}`);
    }
}
