import urljoin from 'url-join';

export default class AuthRoutes {
    public static AuthenticationPath = "/auth/authenticate";
    public static ServiceAPISuffix = "api/v1/identity";

    public static IsAuthEndpoint(url: string): boolean {
        return url == this.AuthenticationEndpoint;
    }

    public static get AuthenticationEndpoint(): string {
        return urljoin(this.ApiUrl, this.AuthenticationPath);
    }

    public static get ApiUrl(): string {
        return process.env.VUE_APP_IDENTITY_API_URL || window.location.origin + this.ServiceAPISuffix;
    }
}