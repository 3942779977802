





































import { Component, Prop, Model } from "vue-property-decorator";

import ValidationMixin from "@/mixins/ValidationMixin";
import { mixins } from "vue-class-component";

@Component({})
export default class PgaNumberField extends mixins(ValidationMixin)
{
	@Prop({ type: String, default: "" }) readonly label!: string;
	@Prop({ type: Boolean, default: false }) readonly readonly!: boolean;
	@Prop({ type: Boolean, default: false }) readonly dense!: boolean;
	@Prop({ type: String, default: "" }) readonly prefix!: string;
	@Prop({ type: String, default: "" }) readonly suffix!: string;
	
	@Prop({ type: Boolean, default: false }) readonly decimal!: boolean;
	@Prop({ type: Number, default: 1 }) readonly step!: number;
	@Prop({ type: Number, default: 2 }) readonly precision!: number;

	@Prop({ type: String, default: "" }) readonly keyError!: string;
	@Prop({ default: function(){ new Map<string, Array<string>>() } }) readonly Errors!: Map<string, Array<string>>;

	@Model("input", { type: Number }) Model!: number;
	updateValue(value: number) : void
	{
		value = value ?? 0;
		if(this.decimal)
			value = this.removeInaspectedChars(value);
		value = this.decimal? this.round(parseFloat(value.toString().trim())) : parseInt(value.toString().trim());
		this.$emit('input', value);
	}

	private removeInaspectedChars(value: number) : number
	{
		if(isNaN(value))
		{
			let newValues = new Array<string>();
			for (const v of value.toString())
			{
				if(!isNaN(parseInt(v)))
					newValues.push(v);
				if(v === '.' || v === ',')
					newValues.push('.');
			}
			return newValues.length > 0 ? parseFloat(newValues.join('')) : 0;
		}
		return value;
	}

	private increment() : void
	{
		const newValue = parseFloat(this.Model.toString());
		this.updateValue(newValue + this.step);
	}

	private decrement() : void
	{
		const newValue = parseFloat(this.Model.toString());
		this.updateValue(newValue - this.step);
	}

	private round(num: number) : number
	{
		var exp = Math.pow(10, this.precision);
		return Math.floor(num * exp + 0.5) / exp;
	}
}
