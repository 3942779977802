


































import Vue from "vue";
import Component from "vue-class-component";
import { Model, Prop, Emit } from "vue-property-decorator";

import PgaCancelButton from './buttons/PgaCancelButton.vue';

@Component(
{
	components:
	{
		PgaCancelButton
	}
}
)
export default class PgaDialog extends Vue
{
	@Model() Model!: boolean;

	@Prop({ type: String, default: "" }) readonly Title!: string;
	@Prop({ type: String, default: "" }) readonly IconTitle!: string;

	@Prop({ type: String, default: "" }) readonly Message!: string;
	@Prop({ type: String, default: "" }) readonly FooterMessage!: string;
	@Prop({ type: String, default: "error" }) readonly Color!: string;

	@Prop({ type: String, default: "mdi-close-circle" }) readonly IconButton!: string;
	@Prop({ type: Boolean, default: false }) readonly OnlyAgree!: boolean;

	get darkenColor() : string
	{
		return `${this.Color} darken-4`
	}

	get classCard() : string
	{
		return `headline ${this.Color} lighten-2`;
	}
	@Emit("agree")
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	agree(): void { console.log(this.OnlyAgree) }
	
	@Emit("reject")
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	reject() : void {	}
}
