import "reflect-metadata"
import { Container } from "inversify";
import AxiosService from './services/AxiosService'
import IAxiosService from './services/iAxiosService'
import PokemonService from './services/PokemonService'
import IPokemonService from './services/iPokemonService'
import OrderService from './services/OrderService';
import IOrderService from './services/iOrderService';
import MsalService from './services/MsalService';
import IMsalService from './services/iMsalService';
import GraphService from './services/GraphService';
import IGraphService from './services/iGraphService';
import AuthService from '@/services/AuthService';
import IAuthService from '@/services/iAuthService';
import IdentityService from '@/services/IdentityService';
import iIdentityService from '@/services/iIdentityService';
import IResourceService from '@/services/iResourceService';
import ResourceService from '@/services/ResourceService';
import iNotificationService from './services/iNotificationService';
import NotificationService from './services/NotificationService';

const pgaDiContainer = new Container();
pgaDiContainer.bind<IAxiosService>(AxiosService).to(AxiosService);
pgaDiContainer.bind<IPokemonService>(PokemonService).to(PokemonService);
pgaDiContainer.bind<IOrderService>(OrderService).to(OrderService);
pgaDiContainer.bind<IMsalService>(MsalService).to(MsalService);
pgaDiContainer.bind<IGraphService>(GraphService).to(GraphService);
pgaDiContainer.bind<IAuthService>(AuthService).to(AuthService);
pgaDiContainer.bind<iIdentityService>(IdentityService).to(IdentityService);
pgaDiContainer.bind<IResourceService>(ResourceService).to(ResourceService);
pgaDiContainer.bind<iNotificationService>(NotificationService).to(NotificationService);
export default pgaDiContainer;