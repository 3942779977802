










































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Guid } from 'guid-typescript';
import { NotificationModel } from '@/services/iNotificationService';
import { NotificactionModule } from '../store';
import moment from "moment";

@Component( {} )
export default class PgaSingleNotification extends Vue
{
	@Prop() readonly Notification!: NotificationModel;

	get notification (): NotificationModel
	{
		return NotificactionModule.Notifications.find( n =>
			n.Id === this.Notification.Id ) as NotificationModel;
	}

	private dismissSingleNotification ( id: Guid ): void
	{
		NotificactionModule.MarkAsReadtNotifications( id );
	}

	private deleteSingleNotification ( id: Guid ): void
	{
		NotificactionModule.DeleteNotification( id );
	}

	private timeAgo ( createdAt: Date ): string
	{
		return moment( createdAt.toISOString(), "YYYY-MM-DDThh:mm:ss" ).fromNow();
	}
}
