import IPokemonService, { Pokeomon } from './iPokemonService'
import AxiosService from './AxiosService'
import { injectable } from 'inversify';

@injectable()
export default class PokemonService extends AxiosService implements IPokemonService {
    GetPokemons(): Promise<Array<Pokeomon>> {
        return this.BaseGet<any>("pokemon?limit=151")
        .then(a=> a.results.map((s: any) => ( ({ Name: s.name, Value: s.name + "-val" } as unknown as Pokeomon))));
    }
    
}