





















import { Component, Prop, Model } from "vue-property-decorator";

import ValidationMixin from "@/mixins/ValidationMixin";
import { mixins } from "vue-class-component";

@Component({})
export default class PgaInputFile extends mixins(ValidationMixin)
{
	@Prop({ type: String, default: "" }) readonly label!: string;
	@Prop({ type: Boolean, default: false }) readonly readonly!: boolean;
	@Prop({ type: Boolean, default: false }) readonly multiple!: boolean;
	@Prop({ type: Boolean, default: false }) readonly hideInput!: boolean;

	@Prop({ type: String, default: "" }) readonly keyError!: string;
	@Prop({ default: function() { return new Map<string, Array<string>>() } }) readonly Errors!: Map<string, Array<string>>;

	@Prop() readonly value!: File | Array<File>;
	@Model("change",) Model!: File | Array<File>;
	updateValue(value: any) : void
	{
		this.$emit('change', value);
	}
}
