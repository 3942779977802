










import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component({})
export default class PgaPartialContent extends Vue
{
    @Prop({ type: Number, default: 0 }) contentLength! : number;
    @Prop({ type: Number, default: 3 }) maxContentLength! : number;
    @Prop({ type: Boolean, default: true }) chips! : boolean;
}
