









import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component({})
export default class PgaCardFieldGroup extends Vue
{
	@Prop({default: ""}) readonly label!: string;	
}
